import { createAxiosInstance, getAuthToken, getNavigatorData } from 'utils/helpers';
import { notifType, notify } from 'utils/notifSender';

const initialState = {
    orders: [],
    canProceedToOrderPlacement: true,
    orderPlacementPageTermsAndCondition: true,
    orderPlacementPageKnoxDiscliamer: true,
    orderPlaceDispatch: false,
    orderPlaced: false,
    tradeInCalculations: {},
    orderPlaceDispatchThroughPopup: false,
    orderPlacedFinalEvent: false
};

const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

const CREATE_ORDER_START = 'CREATE_ORDER_START';
const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

const UDPATE_STORE_ON_ORDER_PROCEED_STAT = 'UDPATE_STORE_ON_ORDER_PROCEED_STAT';
const UDPATE_STORE_ON_ORDER_PROCEED_STAT_FOR_TERMS_CONDITION = 'UDPATE_STORE_ON_ORDER_PROCEED_STAT_FOR_TERMS_CONDITION';
const UDPATE_STORE_ON_ORDER_PROCEED_STAT_FOR_KNOX_DISCLIAMER = 'UDPATE_STORE_ON_ORDER_PROCEED_STAT_FOR_KNOX_DISCLIAMER';

const TRIGGER_PLACING_ORDER_EVENT = 'TRIGGER_PLACING_ORDER_EVENT';

const ORDER_PLACED = 'ORDER_PLACED';
const ORDER_TOTAL_AMOUNT = 'ORDER_TOTAL_AMOUNT';

const TRIGGER_ORDER_PLACING_POPUP_EVENT = 'TRIGGER_ORDER_PLACING_POPUP_EVENT';

const TRIGGER_ORDER_PLACING_FINAL_EVENT = 'TRIGGER_ORDER_PLACING_FINAL_EVENT';

const fetchOrdersStart = () => ({
    type: FETCH_ORDERS_START
});

const fetchOrdersSuccess = (data) => ({
    type: FETCH_ORDERS_SUCCESS,
    data
});

const fetchOrdersFailure = () => ({
    type: FETCH_ORDERS_FAILURE
});

const createOrderStart = () => ({
    type: CREATE_ORDER_START
});

const createOrderSuccess = () => ({
    type: CREATE_ORDER_SUCCESS
});

const createOrderFailure = () => ({
    type: CREATE_ORDER_FAILURE
});

export const triggerOrderPlacingFinalEvent = (data) => ({
    type: TRIGGER_ORDER_PLACING_FINAL_EVENT,
    data
});

export const placeOrderEventThroughPopup = (data) => ({
    type: TRIGGER_ORDER_PLACING_POPUP_EVENT,
    data
});

export const updateStoreOnOrderProceedStat = (data) => ({
    type: UDPATE_STORE_ON_ORDER_PROCEED_STAT,
    data
});

export const updateStoreOnOrderProceedStatTermsAndConditions = (data) => ({
    type: UDPATE_STORE_ON_ORDER_PROCEED_STAT_FOR_TERMS_CONDITION,
    data
});

export const updateStoreOnOrderProceedStatKnoxDisclaimer = (data) => ({
    type: UDPATE_STORE_ON_ORDER_PROCEED_STAT_FOR_KNOX_DISCLIAMER,
    data
});

export const triggerOrderPlacingEventFromOutside = (data) => ({
    type: TRIGGER_PLACING_ORDER_EVENT,
    data
});

export const orderPlacedEvent = (data) => ({
    type: ORDER_PLACED,
    data
});

export const orderTotal = (data) => ({
    type: ORDER_TOTAL_AMOUNT,
    data
});

export const handleFetchingOrders = () => {
    return async (dispatch) => {
        dispatch(fetchOrdersStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/order?channel=samsung');

            dispatch(fetchOrdersSuccess(data));
        } catch (e) {
            dispatch(fetchOrdersFailure());
        }
    };
};

export const handleCreateOrder = (form, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(createOrderStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        form.navigator = JSON.stringify(getNavigatorData());

        try {
            const res = await instance.post('/order/create?channel=samsung', form);

            if (res?.data?.status === true || res.status === 204 || res?.status === 200) {
                dispatch(createOrderSuccess());
                onSuccess(res?.data);
            } else {
                dispatch(createOrderFailure());
                onFailure('sorry we can\'t make your order please contact us!');
            }
        } catch (e) {
            const msg = e?.response?.data?.data?.message;

            if (msg) {
                notify(msg, notifType.DANGER);
            }

            dispatch(createOrderFailure());
            onFailure(msg || 'sorry we can\'t make your order please contact us!');
        }
    };
};

export default function cart(state = initialState, action) {
    switch (action.type) {
        case FETCH_ORDERS_SUCCESS: {
            return {
                ...state,
                orders: action.data
            };
        }

        case UDPATE_STORE_ON_ORDER_PROCEED_STAT: {
            return {
                ...state,
                canProceedToOrderPlacement: action.data
            };
        }

        case UDPATE_STORE_ON_ORDER_PROCEED_STAT_FOR_TERMS_CONDITION:
            return {
                ...state,
                orderPlacementPageTermsAndCondition: action.data
            };

        case UDPATE_STORE_ON_ORDER_PROCEED_STAT_FOR_KNOX_DISCLIAMER:
            return {
                ...state,
                orderPlacementPageKnoxDiscliamer: action.data
            };

        case TRIGGER_PLACING_ORDER_EVENT:
            return {
                ...state,
                orderPlaceDispatch: action.data
            };

        case TRIGGER_ORDER_PLACING_POPUP_EVENT:
            return {
                ...state,
                orderPlaceDispatchThroughPopup: action.data
            };

        case ORDER_PLACED:
            return {
                ...state,
                orderPlaced: action.data
            };

        case TRIGGER_ORDER_PLACING_FINAL_EVENT:
            return {
                ...state,
                orderPlacedFinalEvent: action.data
            };

        case ORDER_TOTAL_AMOUNT:
            return {
                ...state,
                orderTotal: action.data
            };

        default:
            return state;
    }
}
