import React from 'react';
import { Store } from 'react-notifications-component';

import Notification from './Notification';

import 'animate.css';

export const notifType = {
    SUCCESS: 0,
    DANGER: 1,
    WARN: 2
};

export function notify(message, type, duration = 5000) {
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    const content = (
        <Notification
            message={message}
            type={type}
            duration={duration} />
    );

    const notificationConfig = {
        content,
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOutRight'],
        dismiss: {
            duration: duration,
            waitForAnimation: true
        }
    };

    notificationConfig.width = !isMobile && 350;
    Store.addNotification(notificationConfig);
}
