import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';

import Button from 'containers/App/components/Button/Button';

import { updateOTPDataFromStateToStore } from 'redux/reducers/user';
import { storeUserEvent } from 'api/user';

import backIcon from 'assets/images/general/back-icon.png';
import style from './OTPForm.module.scss';

// TODO: This component should be refactored
const AuthOTPForm = ({
    submit,
    changeForm,
    timer,
    resendCode,
    loading,
    type,
    otp,
    setOtp,
    lastnumber,
    email = '',
    showResend = true,
    numInput = 6,
    showActionButton = false,
    fromPopup
}) => {
    const dispatch = useDispatch();
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;

    const handleChange = (otp) => setOtp(otp);

    const handleSubmit = e => {
        e.preventDefault();
        submit(type);
    };

    const maskedEmail = () => {
        const [username, domain] = email.split('@');
        const maskedUsername = username.substring(0, 3) + '*'.repeat(username.length - 3);

        return maskedUsername + '@' + domain;
    };

    const isOtpFilled = () => otp.length === numInput;

    useEffect(() => {
        dispatch(updateOTPDataFromStateToStore({ otp: otp, email: email }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp]);

    useEffect(() => {
        const triggerUserEvent = async () => {
            let event = '';

            if (type === 'forgot') {
                event = 'forgot_otp';
            } else {
                event = 'student_otp';
            }

            const payload = {
                event: event
            };

            await storeUserEvent(payload);
        };

        triggerUserEvent();
    }, [type]);

    return (
        <div className={`${style.section} otp-form-section`}>
            <div className='auth-form-header'>
                <img
                    src={backIcon}
                    alt='Back'
                    className='auth-form-header__back'
                    onClick={changeForm} />
                <div className='auth-form-header__title'>Verification required</div>
            </div>
            <div className={style.description}>{email ? `We've sent a One Time Password (OTP) to the email ${maskedEmail()} Please enter it below.` : `SMS sent to phone number ending with ${lastnumber}`}</div>
            <form onSubmit={handleSubmit}>
                <div className={style.otpBox}>
                    <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={numInput}
                        inputType={'tel'}
                        inputStyle={style.input}
                        containerStyle={style.container}
                        renderInput={(props) => (
                            <input {...props} type='tel' />
                        )}/>
                </div>
                {fromPopup && (
                    <div className={style.buttons}>
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Resend OTP'}
                            className={style.buttons__submit}
                            disabled={timer !== 0} />
                    </div>
                )}
                {(!fromPopup && !isMobile) && (
                    <div className={style.buttons}>
                        <Button
                            type='button'
                            title={'Back'}
                            className={`${style.buttons__back} ${style.w_160}`}
                            onClick={changeForm} />
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Resend OTP'}
                            className={`${style.buttons__submit} ${style.w_160}`}
                            disabled={timer !== 0} />
                    </div>
                )}
                {timer > 0 && <div className={`${style.again} ${timer === 0 ? style.resend : ''}`}>Resend Code
                    <span>{timer > 0 ? ` (${timer} S)` : ''}</span>
                </div>}
            </form>
        </div>
    );
};

AuthOTPForm.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func,
    timer: PropTypes.number,
    resendCode: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.string,
    otp: PropTypes.string,
    setOtp: PropTypes.func,
    lastnumber: PropTypes.string,
    singpass: PropTypes.bool,
    email: PropTypes.string,
    showResend: PropTypes.bool,
    numInput: PropTypes.number,
    isReset: PropTypes.bool,
    showActionButton: PropTypes.bool,
    fromPopup: PropTypes.bool
};

export default AuthOTPForm;
