/****
 *
 * window & process.env constants
 *
 */
export const ELOQUENT = window.AuthEloquent;

export const AXIOS = window._axios;
export const TRADING_AXIOS = window._tradingAxios;
window._withAsyncCallerAxios = window._TradingBaseAxios;

export const APP_ENV = process.env.REACT_APP_ENV;
export const AUTH_API_URL = process.env.REACT_APP_API_URL;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const GOOGLE_MAP_KEY = 'AIzaSyAuvBVQ1BIpyMCBx11_VryAvLMCWGmY2LU';
export const API_ADDRESS = 'https://aged-oasis-di8qclo59nbp.vapor-farm-f1.com/api';

export const STAMPED_PUBLIC_API_KEY = process.env.REACT_APP_STAMPED_PUBLIC_API_KEY;
export const STAMPED_STORE_HASH = process.env.REACT_APP_STAMPED_STORE_HASH;

export const COMPASIA_BASE_URL = process.env.REACT_APP_COMPASIA_BASE_URL;
export const COMPASIA_API_KEY = process.env.REACT_APP_COMPASIA_API_KEY;
export const COMPASIA_USERNAME = process.env.REACT_APP_COMPASIA_USERNAME;

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'sk-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'sk-appLanguage';
export const STORAGEKEY_REFERRAL = 'sk-referral';
export const STORAGEKEY_TRADE_IN_DEVICE = 'trade-in-device';
export const STORAGEKEY_TRADE_IN_FORM_DATA = 'trade-in-form-data';
export const STORAGEKEY_TRADE_IN_PLACED = 'trade-in-placed';
export const STORAGEKEY_PAY_NOW_INFO = 'paynow-number';
export const STORAGEKEY_PASSWORD_SECRET_KEY = 'ae2269f3e1b876ea0af0f4493326bc82';

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'en'; // TODO_REPLACE(en | de | fr | etc..)

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    // google: {
    //     families: [
    //         'Poppins:300',
    //         'Poppins:400',
    //         'Poppins:500',
    //         'Poppins:700',
    //         'Poppins:900'
    //     ]
    // }
};

// INACTIVE Subscriptions Status
export const INACTIVE_STATUS = ['payment_failed', 'completed', 'rejected', 'shortage', 'bought'];

// Social networks config
export const SOCIAL_NETWORK_URLS = {
    FACEBOOK: 'TODO_REPLACE',
    INSTAGRAM: 'TODO_REPLACE',
    TWITTER: 'TODO_REPLACE',
    LINKEDIN: 'TODO_REPLACE'
    // ...
};

export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    HOME: '/',
    PRODUCTS: '/products',
    PRODUCT: {
        path: '/product/:slug',
        generate: (slug, rentalTerm = null) => {
            if (rentalTerm) {
                return `/product/${slug}/?rental_term=${rentalTerm}`;
            } else {
                return `/product/${slug}`;
            }
        }
    },
    HOW_IT_WORKS: '/how-it-works',
    FAQ: '/faq',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    ABOUT_US: '/about-us',
    MY_ACCOUNT: '/my-account',
    PROFILE: '/profile',
    BILLING_HISTORY: '/profile/billing-history',
    ORDEREDPRODUCTSINGLE: '/ordered-item',
    ORDEREDPRODUCT: (id = ':id', orderID = ':orderID') => `/ordered-item/${id}/${orderID}`,
    MYINFORMATION: '/my-account/my-information',
    SUBSCRIPTIONS: '/my-account/subscriptions',
    INACTIVE_SUBSCRIPTIONS: '/my-account/inactive-subscriptions',
    ORDERS: '/my-account/orders',
    PAYMENTINFO: '/my-account/payment-info',
    SHIPPINGINFO: '/my-account/shipping-info',
    ORDER: (id = ':id') => `/my-account/orders/${id}`,
    MYSINGPASS: '/my-account/singpass',
    KNOX_DISCLAIMER: '/knox-disclaimer',
    PURCHASE_SALES_RESTRICTIONS: '/purchase-sales-restrictions',
    CHECKOUT: '/checkout',
    CART: '/checkout/cart',
    SIGNUP: '/checkout/start',
    SINGPASS: '/checkout/singpass',
    SINGPASS_MANUALL_FORM: '/checkout/manuall',
    SINGPASS_RESULT: '/checkout/singpass-result',
    DOCUMENTSPROOF: '/checkout/documents-proof',
    SHIPPING: '/checkout/shipping',
    PAYMENT: '/checkout/payment',
    PRODUCTORDERSUMMARYSINGLE: 'product-order-summary',
    PRODUCTORDERSUMMARY: (id = ':id', orderId = ':orderId', orderNumber = ':orderNumber', isBuy = ':isBuy') => `/product-order-summary/${id}/${orderId}/${orderNumber}/${isBuy}`,
    ORDER_SUMMARY: '/checkout/order-summary',
    ORDER_SUMMARY_CONFIRMATION: '/checkout/order-summary-confirmation',
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    OTP_VERIFICATION: '/otp-verification',
    FORGOT_PASSWORD: '/forgot-password',
    REFER_AND_EARN: '/my-account/refer-and-earn',
    LUCKY_DRAW: '/lucky-draw',
    Q6B6: '/galaxy-z',
    Q6B6_flip6: '/galaxy-z-flip6',
    Q6B6_fold6: '/galaxy-z-fold6',
    TRADE_IN_CALCULATOR: '/trade-in-calculator',
    NAFA: '/nafa',
    UNPACKED: '/unpacked',
    CONTACT_US: '/contact-us',
    INVOICE: (invoiceUrl = ':invoiceUrl') => `/my-account/invoice/${invoiceUrl}`,
    GALAXY_Z_SEM: '/galaxy-z-sem',
    GALAXY_Z_AFF: '/galaxy-z-aff',
    GALAXY_Z_EDM: '/galaxy-z-edm',
    AUTH_CALLBACK_GOOGLE: '/auth/callback/google',
    AUTH_CALLBACK_FACEBOOK: '/auth/callback/facebook',
    GALAXY_Z_ND_FLASH_SALE: '/galaxy-z-nd-flash-sale'
};

export const ROUTE_REDIRECT_AFTER_REGISTRATION = ROUTES.HOME;
export const ROUTE_REDIRECT_AFTER_LOGIN = ROUTES.HOME;
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = ROUTES.HOME;
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = ROUTES.HOME;

export const MARCH_PROMO_CODE = {
    key: '1M_promo_code',
    value: '1monthfree'
};
export const APPLY_MARCH_PROMO_CODE = {
    key: 'apply_1M_promo',
    value: true
};

export const AUTO_APPLY_PROMO_CODE = '1monthfree';

/*
 *
 * Project config
 *
 */
export const APP_CURRENCY_SYMBOL = '$';
export const APP_CURRENCY_NAME = 'SGD';

// docs url: https://holy-fog-yewkgt0qow8w.vapor-farm-f1.com/docs
// https://mailtrap.io/inboxes
// shahzad.creatella@gmail.com
// cre@tel!@

// https://docs.google.com/presentation/d/1s_D-QRj8jAp_PKBZsHll6tUv_6J6en5JUKcdSPlq6do/edit#slide=id.g1362d62e662_0_11
// for issues : https://docs.google.com/spreadsheets/d/12KsXFP834zwxPfMRmJghVmNByt2AdlJrkXG-SydYCOY/edit?usp=sharing
// ravio doc https://docs.google.com/spreadsheets/d/14eLgOOX6Tx6YYmdjC3rN843X13L2vmXRMg8llwe7I-g/edit#gid=599515743
// coralis doc https://docs.google.com/spreadsheets/d/1kIwpdTTUnu8RxvswQ-O1Z9BQcvnbH02VDT9-LWfTeaQ/edit#gid=599515743
// roadmap : https://docs.google.com/spreadsheets/d/1uyEVrnzwOii74YirOEKlfiNPi9Kw1N3Vkoc5yq_zTg8/edit#gid=1279238413

// cinch new staging url
// http://d81ftngso57y6.cloudfront.net/

export const STUDENT_DOMAINS = [
    'cinch.sg'
];

export const TRADE_IN_DEVICES = [
    { key: 'samsung_s21_s23', series: 'Galaxy S21-S23', cashback: `${APP_CURRENCY_NAME} 200.00` },
    { key: 'iphone_12_14', series: 'iPhone 12-14', cashback: `${APP_CURRENCY_NAME} 300.00` },
    { key: 'none', series: 'No Thanks', cashback: '' }
];

export const CHECKOUT_REQUIREMENTS = [
    'SingPass verification',
    'Delivery address',
    'Payment details'
];

export const GALAXY_S24_SLUGS = ['samsung-galaxy-s24-ultra'];

export const PRE_ORDER_PRODUCTS = ['samsung-galaxy-z-flip6', 'samsung-galaxy-z-fold6'];

export const CHATBOT_ROUTES = [ROUTES.CHECKOUT, ROUTES.CART, ROUTES.SHIPPING, ROUTES.PAYMENT, ROUTES.ORDER_SUMMARY, ROUTES.ORDER_SUMMARY_CONFIRMATION];

export const FAUX_DISCOUNT_VARIANTS = [
    { slug: 'samsung-galaxy-z-fold6', storage: '512GB', discount: '16%' }
];

export const STATIC_G6B6_ROUTES = [ROUTES.GALAXY_Z_SEM, ROUTES.GALAXY_Z_AFF, ROUTES.GALAXY_Z_EDM];

export const EXCLUDED_URLS = [
    ROUTES.AUTH_CALLBACK_GOOGLE,
    ROUTES.AUTH_CALLBACK_FACEBOOK
];

export const PDP_FLIP6 = 'https://samsung.cinch.sg/product/samsung-galaxy-z-flip6';
