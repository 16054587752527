import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import * as FS from '@fullstory/browser';
import { useHistory, useLocation, Redirect } from 'react-router-dom';

import { ROUTES } from 'config/constants';
import { addItemsToCart, checkoutRules, getUserCart } from 'api/cart';
import { clearCart } from 'redux/reducers/cart';
import {
    handleGetOTPForLogin,
    handleLogin,
    handleRegistration,
    handleHideOTP,
    handleLoginWithOTP,
    handleGetStudentVerificationOTP,
    handleVerifyStudentVerificationCode,
    handleAuthorizeSingpass
} from 'redux/reducers/auth';
import { updateAuthenticationDataFromStateToStore } from 'redux/reducers/user';
import { checkOTPCode, getOTPForForgotPassword, passwordReset, storeUserEvent } from 'api/user';
import { trackFreshChatUser, trackKlaviyoEvent } from 'api/tracking';

import { useQuery, isStudentDomain } from 'utils/helpers';
import { notifType, notify } from 'utils/notifSender';

import LoginForm from '../App/components/LoginModal/elements/LoginForm/LoginForm';
import SignUpForm from '../App/components/LoginModal/elements/SignUpForm/SignUpForm';
import LoginOTPForm from '../App/components/LoginModal/elements/LoginOTPForm/LoginOTPForm';
import OTPForm from '../App/components/LoginModal/elements/OTPForm/OTPForm';
import ResetPassword from '../App/components/LoginModal/elements/ResetPassword/ResetPassword';
import FullscreenLoader from '../../components/FullscreenLoader/FullscreenLoader';
import CartLayout from 'layouts/CartLayout/CartLayout';
import SingPassScreen from '../../screens/SingPass/SingPassScreen';
import SingpassResultContainer from 'containers/SingpassResult/SingpassResultContainer';
import SupportingDocuments from 'screens/Profile/components/SupportingDocuments/SupportingDocuments';
import SingpassManualFormContainer from '../../containers/SingpassManualForm/SingpassManualFormContainer';
import Button from 'containers/App/components/Button/Button';

import RetrieveMyInfo from 'assets/images/RetrieveMyInfo.svg';
import { getUserIP } from '../../api/user';

const CheckoutStart = ({
    handleLogin,
    isAuthenticating,
    handleRegistration,
    handleGetOTPForLogin,
    showOTP,
    handleHideOTP,
    handleLoginWithOTP,
    signUp,
    clearCart,
    profile,
    isReset,
    resetEmail,
    toggleModal,
    handleGetStudentVerificationOTP,
    handleVerifyStudentVerificationCode
}) => {
    const query = useQuery();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [form, setForm] = useState('');
    const [loginWithPhone, setLoginWithPhone] = useState('');
    const [timer, setTimer] = useState(90);
    const [loading, setLoading] = useState(false);
    const [forgotOtp, setForgotOtp] = useState(false);
    const [emailAddress, setEmailAddress] = useState(isReset ? resetEmail : '');
    const [otp, setOtp] = useState('');
    const [lastnumber, setLastnumber] = useState('');
    const [ip, setIP] = useState(null);
    const [doRegister, setDoRegister] = useState(false);
    const [doLogin, setDoLogin] = useState(false);
    const [, setStudentVerificationOtp] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [signupPayload, setSignupPayload] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [, setMessageModalMessage] = useState({ type: '', message: '' });
    const [, setMessageModalOpen] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];
    let isStudent = location.state?.isStudent || false;
    const [userCart, setUserCart] = useState([]);
    const { authData } = useSelector(state => ({ authData: state.user.authData }));
    const { includeNoa } = useSelector(state => ({ includeNoa: state.auth.includeNoa }));
    const { otpInput } = useSelector(state => ({ otpInput: state.user.otpInput }));
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;

    const handleLoginWithPhone = e => {
        setLoginWithPhone(e);

        if (e.length > 2) {
            setLastnumber(e.slice(-2));
        }
    };

    const handleChangeForm = e => setForm(e);

    const onForgotPassword = (form, email = null) => {
        if (form === 'signup') {
            setForm(form);
        } else {
            submitForgotPassword(email);
        }
    };

    const submitLogin = async (params) => {
        handleLogin({
            ...params,
            gaSessionId: Cookies.get('_ga') || 'no-session',
            trueIp: ip || 'no-ip',
            onSuccess: () => {
                setDoLogin(true);

                if (window.location.pathname === ROUTES.PRODUCTS) {
                    window.location.reload();
                } else {
                    redirectToScreen();
                }
            }
        });
    };

    const submitSignUp = async (params) => {
        setLoading(true);
        isStudent = isStudentDomain(params.email);

        if (isStudent) {
            setSignupPayload(params);
            setEmailAddress(params.email);
            getVerificationCode(params.email);
        } else {
            doRegistration(params);
        }
    };

    const doRegistration = async (params) => {
        handleRegistration({
            ...params,
            gaSessionId: Cookies.get('_ga') || 'no-session',
            trueIp: ip || 'no-ip',
            onSuccess: () => {
                setDoRegister(true);
                redirectToScreen();
            },
            onFailure: () => {
                setLoading(false);
                notify('Please try again.', notifType.DANGER);
            }
        });
    };

    const getVerificationCode = async (email) => {
        handleGetStudentVerificationOTP({
            email: email,
            onSuccess: () => {
                setForm('studentVerification');
                setStudentVerificationOtp(true);
                setLoading(false);
            },
            onFailure: () => {
                setLoading(false);
                notify('Invalid email used.', notifType.DANGER);
            }
        });
    };

    const handleSubmitVerificationCode = async () => {
        setLoading(true);

        if (verificationCode === '') {
            notify('Please enter the OTP code.', notifType.DANGER);
        }

        handleVerifyStudentVerificationCode({
            code: verificationCode,
            email: emailAddress,
            onSuccess: () => {
                doRegistration(signupPayload);
                setSignupPayload('');
                setVerificationCode('');
            },
            onFailure: () => {
                setLoading(false);
                notify('Invalid OTP code used..', notifType.DANGER);
            }
        });
    };

    const submitForgotPassword = async (email) => {
        setLoading(true);
        setEmailAddress(email);
        setOtp('');
        setTimer(90);

        getOTPForForgotPassword(email,
            () => {
                setForgotOtp(true);
                setForm('reset');
            },
            () => {
                notify('Invalid email used.', notifType.DANGER);
            });
        setLoading(false);
    };

    const handleSubmitLoginWithPhone = async () => {
        if (loginWithPhone === '') {
            notify('Please enter your mobile number.', notifType.DANGER);

            return true;
        }

        setOtp('');
        handleGetOTPForLogin(loginWithPhone);
    };

    const handleShowLoginWithOTP = () => {
        setForm('loginOtp');
        handleHideOTP();
    };

    const handleResendCode = () => {
        if (timer > 0) {
            notify(`Error ! after ${timer} seconds you can resend SMS again`, notifType.DANGER);

            return true;
        }

        setOtp('');

        if (form === 'reset') {
            getOTPForForgotPassword(emailAddress);
        } else {
            handleGetOTPForLogin(loginWithPhone);
        }

        setTimer(90);
    };

    const handleSubmitOtp = async (type) => {
        if (type === 'login' && !isReset) {
            handleLoginWithOTP({ otp });
            setOtp('');
        }

        if (isReset || type === 'forgot' || (forgotOtp && form === 'reset')) {
            setLoading(true);

            try {
                await checkOTPCode(otp,
                    () => {
                        setForgotOtp(false);

                        if (isReset) {
                            setForm('reset');
                        }
                    },
                    () => {
                        notify('The OTP code is incorrect.', notifType.DANGER);
                    });
            } catch (error) {
                if (error.response.status) {
                    notify('The OTP code is incorrect.', notifType.DANGER);
                }
            }

            setLoading(false);
        }
    };

    const submitResetPassword = async password => {
        setLoading(true);

        try {
            await passwordReset(
                {
                    otp_code: otp,
                    password,
                    password_confirmation: password
                },
                () => {
                    onPasswordResetSuccess();
                },
                () => {
                    onPasswordResetFailure();
                }
            );
        } catch (error) {
            if (error.response.status) {
                onPasswordResetFailure();
            }
        }

        setLoading(false);
    };

    const onPasswordResetSuccess = () => {
        notify('Password changed successfully.', notifType.SUCCESS);
        setForm('signin');
        setForgotOtp(false);

        if (isReset) {
            toggleModal();
        }

        setOtp('');
    };

    const onPasswordResetFailure = () => notify('Please try again.', notifType.DANGER);

    useEffect(() => {
        const forgot = query.get('forgot');

        if (forgot && forgot === 'true') {
            handleChangeForm('forgot');
        }
    }, [query]);

    useEffect(() => {
        if (isReset) {
            setForm('loginOtp');
        }
    }, [isReset]);

    useEffect(() => {
        if (showOTP || forgotOtp) {
            const time = setTimeout(() => {
                if (timer > 0) {
                    setTimer(timer - 1);
                }
            }, 1000);

            return () => clearTimeout(time);
        } else {
            setTimer(90);
        }
    }, [forgotOtp, showOTP, timer]);

    useEffect(() => {
        if (signUp) {
            setForm('signup');
        }
    }, [signUp]);

    const getData = async () => {
        try {
            const res = await getUserIP();

            setIP(res);
        } catch (error) {
            //
        }
    };

    const handleUrlChange = () => {
        switch (location.pathname) {
            case ROUTES.SINGPASS:
                setForm('singpass');
                break;
            case ROUTES.DOCUMENTSPROOF:
                if (profile?.is_student) {
                    if (profile?.pass_proof_of_expiry_required) {
                        setForm('documentproof');
                    } else {
                        history.push(ROUTES.ORDER_SUMMARY);
                    }
                } else {
                    if (profile?.pass_proof_of_expiry_required) {
                        setForm('documentproof');
                    } else {
                        history.push(ROUTES.ORDER_SUMMARY);
                    }
                }

                break;
            case ROUTES.SINGPASS_RESULT:
                setForm('singpassresult');
                break;
            case ROUTES.SINGPASS_MANUALL_FORM:
                setForm('singpassmanuall');
                break;
            default:
                setForm('signup');
                break;
        }
    };

    useEffect(() => getData(), []);

    useEffect(() => {
        handleUrlChange();
        const unlisten = history.listen(handleUrlChange);

        return () => unlisten();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const getRentalPeriodId = (product) => {
        const userType = 'b2c';

        const durationTime = product?.rental_period?.find(rp => rp.id === product?.selectedRentalPeriod?.id)?.duration_time;
        const rentalPeriod = product?.rental_period?.find(rp => rp.duration_time === durationTime && rp.user_type === userType);

        return rentalPeriod?.id ?? '';
    };

    const sendProductsToBE = async () => {
        const makeProducts = [];

        for (const product of products) {
            const selectedRentalPeriodId = getRentalPeriodId(product);

            makeProducts.push(
                {
                    is_purchase: false,
                    product_attribute_value_id: product.selectedStorage ? product.selectedStorage.value
                        : (
                            product.attributes.specifications.values[0].id ||
                            product.attributes.inside_box.values[0].id
                        ),
                    quantity: 1,
                    rental_period_id: selectedRentalPeriodId,
                    rental_period_duration: product?.rental_period?.find(rp => rp.id === selectedRentalPeriodId)?.duration_time,
                    product_id: product.id,
                    color_id: product.selectedColor.value,
                    deal_id: null
                }
            );
        }

        try {
            await addItemsToCart(makeProducts);

            const resp = await getUserCart();

            if (profile?.singpass_status === 'verified') {
                if (resp?.data?.products?.length === 0) {
                    notify(`Product${makeProducts.length > 1 ? 's' : ''} added to cart successfuly`, notifType.SUCCESS);
                }
            }

            handleRoute();
        } catch (err) {
            //
        }
    };

    const handleRoute = () => {
        if (profile?.singpass_status === 'verified') {
            validateCheckout();
        } else {
            setIsLoading(false);
            setForm('singpass');
        }
    };

    const validateCheckout = async () => {
        const resp = await checkoutRules();

        setIsLoading(true);

        if (resp?.status === 204) {
            // if (profile?.is_student) {
            //     if (profile?.pass_proof_of_expiry_required) {
            //         history.push(ROUTES.DOCUMENTSPROOF);
            //     } else {
            //         history.push(ROUTES.ORDER_SUMMARY);
            //     }
            // } else {
            //     history.push(ROUTES.ORDER_SUMMARY);
            // }
            history.push(ROUTES.ORDER_SUMMARY);
        } else {
            notify(resp?.data?.message?.message, notifType.DANGER, 5000);
            history.push(ROUTES.CART);
        }
    };

    const redirectToScreen = () => {
        setIsLoading(true);

        if (products.length !== 0) {
            sendProductsToBE();
        } else {
            checkUserCart();
        }
    };

    const checkUserCart = async () => {
        const resp = await getUserCart();

        if (profile.singpass_status === 'verified') {
            if (resp?.data?.products?.length > 0) {
                setUserCart(resp?.data?.products);
                validateCheckout();
            } else {
                setIsLoading(false);
                history.push(ROUTES.PRODUCTS);
            }
        } else {
            setIsLoading(false);
            setForm('singpass');
        }
    };

    useEffect(() => {
        if (profile.id && doRegister) {
            ReactGA.event('user_register', {
                category: 'user_auth',
                label: 'user_register',
                value: profile.id,
                user_ID: profile.id,
                fp: Cookies.get('_fp'),
                timestamp: new Date()
            });

            redirectToScreen();

            FS.event('User registered', {
                user_id: profile.id.toString()
            });

            trackKlaviyoEvent('identify', profile);

            trackFreshChatUser('identify', profile);
        }

        if (profile.id && doLogin) {
            ReactGA.event('user_login', {
                category: 'user_auth',
                label: 'user_login',
                value: profile.id,
                user_ID: profile.id,
                fp: Cookies.get('_fp'),
                timestamp: new Date()
            });

            redirectToScreen();

            FS.event('User login', {
                user_id: profile.id.toString()
            });

            trackKlaviyoEvent('identify', profile);

            trackFreshChatUser('identify', profile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, doRegister, doLogin, clearCart, history]);

    const triggerLoginSubmitEvent = () => {
        setLoading(true);
        handleLogin({
            email: authData.email,
            password: authData.password,
            gaSessionId: Cookies.get('_ga') || 'no-session',
            trueIp: ip || 'no-ip',
            onSuccess: () => {
                setDoLogin(true);

                if (products.length !== 0) {
                    sendProductsToBE();
                }

                if (window.location.pathname === ROUTES.HOME) {
                    dispatch(updateAuthenticationDataFromStateToStore({}));
                    window.location.reload();
                }
            },
            onFailure: () => {
                setLoading(false);
                notify('Please try again.', notifType.DANGER);
            }
        });
        setTimeout(() => setLoading(false), [5000]);
    };

    const triggerRegistrationSubmitEvent = () => {
        setLoading(true);

        setLoading(true);
        isStudent = isStudentDomain(authData.email);

        if (isStudent) {
            setSignupPayload(authData.email);
            setEmailAddress(authData.email);
            getVerificationCode(authData.email);
            dispatch(updateAuthenticationDataFromStateToStore({
                email: authData.email,
                password: authData.password,
                confirmPassword: authData.confirmPassword
            }));
        } else {
            handleRegistration({
                email: authData.email,
                password: authData.password,
                confirmPassword: authData.confirmPassword,
                gaSessionId: Cookies.get('_ga') || 'no-session',
                trueIp: ip || 'no-ip',
                onSuccess: () => {
                    setDoRegister(true);

                    if (products.length !== 0) {
                        sendProductsToBE();
                    }

                    dispatch(updateAuthenticationDataFromStateToStore({}));
                },
                onFailure: () => {
                    setLoading(false);
                    notify('Please try again.', notifType.DANGER);
                }
            });
            setTimeout(() => {
                setLoading(false);
            }, [5000]);
        }
    };

    const triggerOTPInputSubmitEvent = () => {
        setLoading(true);

        if (otpInput.otp === '') {
            notify('Please enter the OTP code.', notifType.DANGER);
        }

        handleVerifyStudentVerificationCode({
            code: otpInput.otp,
            email: otpInput.email,
            onSuccess: () => {
                if (isMobile) {
                    doRegistration({
                        email: authData.email,
                        password: authData.password,
                        confirmPassword: authData.confirmPassword
                    });
                } else {
                    doRegistration(signupPayload);
                }

                setSignupPayload('');
                setVerificationCode('');
            },
            onFailure: () => {
                setLoading(false);
                notify('Invalid OTP code used..', notifType.DANGER);
            }
        });
        setTimeout(() => {
            setLoading(false);
        }, [5000]);
    };

    const onAuthorizedSingpass = redirectUrl => {
        window.location.href = redirectUrl;
    };

    const onRejectSingpassAuthorization = includeNoa => {
        setIsLoading(false);

        if (!includeNoa) {
            setMessageModalMessage({ type: 'failed', message: 'Unable to retrieve your information from Singpass.' });
        } else {
            setMessageModalMessage({
                type: 'failed',
                message: `
                We are sorry, you are not eligible for our services at the moment.
                If you feel that this is a mistake or to better understand our policies
                please feel free to contact us at support@cinch.sg or +65 8168-8604.
`
            });
        }

        setMessageModalOpen(true);
    };

    const onRetrieveInfo = () => {
        localStorage.removeItem('notificationplacedonetime');
        setIsLoading(!isLoading);

        const payload = {
            event: 'singpass_attempt'
        };

        storeUserEvent(payload);

        dispatch(handleAuthorizeSingpass({ include_noa: includeNoa }, onAuthorizedSingpass, () => onRejectSingpassAuthorization(includeNoa)));
    };

    const backToTheCart = () => history.push(ROUTES.CART);

    return (
        <>
            <CartLayout
                cartItems={userCart}
                isStyledCard={false}>
                {(form === 'singpass' || form === 'documentproof' || form === 'singpassresult' || form === 'singpassmanuall')
                    ? <div className='checkoutFlowContainer'>
                        {profile?.singpass_status === 'rejected' && <Redirect to={ROUTES.SINGPASS} />}
                        {(form === 'singpass') &&
                            <SingPassScreen profile={profile} />}
                        {(form === 'documentproof' && (profile?.pass_proof_of_expiry_required || profile?.is_student)) &&
                            <SupportingDocuments profile={profile} />}
                        {form === 'singpassresult' &&
                            <SingpassResultContainer profile={profile} />
                        }
                        {form === 'singpassmanuall' &&
                            <SingpassManualFormContainer />
                        }
                    </div>
                    : <div className='signup'>
                        {isLoading && <FullscreenLoader />}
                        {form === 'signin' &&
                            <LoginForm
                                showLoginWithOTP={handleShowLoginWithOTP}
                                submit={submitLogin}
                                changeForm={onForgotPassword}
                                isStudent={localStorage.getItem('is_student')}
                                loading={isAuthenticating} />
                        }
                        {form === 'signup' &&
                            <SignUpForm
                                submit={submitSignUp}
                                changeForm={handleChangeForm}
                                isStudent={localStorage.getItem('is_student')}
                                loading={isAuthenticating} />}

                        {(form === 'loginOtp' && !showOTP && !isReset) &&
                            <LoginOTPForm
                                phone={loginWithPhone}
                                setPhone={handleLoginWithPhone}
                                submit={handleSubmitLoginWithPhone}
                                changeForm={handleChangeForm}
                                loading={isAuthenticating} />}
                        {form === 'reset' && !forgotOtp &&
                            <ResetPassword
                                submit={submitResetPassword}
                                changeForm={handleChangeForm}
                                isReset={isReset}
                                loading={loading} />}
                        {form === 'reset' && forgotOtp &&
                            <OTPForm
                                lastnumber={lastnumber}
                                otp={otp}
                                setOtp={setOtp}
                                email={emailAddress}
                                type={'forgot'}
                                resendCode={handleResendCode}
                                timer={timer}
                                submit={handleSubmitOtp}
                                changeForm={handleChangeForm}
                                loading={loading}
                                showResend={true} />}
                        {form === 'studentVerification' &&
                            <OTPForm
                                otp={verificationCode}
                                email={emailAddress}
                                setOtp={setVerificationCode}
                                submit={handleSubmitVerificationCode}
                                changeForm={handleChangeForm}
                                loading={loading}
                                showResend={false} />}
                    </div>}
            </CartLayout>
            {(
                isMobile &&
                location.pathname !== ROUTES.DOCUMENTSPROOF &&
                location.pathname !== ROUTES.SINGPASS_MANUALL_FORM &&
                location.pathname !== ROUTES.SINGPASS_RESULT &&
                profile?.singpass_status !== 'verified'
            ) &&
                <div className='sticky__bottom__auth'>
                    <div className='cta_buttons'>
                        {(form !== 'singpass') &&
                            <button
                                onClick={backToTheCart}>
                                Back
                            </button>}
                        {(form === 'signin') && <Button
                            loading={loading}
                            type='button'
                            title={'Sign In'}
                            className={'submit'}
                            disabled={authData.email === '' || authData.password === ''}
                            onClick={triggerLoginSubmitEvent}>Sign In</Button>}

                        {(form === 'signup') && <Button
                            loading={loading}
                            type='button'
                            title={'SignUp'}
                            className={'submit'}
                            disabled={authData.email === '' || authData.password === ''}
                            onClick={triggerRegistrationSubmitEvent}>Sign Up</Button>}

                        {(form === 'studentVerification') && <Button
                            loading={loading}
                            type='button'
                            title={'Verify OTP'}
                            className={'submit'}
                            disabled={otpInput.otp === ''}
                            onClick={triggerOTPInputSubmitEvent}>Submit</Button>}

                        {(form === 'reset' && forgotOtp) && <Button
                            loading={loading}
                            type='button'
                            title={'Verify OTP'}
                            className={'submit'}
                            disabled={otpInput.otp === '' || otpInput.otp?.length < 6}
                            onClick={() => handleSubmitOtp()}>Submit</Button>}

                        {(form === 'reset' && !forgotOtp) && <Button
                            loading={loading}
                            type='button'
                            title={'Submit'}
                            className={'submit'}
                            disabled={authData.password === '' || authData.confirmPassword !== authData.password}
                            onClick={() => submitResetPassword(authData.password)}>Submit</Button>}

                        {(form === 'singpass') &&
                            <div className='SingPassVerification__buttons-container checkout__singpass__container'>
                                <img src={RetrieveMyInfo} alt='Retrieve My Info on SingPass' onClick={onRetrieveInfo} />
                            </div>}
                    </div>
                </div>}
        </>
    );
};

const mapStateToProps = ({ cart, auth }) => {
    const { products, product } = cart;
    const { isAuthenticating, showOTP, signUp, profile } = auth;

    return {
        products: JSON.parse(products),
        isAuthenticating,
        showOTP,
        signUp,
        product,
        profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleLogin: ({
            email,
            password,
            isRemember,
            gaSessionId,
            trueIp,
            onSuccess
        }) => {
            dispatch(handleLogin({
                email,
                password,
                isRemember,
                gaSessionId,
                trueIp,
                onSuccess
            }));
        },
        handleRegistration: ({
            email,
            password,
            confirmPassword,
            subscriptionAcknowledge,
            gaSessionId,
            trueIp,
            onSuccess,
            onFailure
        }) => {
            dispatch(handleRegistration({
                email,
                password,
                confirmPassword,
                subscriptionAcknowledge,
                gaSessionId,
                trueIp,
                onSuccess,
                onFailure
            }));
        },
        handleGetOTPForLogin: (phone) => dispatch(handleGetOTPForLogin({ phone })),
        handleHideOTP: () => { dispatch(handleHideOTP()); },
        handleLoginWithOTP: ({ otp }) => { dispatch(handleLoginWithOTP({ otp })); },
        clearCart: () => dispatch(clearCart()),
        handleGetStudentVerificationOTP: ({ email, onSuccess, onFailure }) => dispatch(handleGetStudentVerificationOTP({ email, onSuccess, onFailure })),
        handleVerifyStudentVerificationCode: ({ code, email, onSuccess, onFailure }) => dispatch(handleVerifyStudentVerificationCode({ code, email, onSuccess, onFailure }))
    };
};

CheckoutStart.propTypes = {
    isAuthenticating: PropTypes.bool.isRequired,
    handleLogin: PropTypes.func.isRequired,
    handleRegistration: PropTypes.func.isRequired,
    handleGetOTPForLogin: PropTypes.func,
    showOTP: PropTypes.bool,
    handleHideOTP: PropTypes.func,
    handleLoginWithOTP: PropTypes.func,
    signUp: PropTypes.bool,
    clearCart: PropTypes.func.isRequired,
    product: PropTypes.any,
    profile: PropTypes.any,
    isReset: PropTypes.bool,
    resetEmail: PropTypes.string,
    toggleModal: PropTypes.func,
    handleGetStudentVerificationOTP: PropTypes.func,
    handleVerifyStudentVerificationCode: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStart);
