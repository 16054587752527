import { createAxiosInstance, getAuthToken } from 'utils/helpers';

const initialState = {
    products: localStorage.getItem('products') || '[]',
    fetchedProducts: [],
    overview: {},
    isClearingFetchedProducts: false,
    isLoading: false,
    product: null,
    currentCartItemPricingObj: {},
    toggleAddToCartEvent: false,
    selectedCartItem: {}
};

const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
const REMOVE_ONE_ITEM_FROM_CART = 'REMOVE_ONE_ITEM_FROM_CART';
const CLEAR_CART = 'CLEAR_CART';
const FETCH_CART_START = 'FETCH_CART_START';
const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';
const UPDATE_CART_ITEM_START = 'UPDATE_CART_ITEM_START';
const UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS';
const UPDATE_CART_ITEM_FAILURE = 'UPDATE_CART_ITEM_FAILURE';
const REMOVE_CART_ITEM_START = 'REMOVE_CART_ITEM_START';
const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
const REMOVE_CART_ITEM_FAILURE = 'REMOVE_CART_ITEM_FAILURE';
const CLEAR_FETCHED_CART_START = 'CLEAR_FETCHED_CART_START';
const CLEAR_FETCHED_CART_SUCCESS = 'CLEAR_FETCHED_CART_SUCCESS';
const CLEAR_FETCHED_CART_FAILURE = 'CLEAR_FETCHED_CART_FAILURE';
const TRIGGER_ADD_TO_CART_EVENT = 'TRIGGER_ADD_TO_CART_EVENT';
const TOGGLE_STICKY_BOTTOM_ADD_CART_EVENT = 'TOGGLE_STICKY_BOTTOM_ADD_CART_EVENT';
const CLEAR_CART_AFTER_ORDERPLACING = 'CLEAR_CART_AFTER_ORDERPLACING';
const UPDATE_SELECTED_CART_ITEM = 'UPDATE_SELECTED_CART_ITEM';

export const addProductToCart = (product) => ({
    type: ADD_TO_CART,
    product
});

export const removeProductfromCart = (product) => ({
    type: REMOVE_FROM_CART,
    product
});

export const removeOneProductfromCart = (product) => ({
    type: REMOVE_ONE_ITEM_FROM_CART,
    product
});

export const clearCart = () => ({
    type: CLEAR_CART
});

export const fetchCartStart = () => ({
    type: FETCH_CART_START
});

export const fetchCartSuccess = (data) => ({
    type: FETCH_CART_SUCCESS,
    data
});

export const fetchCartFailure = () => ({
    type: FETCH_CART_FAILURE
});

export const updateCartItemStart = () => ({
    type: UPDATE_CART_ITEM_START
});

export const updateCartItemSuccess = (data) => ({
    type: UPDATE_CART_ITEM_SUCCESS,
    data
});

export const updateCartItemFailure = () => ({
    type: UPDATE_CART_ITEM_FAILURE
});

export const removeCartItemStart = () => ({
    type: REMOVE_CART_ITEM_START
});

export const removeCartItemSuccess = (data) => ({
    type: REMOVE_CART_ITEM_SUCCESS,
    data
});

export const removeCartItemFailure = () => ({
    type: REMOVE_CART_ITEM_FAILURE
});

export const clearFetchedCartStart = () => ({
    type: CLEAR_FETCHED_CART_START
});

export const clearFetchedCartSuccess = () => ({
    type: CLEAR_FETCHED_CART_SUCCESS
});

export const clearFetchedCartFailure = () => ({
    type: CLEAR_FETCHED_CART_FAILURE
});

export const triggerAddToCartEventFromOutside = (data) => ({
    type: TRIGGER_ADD_TO_CART_EVENT,
    data
});

export const toggleAddToCartEventFromStickyBottomBar = (data) => ({
    type: TOGGLE_STICKY_BOTTOM_ADD_CART_EVENT,
    data
});

export const clearTheCartLocallyWhenOrderPlaces = () => ({
    type: CLEAR_CART_AFTER_ORDERPLACING
});

export const updateSelectedCartItem = (data) => ({
    type: UPDATE_SELECTED_CART_ITEM,
    data
});

export const handleFetchingCart = () => {
    return async (dispatch) => {
        dispatch(fetchCartStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/user/cart?channel=samsung');

            dispatch(fetchCartSuccess(data));
        } catch (e) {
            dispatch(fetchCartFailure());
        }
    };
};

export const handleClearCart = () => {
    return async (dispatch) => {
        dispatch(clearFetchedCartStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        if (token) {
            try {
                await instance.post('/user/cart/empty');

                dispatch(clearFetchedCartSuccess());
            } catch {
                dispatch(clearFetchedCartFailure());
            }
        } else {
            try {
                localStorage.removeItem('products');
                localStorage.removeItem('currentCartItemPricingObj');

                dispatch(clearFetchedCartSuccess());
            } catch {
                dispatch(clearFetchedCartFailure());
            }
        }
    };
};

export const handleUpdateCartItem = (form, id, onFailure = () => { }) => {
    return async (dispatch) => {
        dispatch(updateCartItemStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        if (token) {
            try {
                await instance.put(`/user/cart/${id}`, form);

                dispatch(handleFetchingCart());
                dispatch(updateCartItemSuccess());
            } catch (err) {
                dispatch(updateCartItemFailure());

                if (err.response) {
                    onFailure(err.response);
                }
            }
        } else {
            try {
                // update in local storage
                const products = JSON.parse(localStorage.getItem('products'));
                const colourId = form?.color_id;
                const pavId = id;
                const rentalPeriodId = form.rental_period_id;
                const noStorage = (pavId === colourId);

                // find the product with matching id and color
                const product = products?.find(item => {
                    if (noStorage) {
                        return item.selectedColor.value === colourId;
                    } else {
                        return item?.selectedStorage?.value === pavId && item.selectedColor?.value === colourId;
                    }
                });

                // update the product with the rental period
                const newRentalPeriod = product.rental_period.find(period => period.id === rentalPeriodId);

                // update the product with the rental period in local storage
                product.selectedRentalPeriod = newRentalPeriod;

                // update the product in local storage
                const index = products.findIndex(item => {
                    if (noStorage) {
                        return item.selectedColor.value === colourId;
                    } else {
                        return item.selectedStorage?.value === pavId && item.selectedColor?.value === colourId;
                    }
                });

                products[index] = product;

                localStorage.setItem('products', JSON.stringify(products));

                dispatch(updateCartItemSuccess());
            } catch {
                dispatch(updateCartItemFailure());
            }
        }
    };
};

export const handleUpdateCartForCoupon = (form) => {
    return async (dispatch) => {
        dispatch(updateCartItemStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.post('/user/cart/', form);

            dispatch(handleFetchingCart());
            dispatch(updateCartItemSuccess());
        } catch {
            dispatch(updateCartItemFailure());
        }
    };
};

export const handleRemoveCartItem = (id, onSuccess) => {
    return async (dispatch) => {
        dispatch(removeCartItemStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        if (token) {
            try {
                await instance.delete(`/user/cart/${id}`);

                dispatch(handleFetchingCart());
                dispatch(removeCartItemSuccess());
                onSuccess();
            } catch {
                dispatch(removeCartItemFailure());
            }
        } else {
            try {
                const products = JSON.parse(localStorage.getItem('products'));

                const index = products.findIndex(item => item.selectedStorage?.value === id || item.selectedColor?.value === id);

                products.splice(index, 1);

                localStorage.setItem('products', JSON.stringify(products));

                dispatch(removeCartItemSuccess());
            } catch {
                dispatch(removeCartItemFailure());
            }
        }
    };
};

export default function cart(state = initialState, action) {
    switch (action.type) {
        case ADD_TO_CART: {
            const { product } = action;

            const products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];

            const checkIfExist = products && products.findIndex(e => (e?.id === product?.id));

            if (checkIfExist < 0) {
                products.push({ ...product, qty: 1 });
            } else {
                products[checkIfExist] = product;
            }

            localStorage.setItem('products', JSON.stringify(products));

            return {
                ...state,
                product,
                products: JSON.stringify(products)
            };
        }

        case REMOVE_FROM_CART: {
            // products;
            // const { product } = action;
            // const products = JSON.parse(state.products);

            // const checkIfExist = products.findIndex(e => (e.id === product.id) && (e.color.value === product.color.value));

            // if (checkIfExist > -1) {
            //     products[checkIfExist].qty--;

            //     if (products[checkIfExist].qty < 1) {
            //         products.splice(checkIfExist, 1);
            //     }
            // }

            return {
                ...state,
                product: null
                // products: JSON.stringify(products)
            };
        }

        case REMOVE_ONE_ITEM_FROM_CART: {
            const { product } = action;
            const products = JSON.parse(state.products);

            const checkIfExist = products.findIndex(e => e.id === product.id && e.selectedRentalPeriod.id === product.selectedRentalPeriod.id);

            if (checkIfExist > -1) {
                products.splice(checkIfExist, 1);
            }

            return {
                ...state,
                products: JSON.stringify(products)
            };
        }

        case CLEAR_CART: {
            localStorage.removeItem('products');
            localStorage.removeItem('currentCartItemPricingObj');

            return {
                ...state,
                products: '[]',
                product: null
            };
        }

        case FETCH_CART_START: {
            return {
                ...state,
                isLoading: true
            };
        }

        case FETCH_CART_SUCCESS: {
            return {
                ...state,
                fetchedProducts: action.data?.data?.products || [],
                overview: action.data?.meta || {},
                isLoading: false
            };
        }

        case CLEAR_CART_AFTER_ORDERPLACING: {
            return {
                ...state,
                products: '[]',
                product: null,
                fetchedProducts: [],
                overview: {},
                isLoading: false
            };
        }

        case FETCH_CART_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        case CLEAR_FETCHED_CART_START: {
            return {
                ...state,
                isClearingFetchedProducts: true,
                isLoading: true
            };
        }

        case CLEAR_FETCHED_CART_SUCCESS: {
            return {
                ...state,
                fetchedProducts: [],
                overview: {},
                isClearingFetchedProducts: false,
                isLoading: false
            };
        }

        case CLEAR_FETCHED_CART_FAILURE: {
            return {
                ...state,
                isClearingFetchedProducts: false,
                isLoading: false
            };
        }

        case UPDATE_CART_ITEM_START:
        case REMOVE_CART_ITEM_START:
            return {
                ...state,
                isLoading: true
            };

        case UPDATE_CART_ITEM_SUCCESS:
        case UPDATE_CART_ITEM_FAILURE:
        case REMOVE_CART_ITEM_SUCCESS:
        case REMOVE_CART_ITEM_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case TRIGGER_ADD_TO_CART_EVENT:
            localStorage.setItem('currentCartItemPricingObj', JSON.stringify(action.data));

            return {
                ...state,
                currentCartItemPricingObj: action.data
            };

        case TOGGLE_STICKY_BOTTOM_ADD_CART_EVENT:
            return {
                ...state,
                toggleAddToCartEvent: action.data
            };

        case UPDATE_SELECTED_CART_ITEM:
            return {
                ...state,
                selectedCartItem: action.data
            };

        default:
            return state;
    }
}
