import React, { useState } from 'react';
import { Modal, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ROUTES } from 'config/constants';

import FirstPayment from 'assets/images/general/first-payment.svg';
import FraudProtection from 'assets/images/general/fraud-protection.svg';
import ResaleRestriction from 'assets/images/general/resale-restriction.svg';
import CheckboxUnselected from 'assets/images/checkbox-unselected.png';
import CheckboxSelected from 'assets/images/checkbox-selected.png';

import './CheckoutPaymentInfo.scss';

export default function CheckoutPaymentInfo({
    isOpen,
    onToggle,
    placeOrder,
    backToOrderSummary
}) {
    const [termsNConditions, setTermsNConditions] = useState(false);
    const [propertyOfCinch, setPropertyOfCinch] = useState(false);

    const onChangeTermsNConditions = () => setTermsNConditions(!termsNConditions);

    const onChangeSetPropertyOfCinch = () => setPropertyOfCinch(!propertyOfCinch);

    return (
        <Modal isOpen={isOpen} centered={true} toggle={onToggle}
            backdrop={true} className='checkoutPaymentInfo'>
            <div className='checkoutPaymentInfo__container d-flex align-items-center flex-col'>
                <div className='w-100 checkoutPaymentInfo__container__header'>
                    <div className='user-consent-modal-header'>
                        <h3>Congratulations!</h3>
                        <p className='mb-0'>You&apos;re almost there!</p>
                    </div>
                    <div className='images-container'>
                        <div className='images-container__block p-0'>
                            <p>A few things to remember</p>
                        </div>
                        <div className='images-container__block d-flex align-items-center'>
                            <div className='images-container__image-container'>
                                <img src={FirstPayment} alt='Sufficient funds'
                                    className='images-container__first-payment'/>
                            </div>
                            <span className='text-center'>Your first payment is immediate</span>
                        </div>
                        <div className='images-container__block d-flex align-items-center'>
                            <div className='images-container__image-container'>
                                <img src={FraudProtection} alt='Fraud protection'
                                    className='images-container__fraud-protection'/>
                            </div>
                            <span className='text-center'>Protected against fraud</span>
                        </div>
                        <div className='images-container__block d-flex align-items-center pb-0 mb-0'>
                            <div className='images-container__image-container'>
                                <img src={ResaleRestriction} alt='Resale not allowed'
                                    className='images-container__resale-restriction'/>
                            </div>
                            <span className='text-center'>Resale is not allowed</span>
                        </div>
                    </div>
                    <hr className='divider'/>
                    <div className='body-container'>
                        <div className='mb-3'>
                            <FormGroup className='agreements-list d-flex p-0 m-0' check={true} inline={true}>
                                <img id='propertyOfCinch' name='propertyOfCinch' src={propertyOfCinch ? CheckboxSelected : CheckboxUnselected}
                                    alt='knox Disclaimer indicator' onClick={onChangeSetPropertyOfCinch} />
                                <div>
                                    <Label check={true}
                                        onClick={onChangeSetPropertyOfCinch}
                                        className='cursor-pointer'
                                        htmlFor='propertyOfCinch'>
                                        I understand that this device is property of Cinch and cannot be resold</Label>
                                </div>
                            </FormGroup>
                        </div>
                        <div>
                            <FormGroup className='agreements-list d-flex p-0 m-0' check={true} inline={true}>
                                <img id='termsNConditions' name='termsNConditions' src={termsNConditions ? CheckboxSelected : CheckboxUnselected}
                                    alt='knox Disclaimer indicator' onClick={onChangeTermsNConditions} />
                                <div>
                                    <Label check={true}
                                        onClick={onChangeTermsNConditions}
                                        className='cursor-pointer'
                                        htmlFor='termsNConditions'>
                                        I accept the <Link to={ROUTES.TERMS_AND_CONDITIONS} target='_blank'>Terms & Conditions</Link> and <Link to={ROUTES.PRIVACY_POLICY} target='_blank'>Privacy Policy</Link>
                                    </Label>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <div className='align-items-center bottom-container'>
                    <div className='checkoutPaymentInfo__container__content'>
                        <p className={`checkoutPaymentInfo__container__content__noteextra mb-0 ${(propertyOfCinch && termsNConditions) && ' checkoutPaymentInfo__container__content__noteextraHidden'}`}>*Almost there! Check all the boxes to proceed.</p>
                    </div>
                    <button onClick={placeOrder} disabled={!propertyOfCinch || !termsNConditions} className='orderSummary__button'>Finish</button>
                    <button onClick={backToOrderSummary} className='orderSummary__back__button'>Back</button>
                </div>
            </div>
        </Modal>
    );
}

CheckoutPaymentInfo.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func,
    placeOrder: PropTypes.func,
    backToOrderSummary: PropTypes.func,
    onChangeTermsNConditions: PropTypes.func,
    termsNConditions: PropTypes.bool
};
