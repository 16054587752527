import { createAxiosInstance, getAuthToken } from 'utils/helpers';
import { notifType, notify } from 'utils/notifSender';

const initialState = {
    cardList: [],
    isLoading: false,
    selectedPaymentMethodId: ''
};

const FETCH_CARD_LIST_START = 'FETCH_CARD_LIST_START';
const FETCH_CARD_LIST_SUCCESS = 'FETCH_CARD_LIST_SUCCESS';
const FETCH_CARD_LIST_FAILURE = 'FETCH_CARD_LIST_FAILURE';

const REMOVE_CARD_START = 'REMOVE_CARD_START';
const REMOVE_CARD_SUCCESS = 'REMOVE_CARD_SUCCESS';
const REMOVE_CARD_FAILURE = 'REMOVE_CARD_FAILURE';

const SET_SELECTED_PAYMENT_METHOD_ID = 'SET_SELECTED_PAYMENT_METHOD_ID';

export const fetchCardListStart = () => ({
    type: FETCH_CARD_LIST_START
});

export const fetchCardListSuccess = (data) => ({
    type: FETCH_CARD_LIST_SUCCESS,
    data
});

export const fetchCardListFailure = () => ({
    type: FETCH_CARD_LIST_FAILURE
});

export const removeCardStart = () => ({
    type: REMOVE_CARD_START
});

export const removeCardSuccess = (id) => ({
    type: REMOVE_CARD_SUCCESS,
    id
});

export const removeCardFailure = () => ({
    type: REMOVE_CARD_FAILURE
});

export const setSelectedPaymentMethodId = (id) => ({
    type: SET_SELECTED_PAYMENT_METHOD_ID,
    payload: id
});

export const handleFetchPaymentIntent = (onSuccess) => {
    return async () => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/payment-method/intent');

            onSuccess(data);
        } catch (e) {
            // console.error(e);
        }
    };
};

export const handleFetchCardList = (onSuccess = () => { }) => {
    return async (dispatch) => {
        dispatch(fetchCardListStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/payment-method');

            dispatch(fetchCardListSuccess(data?.data));
            onSuccess();
        } catch (e) {
            dispatch(fetchCardListFailure());
        }
    };
};

export const handleCreateNewCard = (paymentToken, onSuccess = () => { }, onFailure = () => { }) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.post('/payment-method', { token: paymentToken });

            dispatch(setSelectedPaymentMethodId(paymentToken));
            dispatch(makeDefaultCard(paymentToken));
            onSuccess(data);
        } catch (e) {
            onFailure();
        }
    };
};

export const handleRemoveCard = (paymentMethodId) => {
    return async (dispatch) => {
        dispatch(removeCardStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.delete(`/payment-method/${paymentMethodId}`);

            dispatch(removeCardSuccess(paymentMethodId));
        } catch (e) {
            if (e.response.data.error) {
                notify(e.response.data.error, notifType.DANGER);
            }

            dispatch(removeCardFailure());
        }
    };
};

export const makeDefaultCard = (paymentMethodId) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.post('/payment-method/default', { id: paymentMethodId });
            dispatch(handleFetchCardList());
            notify('Default payment method updated.', notifType.SUCCESS);
        } catch (e) {
            if (e.response.data.error) {
                notify(e.response.data.error, notifType.DANGER);
            }
        }
    };
};

export default function cart(state = initialState, action) {
    switch (action.type) {
        case FETCH_CARD_LIST_START:
        case REMOVE_CARD_START:
            return {
                ...state,
                isLoading: true
            };

        case FETCH_CARD_LIST_SUCCESS:
            return {
                ...state,
                cardList: action.data,
                isLoading: false
            };

        case REMOVE_CARD_SUCCESS:
            return {
                ...state,
                cardList: state.cardList.filter((item) => item.payment_method_id !== action.id),
                isLoading: false,
                selectedPaymentMethodId: action.id === state.selectedPaymentMethodId ? '' : state.selectedPaymentMethodId
            };

        case FETCH_CARD_LIST_FAILURE:
        case REMOVE_CARD_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case SET_SELECTED_PAYMENT_METHOD_ID:
            return {
                ...state,
                selectedPaymentMethodId: action.payload
            };
        default:
            return state;
    }
}
