import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './CustomLinearProgress.module.scss';

const CustomLinearProgress = ({ loading }) => {
    const [progressWidth, setProgressWidth] = useState(0);

    useEffect(() => {
        const showProgress = () => {
            if (!document.getElementById('progress')) {
                const progressBar = document.createElement('div');

                progressBar.setAttribute('id', 'progress');
                progressBar.innerHTML = '<b></b><i></i>';

                document.body.appendChild(progressBar);
                const progressElement = document.getElementById('progress');

                setProgressWidth(101);

                setTimeout(() => {
                    setProgressWidth(0);

                    setTimeout(() => {
                        document.body.removeChild(progressElement);
                    }, 800);
                }, 1500);
            }
        };

        if (loading) {
            showProgress();
        }
    }, [loading]);

    return (
        <div className={styles.progress} style={{ width: `${progressWidth}%` }}>
            <b />
            <i />
        </div>
    );
};

CustomLinearProgress.propTypes = {
    loading: PropTypes.bool
};

export default CustomLinearProgress;
