import React, { useEffect, useRef, useState, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { ROUTES, AUTO_APPLY_PROMO_CODE, PRE_ORDER_PRODUCTS } from 'config/constants';

import CustomLinearProgress from 'containers/App/components/Loading/CustomLinearProgress';
import { notifType, notify } from 'utils/notifSender';
import { isObjectEmpty, isAutoApplyCouponValid } from 'utils/helpers';

import { handleFetchingCart } from 'redux/reducers/cart';
import { billingOrder } from 'api/order';
import { triggerOrderPlacingEventFromOutside, placeOrderEventThroughPopup } from 'redux/reducers/order';

import ToProceed from './ToProceed/ToProceed';
import CustomStepIcon from './CustomStepIcon/CustomStepIcon';
import CheckoutPaymentInfo from 'layouts/CartLayout/CheckoutPaymentInfo/CheckoutPaymentInfo';

import { addItemsToCart, validateCoupon } from 'api/cart';
import * as FS from '@fullstory/browser';
import { isEmpty } from 'underscore';
import { Spinner } from 'reactstrap';
import { storeUserEvent } from 'api/user';

import StickyUpDown from '../../assets/images/stickyUpDownIcon.png';

import './CartLayout.scss';
import './../../screens/FAQ/FAQScreen.scss';

const CartLayout = ({
    isStyledCard = true,
    onClick = () => { },
    children,
    type,
    buyPrice,
    cartItems,
    currentStep,
    form,
    triggerLoginSubmit,
    triggerRegistrationSubmit,
    submitCoupon,
    setOrder,
    order
}) => {
    const elementRefForShippingSection = useRef(null);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [isButtonDisabled] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    const { cardList } = useSelector((state) => ({ cardList: state.payment.cardList }));
    const { selectedCartItem } = useSelector(state => ({ selectedCartItem: state.cart.selectedCartItem, meta: state.cart.overview }));
    const [cartStickyBottomExpanded, setCartStickyBottomExpanded] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { segment } = useSelector(state => ({ segment: state.common.segment }));
    const segmentQuery = segment ? `?segment=${segment}` : '';
    const [Loading, setLoading] = useState(false);
    const [coupon, setCoupon] = useState('');
    const [orderExist, setOrderExist] = useState(false);
    const mapSteps = [{
        id: 0,
        key: ROUTES.CART,
        value: 'Review Cart'
    },
    {
        id: 1,
        key: ROUTES.SINGPASS,
        value: 'Singpass Verification'
    },
    {
        id: 2,
        key: ROUTES.ORDER_SUMMARY,
        value: 'Shipping & Payment'
    },
    {
        id: 3,
        key: ROUTES.ORDER_SUMMARY_CONFIRMATION,
        value: 'Order Summary'
    }];
    const { profile } = useSelector(state => ({ profile: state?.auth?.profile }));
    const { isCartLoading, isPaymentLoading, isShippingLoading, singPassVerified } = useSelector(state => ({
        isCartLoading: state.cart.isLoading,
        isPaymentLoading: state.payment.isLoading,
        isShippingLoading: state.user.isLoading,
        singPassVerified: state.auth?.profile?.singpass_status
    }));
    const { products, isAuthed } = useSelector(state => ({
        products: state.cart.fetchedProducts,
        singPassVerified: state.auth?.profile?.singpass_status,
        meta: state.cart.overview,
        isAuthed: state.auth.isAuthed
    }));
    const { orderPlaced } = useSelector(state => ({ orderPlaced: state.order.orderPlaced }));
    const cartLocalStorageJSON = localStorage.getItem('products');
    const cartLocalStorage = cartLocalStorageJSON ? JSON.parse(cartLocalStorageJSON) : [];
    const userCart = isAuthed ? products || cartItems : cartLocalStorage;
    const { orderPlacementPageTermsAndCondition } = useSelector(state => ({
        orderPlacementPageTermsAndCondition: state.order.orderPlacementPageTermsAndCondition,
        canProceedToOrderPlacement: state.order.canProceedToOrderPlacement
    }));
    const emptyCart = !(products?.length > 0 || cartLocalStorage?.length > 0);
    const [isLoadingExtend, setIsLoadingExtend] = useState(false);
    const { selectedMethodId } = useSelector((state) => ({
        selectedMethodId: state.payment.selectedPaymentMethodId
    }));
    const [isFreeCouponApplied, setIsFreeCouponApplied] = useState(false);
    const { orderPlaceDispatchThroughPopup } = useSelector(state => ({
        orderPlaceDispatchThroughPopup: state.order.orderPlaceDispatchThroughPopup
    }));
    const [stepperData, setStepperData] = useState([]);

    useEffect(() => {
        const pageStack = JSON.parse(localStorage.getItem('routeHistoryStack'));

        if (pageStack && pageStack.length >= 2) {
            const secondLastPageUrl = pageStack[pageStack.length - 2];

            if ((location.pathname === ROUTES.CART || location.pathname === ROUTES.ORDER_SUMMARY) && secondLastPageUrl.indexOf('cart') === -1) dispatch(handleFetchingCart());
        }

        if (buyPrice && (buyPrice === '' || buyPrice === null)) notify('This product is unavailable. Please contact customer service.', notifType.DANGER);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (singPassVerified === 'otp_required') {
            history.push(ROUTES.MYINFORMATION);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        updateStepperSteps();
    }, [singPassVerified]);

    useEffect(() => {
        if (isOpen) {
            storeUserEvent({
                event: 'terms_and_conditions_popup_opened',
                product_id: products[0]?.base_product?.id,
                cart_id: products[0]?.pivot?.id
            });

            FS.event('Terms and conditions popup opened', {
                user_id: profile.id.toString()
            });
        }
    }, [isOpen]);

    useEffect(() => {
        updateStepperSteps();
    }, []);

    const handleUrlChange = () => {
        switch (location.pathname) {
            case ROUTES.CART:
            default:
                setActiveStep(0);

                if (singPassVerified === 'rejected') {
                    if (!localStorage.getItem('notificationplacedonetime')) {
                        notify('Your singpass account was rejected. Please include income NOA', notifType.DANGER, 5000);
                        localStorage.setItem('notificationplacedonetime', true);
                    }
                }

                break;
            case ROUTES.SINGPASS:
                if (singPassVerified === 'verified') {
                    history.push(ROUTES.ORDER_SUMMARY);
                } else {
                    setActiveStep(1);
                }

                break;
            case ROUTES.DOCUMENTSPROOF:
                if (profile?.document_proof_submitted) {
                    setActiveStep(4);
                    history.push(ROUTES.ORDER_SUMMARY);
                } else {
                    setActiveStep(2);
                }

                break;
            case ROUTES.ORDER_SUMMARY:
                if (currentStep === 4) {
                    setActiveStep(4);
                } else {
                    setActiveStep(singPassVerified === 'verified' ? 1 : 2);
                }

                break;

            case ROUTES.ORDER_SUMMARY_CONFIRMATION:
                setActiveStep(singPassVerified === 'verified' ? 2 : 3);

                break;
        }
    };

    useEffect(() => {
        updateStepperSteps();
        handleUrlChange();

        const unlisten = history.listen(handleUrlChange);

        return () => unlisten();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const toggleCartLayoutStickyBottom = () => setCartStickyBottomExpanded(!cartStickyBottomExpanded);

    const childrenWithProps = Children && Children.map(children, child => child && cloneElement(child, { cartStickyBottomExpanded, form }));

    const onToggle = () => {
        setIsOpen(!isOpen);
        dispatch(placeOrderEventThroughPopup(false));
        dispatch(triggerOrderPlacingEventFromOutside(false));
    };

    const backToOrderSummary = () => {
        setIsOpen(!isOpen);
        dispatch(placeOrderEventThroughPopup(false));
        dispatch(triggerOrderPlacingEventFromOutside(false));
    };

    const placeOrderDesktopTrigger = () => {
        if (!order) {
            if (selectedMethodId === undefined || selectedMethodId === '' || selectedMethodId === null) {
                notify('Error ! Please enter the payment method!', notifType.DANGER);
                dispatch(triggerOrderPlacingEventFromOutside(true));
            } else {
                dispatch(triggerOrderPlacingEventFromOutside(true));
            }
        } else {
            setIsLoadingExtend(true);
            onClick();
            setIsOpen(false);
        }
    };

    const backToPreviousPage = () => {
        switch (location.pathname) {
            case ROUTES.CART:
            default:
                if (!isAuthed) {
                    history.push(ROUTES.SIGN_IN);
                }

                // eslint-disable-next-line no-case-declarations
                const previousProductPage = localStorage.getItem('previousProductPage');

                if (previousProductPage) {
                    history.push(previousProductPage);
                } else {
                    history.push(ROUTES.HOME + segmentQuery);
                }

                break;
            case ROUTES.SIGN_UP:
                history.push(ROUTES.CART);
                break;
            case ROUTES.DOCUMENTSPROOF:
                if (profile?.singpass_status !== 'verified') {
                    history.push(ROUTES.SINGPASS);
                } else if (profile?.singpass_status === 'verified') {
                    history.push(ROUTES.CART);
                }

                break;
            case ROUTES.ORDER_SUMMARY:
                if (profile?.singpass_status !== 'verified') {
                    history.push(ROUTES.SINGPASS);
                } else if (profile?.singpass_status === 'verified') {
                    history.push(ROUTES.CART);
                }

                break;
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const code = coupon;

        applyCoupon(code);
    };

    const applyCoupon = async (code, isAutoApplyCoupon = false) => {
        if (code === '') {
            notify('Error ! Please enter promo code', notifType.DANGER);

            return;
        }

        if (type !== 'rent') {
            if (!products.length) {
                notify('Your cart is empty. Please add at least one item in the cart.', notifType.DANGER);

                return;
            }
        }

        setLoading(true);

        const tmp = [];

        if (type !== 'rent') {
            if (isAutoApplyCoupon) {
                const preOrderedProducts = products.filter(product => PRE_ORDER_PRODUCTS.includes(product?.base_product?.slug) && product?.rental_period?.duration_time === 12);

                for (const product of preOrderedProducts) {
                    const durationTime = JSON.parse(localStorage.getItem('currentCartItemPricingObj'));

                    tmp.push(getFormattedObj(product, durationTime));
                }
            } else {
                for (const product of products) {
                    const durationTime = JSON.parse(localStorage.getItem('currentCartItemPricingObj'));

                    tmp.push(getFormattedObj(product, durationTime));
                }
            }
        } else {
            tmp.push({
                is_extend: true,
                is_purchase: false,
                product_attribute_value_id: order.product_attribute_value_id,
                quantity: order.quantity,
                rental_period_id: order.orderTypeValue,
                product_id: order.product_id,
                color_id: order.color_id
            });
        }

        try {
            await validateCoupon(code, tmp);

            notify('This coupon is verified', notifType.SUCCESS);
            setCoupon(code);

            setIsFreeCouponApplied(false);

            if (type !== 'rent') {
                // send product with coupon to calculate new price
                await calculateNewPrice(code, tmp);
            } else {
                await updateOrderData(order.id, type, order.orderTypeValue, code);
            }

            FS.event('Coupon Added', {
                code: code
            });
        } catch (error) {
            notify(error.data.message, notifType.DANGER);
            setCoupon('');

            if (type === 'rent') {
                updateOrderData(order.id, type, order.orderTypeValue, '');
            } else {
                await calculateNewPrice('', tmp);
            }
        }

        setLoading(false);
    };

    const getFormattedObj = (product, durationTime) => {
        return {
            is_purchase: false,
            product_attribute_value_id: product.pivot.product_attribute_value_id,
            quantity: product.pivot.quantity,
            rental_period_id: product.pivot.rental_period_id,
            rental_period_duration: durationTime,
            product_id: product.base_product.id,
            color_id: product.pivot.color_id
        };
    };

    const calculateNewPrice = async (coupon, products) => {
        try {
            await addItemsToCart(products, coupon);
            dispatch(handleFetchingCart());
        } catch (error) {
            //
        }
    };

    const calcNewRentalPeriodAndPrice = (newRentalId, product) => {
        const newRentalPeriod = product.rental_period.find(e => Number(e.id) === Number(newRentalId));

        return { newRentalPeriod };
    };

    const updateOrderData = async (id, type, value, code) => {
        try {
            const { data } = await billingOrder(id, value, code);

            if (type === 'rent') {
                const { newRentalPeriod } = calcNewRentalPeriodAndPrice(value, data.data.variation.product);

                setOrder({
                    ...data.data,
                    orderType: type,
                    orderTypeValue: value,
                    newTotalPrice: data.data.newTotal,
                    newCoupon: code,
                    newDiscountedTotalPrice: data.data.newDiscountedTotal,
                    newRentalPeriod
                });
            } else {
                setOrder({
                    ...data.data,
                    orderType: type,
                    newTotalPrice: order.total,
                    newDiscount: 0,
                    orderTypeValue: value
                });
            }
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        if (order && Object.keys(order).length > 0) {
            setOrderExist(true);
        } else {
            setOrderExist(false);
        }
    }, [order]);

    useEffect(() => {
        const cartItem = userCart?.find(e => e.base_product?.slug === selectedCartItem?.base_product?.slug);
        const isSelectedProductExistsInCart = userCart.find(item => item.product_attribute_value_id === selectedCartItem?.product_attribute_value_id);

        if (isAutoApplyCouponValid() &&
            PRE_ORDER_PRODUCTS.includes(selectedCartItem?.base_product?.slug) &&
            userCart?.length > 0 &&
            (location.pathname === ROUTES.CART || (location.pathname === ROUTES.ORDER_SUMMARY && type === 'rent')) &&
            !isFreeCouponApplied &&
            !cartItem?.pivot?.coupon &&
            selectedCartItem?.rental_period?.duration_time === 12 &&
            isSelectedProductExistsInCart) {
            applyCoupon(AUTO_APPLY_PROMO_CODE, true);
            setIsFreeCouponApplied(true);
        }
    }, [userCart, selectedCartItem]);

    useEffect(() => {
        if (orderPlaceDispatchThroughPopup) {
            setIsOpen(true);
            dispatch(placeOrderEventThroughPopup(true));
        }
    }, [orderPlaceDispatchThroughPopup]);

    const getButtonText = (type, isLoadingExtend) => {
        if (isLoadingExtend) return <Spinner className='spinner' />;

        if (type === 'rent') {
            return 'Update';
        } else {
            const isOrderSummary = location.pathname === ROUTES.ORDER_SUMMARY;

            return isOrderSummary ? 'Place Order' : 'Next';
        }
    };

    const updateStepperSteps = () => {
        const updatedSteps = singPassVerified === 'verified'
            ? mapSteps.filter(step => step.key !== ROUTES.SINGPASS)
            : mapSteps;

        setStepperData(updatedSteps);
    };

    return (
        <>
            <div className='CartLayout container'>
                <div className={`CartLayout__LeftPanel ${(
                    pathname === ROUTES.SINGPASS ||
                    pathname === ROUTES.SINGPASS_MANUALL_FORM ||
                    pathname === ROUTES.SINGPASS_RESULT ||
                    pathname === ROUTES.SIGNUP ||
                    currentStep === 4
                ) ? 'CartLayout__LeftPanelExtended' : ''}`}>
                    {type !== 'rent' && type !== 'buy' &&
                        <>
                            <div className='CartLayout__steps no-scrollbar'>
                                <Stepper activeStep={activeStep} alternativeLabel={true} className='CartLayout__stepper'>
                                    {stepperData.map((step, index) => (
                                        <Step key={step.key}>
                                            <StepLabel StepIconComponent={({ completed }) => (
                                                <>
                                                    <CustomStepIcon completed={completed} active={activeStep === index} icon={index + 1} />
                                                    <span className={`CartLayout__stepper__text ${activeStep === index ? 'CartLayout__stepper__bold' : ''}`}>{step.value}</span>
                                                </>
                                            )} />
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                            <hr className='CartLayout__steps__divider' />
                        </>
                    }
                    <div ref={elementRefForShippingSection} className={`CartLayout__body no-scrollbar ${(cartStickyBottomExpanded && pathname !== ROUTES.SIGNUP) ? 'CartLayout__body__low__height' : 'CartLayout__body__max__height'}`}>
                        <div className={`CartLayout__body__main-contents ${!isStyledCard ? 'CartLayout__body__main-contents--no-card-styles' : ''}`}>
                            {childrenWithProps}
                        </div>
                    </div>
                    {(((selectedCartItem !== null && selectedCartItem !== undefined && !isEmpty(selectedCartItem)) && (isMobile && !emptyCart) && (userCart?.length === 1)) || (type === 'rent' && isMobile)) && (location.pathname === ROUTES.CART || (location.pathname === ROUTES.ORDER_SUMMARY && type === 'rent')) && <>
                        <div className='promo_code_section'>
                            <form onSubmit={handleSubmit}>
                                <h6 className='promo_code_title'>Promo Codes</h6>
                                <div className='promo_code_input_container'>
                                    <input type='text' className='promo_code_input' name='coupon'
                                        value={coupon}
                                        onChange={(e) => setCoupon(e.target.value)} />
                                    <button className='promo_code_apply_button' onClick={handleSubmit} type='submit'
                                        disabled={Loading} >
                                        {Loading ? <Spinner className='spinner' /> : 'Apply'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>}
                </div>
                {(!isMobile && (
                    pathname === ROUTES.CART ||
                    pathname === ROUTES.DOCUMENTSPROOF ||
                    (pathname === ROUTES.ORDER_SUMMARY && currentStep !== 4)
                )) &&
                    <div className={`CartLayout__RightPanel ${pathname === ROUTES.ORDER_SUMMARY && currentStep !== 4 ? 'CartLayout__RightPanelExtended' : ''} ${userCart?.length === 0 && orderExist === false ? 'CartLayout__RightPanelEmpty' : ''} `}>
                        {(
                            pathname === ROUTES.CART ||
                            pathname === ROUTES.SIGNUP ||
                            pathname === ROUTES.DOCUMENTSPROOF ||
                            (pathname === ROUTES.ORDER_SUMMARY && currentStep !== 4)
                        ) && (
                            <div className='w-100'>
                                <ToProceed
                                    userCart={userCart}
                                    products={products}
                                    hideBottomCTABtns={pathname === ROUTES.ORDER_SUMMARY}
                                    submitCoupon={submitCoupon}
                                    coupon={coupon}
                                    setCoupon={setCoupon}
                                    handleSubmit={handleSubmit}
                                    order={order}
                                    orderExist={orderExist}
                                    type={type}
                                    onClick={onClick}
                                    Loading={Loading} />
                                {(pathname === ROUTES.ORDER_SUMMARY && (userCart?.length > 0 && !isObjectEmpty(selectedCartItem)) || orderExist) && (
                                    <>
                                        <div className='orderSummary'>
                                            {isButtonDisabled && <p className='agreements-error'>Please agree to the terms and conditions before proceeding.</p>}
                                            <div className='cta_buttons' style={{ paddingInline: '1rem' }}>
                                                <button onClick={backToPreviousPage}>Back</button>
                                                <button onClick={placeOrderDesktopTrigger}
                                                    className='orderSummary__button'
                                                    disabled={isButtonDisabled ||
                                                        (
                                                            orderPlacementPageTermsAndCondition === false ||
                                                            orderPlacementPageTermsAndCondition === undefined ||
                                                            (!cardList || cardList.length === 0)
                                                        ) || isLoadingExtend}>
                                                    {getButtonText(type, isLoadingExtend)}
                                                </button>
                                            </div>
                                        </div>
                                    </>)}
                            </div>
                        )}
                        {(isCartLoading || isPaymentLoading || isShippingLoading) && <CustomLinearProgress loading={isCartLoading || isPaymentLoading || isShippingLoading} />}
                    </div>}
            </div>
            {(isMobile && (
                pathname !== ROUTES.SINGPASS &&
                pathname !== ROUTES.SIGNUP &&
                pathname !== ROUTES.SINGPASS_RESULT &&
                pathname !== ROUTES.SINGPASS_MANUALL_FORM &&
                (userCart?.length > 0 || orderExist) &&
                orderPlaced === false
            )) &&
                <div className={`CartBottomStickyContainer ${cartStickyBottomExpanded ? 'CartBottomStickyContainer__expanded' : (userCart?.length === 1 || orderExist ? 'CartBottomStickyContainer__collapsed' : 'CartBottomStickyContainer__normal_on_disable')}`}>
                    {(pathname !== ROUTES.SIGNUP && (!isEmpty(selectedCartItem) || orderExist)) && <button onClick={toggleCartLayoutStickyBottom} className='CartBottomStickyContainer__updown'>
                        <img src={StickyUpDown} alt='' className={`${cartStickyBottomExpanded ? 'CartBottomStickyContainer__updown__up' : 'CartBottomStickyContainer__updown__down'}`} />
                    </button>}
                    <div className='d-flex flex-column gap-2 w-100'>
                        <ToProceed
                            userCart={userCart}
                            products={products}
                            hideBottomCTABtns={pathname === ROUTES.ORDER_SUMMARY}
                            cartStickyBottomExpanded={cartStickyBottomExpanded}
                            form={form}
                            triggerLoginSubmit={triggerLoginSubmit}
                            triggerRegistrationSubmit={triggerRegistrationSubmit}
                            submitCoupon={submitCoupon}
                            coupon={coupon}
                            setCoupon={setCoupon}
                            handleSubmit={handleSubmit}
                            order={order}
                            orderExist={orderExist}
                            type={type}
                            onClick={onClick}
                            Loading={Loading} />
                    </div>
                </div>}
            <CheckoutPaymentInfo isOpen={isOpen} onToggle={onToggle} placeOrder={() => { setIsOpen(false); onClick(); }}
                backToOrderSummary={backToOrderSummary} />
        </>
    );
};

CartLayout.propTypes = {
    cartItems: PropTypes.array,
    children: PropTypes.node.isRequired,
    isStyledCard: PropTypes.bool,
    onClick: PropTypes.func,
    error: PropTypes.any,
    order: PropTypes.array,
    setOrder: PropTypes.func,
    type: PropTypes.string,
    buyPrice: PropTypes.string,
    products: PropTypes.array,
    currentStep: PropTypes.number,
    isAuthed: PropTypes.bool,
    form: PropTypes.string,
    triggerLoginSubmit: PropTypes.func,
    triggerRegistrationSubmit: PropTypes.func,
    submitCoupon: PropTypes.func
};

export default CartLayout;
