/* eslint-disable import/no-anonymous-default-export */
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

import { register, login, logout, loginWithOTP, getOTPForLogin, getStudentVerificationCode, verifyStudentVerificationCode } from 'api/auth';
import { getAuthedProfile, updateUserProfile, storeUserEvent, getPendingInvoice } from 'api/user';
import { getSegment, setSegment } from './common';

import { ROUTES, STORAGEKEY_PASSWORD_SECRET_KEY } from 'config/constants';

import { setAuthToken, getAuthToken, deleteAuthToken, createAxiosInstance, getReferralParam, deleteReferralParam, deleteTradeInInfo, getNavigatorData, clearLocationHistoryStack } from 'utils/helpers';
import { notifType, notify } from 'utils/notifSender';
import axios from 'axios';
import { trackKlaviyoEvent } from 'api/tracking';
import CryptoJS from 'crypto-js';

const INITIAL_STATE = {
    isAppReady: false,
    isAuthenticating: false,
    isUpdatingProfile: false,
    isAuthed: false,
    isLoggingOut: false,
    openLogin: false,
    profile: {
        singpass: {}
    },
    token: '',
    showOTP: false,
    signUp: false,
    userDoLoggedin: false,
    singpassSubmissionKey: null,
    showSubmit: localStorage.getItem('showSubmit') ? (localStorage.getItem('showSubmit') === 'true') : false,
    includeNoa: localStorage.getItem('includeNoa') ? (localStorage.getItem('includeNoa') === 'true') : true,
    isInvoiceModalOpen: false,
    productId: '',
    orderNumber: '',
    invoiceUrl: ''
};

const IS_APP_READY = 'IS_APP_READY';

const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAILED_LOGIN = 'AUTH_FAILED_LOGIN';
const AUTH_FAILED_REGISTRATION = 'AUTH_FAILED_REGISTRATION';
const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
const UPDATE_PROFILE = 'UPDATE_PROFILE';
const OPEN_LOGIN = 'OPEN_LOGIN';
const SHOW_OTP = 'SHOW_OTP';
const HIDE_OTP = 'HIDE_OTP';
const OPEN_SIGNUP = 'OPEN_SIGNUP';
const CLOSE_SIGNUP = 'CLOSE_SIGNUP';
const SHOW_SUBMIT = 'SHOW_SUBMIT';
const NOA = 'NOA';
const ENABLESINGPASS = 'ENABLESINGPASS';

const UPDATE_SINGPASS_SUBMISSION_KEY = 'UPDATE_SINGPASS_SUBMISSION_KEY';
const SHOW_STUDENT_VERIFICATION_CODE = 'SHOW_STUDENT_VERIFICATION_CODE';
const HIDE_STUDENT_VERIFICATION_CODE = 'HIDE_STUDENT_VERIFICATION_CODE';

export const appIsReady = () => ({
    type: IS_APP_READY
});

const authStart = () => ({
    type: AUTH_START
});

export const authSuccess = (profile, token, userDoLoggedin, invited) => ({
    type: AUTH_SUCCESS,
    profile,
    token,
    userDoLoggedin,
    invited
});

const loginError = () => ({
    type: AUTH_FAILED_LOGIN
});

const registrationError = () => ({
    type: AUTH_FAILED_REGISTRATION
});

const authLogoutStart = () => ({
    type: AUTH_LOGOUT_START
});

const authLogoutSuccess = () => ({
    type: AUTH_LOGOUT_SUCCESS
});

const updateProfileStart = () => ({
    type: UPDATE_PROFILE_START
});

export const updateProfile = (profile) => ({
    type: UPDATE_PROFILE,
    profile
});

const showOTPForm = () => ({
    type: SHOW_OTP
});

export const loginModal = (openLogin) => ({
    type: OPEN_LOGIN,
    openLogin
});

export const handleHideOTP = () => ({
    type: HIDE_OTP
});

export const updateSingpassSubmissionKey = (submissionKey) => ({
    type: UPDATE_SINGPASS_SUBMISSION_KEY,
    payload: submissionKey
});

export const showSubmit = payload => ({
    type: SHOW_SUBMIT,
    payload
});

export const handleNOA = payload => ({
    type: NOA,
    payload
});

export const handleEnableSingpass = () => ({
    type: ENABLESINGPASS
});

export const handleShowStudentVerificationCode = () => ({
    type: SHOW_STUDENT_VERIFICATION_CODE
});

export const handleHideStudentVerificationCode = () => ({
    type: HIDE_STUDENT_VERIFICATION_CODE
});

const TOGGLE_INVOICE_MODAL = 'TOGGLE_INVOICE_MODAL';

export const toggleInvoiceModal = (isOpen, productId = '', orderNumber = '', invoiceUrl = '') => ({
    type: TOGGLE_INVOICE_MODAL,
    isOpen,
    productId,
    orderNumber,
    invoiceUrl
});

export function handleGetOTPForLogin({ phone }) {
    return async (dispatch) => {
        await dispatch(authStart());

        try {
            await getOTPForLogin({ mobile_number: phone });
            notify('OTP code sent successfully', notifType.SUCCESS);
            await dispatch(showOTPForm());
        } catch (err) {
            dispatch(loginError());
        }
    };
}

export function handleLoginWithOTP({ otp }) {
    return async (dispatch) => {
        dispatch(authStart());

        try {
            const token = await loginWithOTP({ login_otp: otp });

            setAuthToken(token);

            const data = await getAuthedProfile();

            notify('Welcome ' + data.name + ' ' + data.surname, notifType.SUCCESS);

            dispatch(authSuccess(data.data, token, false, data.invited));
        } catch (err) {
            dispatch(loginError());
        }
    };
}

export function handleLogin({ email, password, isRemember, gaSessionId, trueIp, onSuccess, onFailure }) {
    return async (dispatch) => {
        dispatch(authStart());

        const secretKey = STORAGEKEY_PASSWORD_SECRET_KEY;
        const key = CryptoJS.enc.Utf8.parse(secretKey);
        const iv = CryptoJS.lib.WordArray.random(128 / 8);
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
            keySize: 256 / 32,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const encryptedPassword = encrypted.toString();
        const encryptedIv = CryptoJS.enc.Base64.stringify(iv);

        const tmp = {
            email,
            password: encryptedPassword,
            iv: encryptedIv,
            ga_session_id: gaSessionId,
            true_ip: trueIp,
            navigator: getNavigatorData()
        };

        if (Cookies.get('_fp')) {
            // if we have fp send it to BE
            tmp.exact_id = Cookies.get('_fp');
        }

        try {
            const resp = await login(tmp);

            if (!Cookies.get('_fp')) {
                // if user doesn't use this device for signup add fingerprint to its cookie
                Cookies.set('_fp', resp.fingerprint);
            }

            // setAuthToken(token, isRemember);
            setAuthToken(resp.access_token);

            const data = await getAuthedProfile();

            let pendingInvoice = null;

            if (data.data.activeSubscriber) {
                pendingInvoice = await getPendingInvoice();
            }

            /**
             * TODO: for now we are storing is_student in localStorage for post-signup flow
             */
            localStorage.setItem('is_student', data.data.is_student ? 'b2s' : 'b2c');

            dispatch(setSegment(data.data.is_student ? 'b2s' : 'b2c'));

            notify('Welcome!', notifType.SUCCESS);

            await dispatch(authSuccess(data.data, resp.access_token, true, data.invited));
            onSuccess(pendingInvoice ? pendingInvoice.data : {});
        } catch (err) {
            onFailure();
            dispatch(loginError());
        }
    };
}

export function handleRegistration({
    email,
    password,
    confirmPassword,
    subscriptionAcknowledge,
    gaSessionId,
    trueIp,
    needRedirect,
    onSuccess,
    onFailure
}) {
    return async (dispatch) => {
        dispatch(authStart());

        const storageReferral = getReferralParam();

        const secretKey = STORAGEKEY_PASSWORD_SECRET_KEY;
        const key = CryptoJS.enc.Utf8.parse(secretKey);
        const iv = CryptoJS.lib.WordArray.random(128 / 8);
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
            keySize: 256 / 32,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const encryptedPassword = encrypted.toString();
        const encryptedConfirm = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(confirmPassword), key, {
            keySize: 256 / 32,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const encryptedConfirmPassword = encryptedConfirm.toString();
        const encryptedIv = CryptoJS.enc.Base64.stringify(iv);

        const tmp = {
            email,
            password: encryptedPassword,
            password_confirmation: encryptedConfirmPassword,
            iv: encryptedIv,
            subscribed_to_promotion: subscriptionAcknowledge ? 1 : 0,
            ga_session_id: gaSessionId,
            true_ip: trueIp,
            referral_code: storageReferral,
            navigator: getNavigatorData()
        };

        if (Cookies.get('_fp')) {
            // if we have fp send it to BE
            tmp.exact_id = Cookies.get('_fp');
        }

        try {
            const user = await register(tmp);

            if (user?.status_code === 200) {
                const token = user.access_token;

                if (!Cookies.get('_fp')) {
                    // if user doesn't use this device before add fingerprint to its cookie
                    Cookies.set('_fp', user.fingerprint);
                }

                setAuthToken(token);

                const data = await getAuthedProfile();

                // store user events
                const urlParams = new URLSearchParams(window.location.search);
                const utmFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'irclickid'];

                const userEventParams = {
                    event: 'user_registered',
                    ...Object.fromEntries(utmFields.map(field => [field, urlParams.get(field)]).filter(([_, value]) => value)) // get utm params from url if they exist, filtering out empty values
                };

                storeUserEvent(userEventParams);

                /**
                 * TODO: for now we are storing is_student in localStorage for post-signup flow
                */
                localStorage.setItem('is_student', data.data.is_student ? 'b2s' : 'b2c');
                deleteReferralParam();

                // post to HubSpot to populate Contacts table
                const hubspotWebsiteFormUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/23260945/aaa48c80-431f-43fa-a617-9687fc097720';
                const hubspotFormFields = [
                    {
                        objectTypeId: '0-1',
                        name: 'email',
                        value: email
                    }
                ];

                const context = {
                    hutk: Cookies.get('hubspotutk'),
                    pageUri: window.location.href,
                    pageName: document.title,
                    ipAddress: trueIp
                };

                await axios.post(hubspotWebsiteFormUrl, {
                    fields: hubspotFormFields,
                    context: context
                }).then(() => { }).catch(() => { });

                const trackPayload = {
                    ...data.data
                };

                trackPayload.referral_code = storageReferral;
                trackKlaviyoEvent('user-registered', trackPayload);
                dispatch(authSuccess(data.data, token, true, data.invited));
                notify('Welcome!', notifType.SUCCESS);
                onSuccess();

                if (needRedirect) {
                    const segment = getSegment();
                    const segmentQuery = segment ? `?segment=${segment}` : '';

                    window.location.replace(ROUTES.HOME + segmentQuery);
                }
            } else {
                dispatch(registrationError());
                onFailure();
            }
        } catch (err) {
            onFailure(err);
            dispatch(registrationError());
        }
    };
}

export function handleAutoLogin() {
    return async (dispatch) => {
        try {
            const token = getAuthToken();

            if (token !== null) {
                const data = await getAuthedProfile(token);

                ReactGA.gtag('set', 'user_id', data.id);

                dispatch(authSuccess(data.data, token, true, data.invited));
            }
        } catch (err) {
            dispatch(handleLogout(true));
        } finally {
            dispatch(appIsReady());
        }
    };
}

export function handleLogout() {
    return async (dispatch) => {
        const token = getAuthToken();

        // Clear local storage items
        const storageKeys = [
            'is_student',
            'currentCartItemPricingObj',
            'currentlySelectedCartItem',
            'selectedRentalPeriod',
            'selectedColorOption',
            'orderDetails',
            'products',
            'orderedProduct',
            'notificationplacedonetime',
            'idlepopupClicked'
        ];

        storageKeys.forEach(key => localStorage.removeItem(key));

        try {
            if (token !== null) {
                await logout(token);

                if (window.fcWidget && window.fcWidget.user) {
                    window.fcWidget.user.clear().then(
                        () => { },
                        () => { }
                    );
                }

                dispatch(setSegment('b2s'));

                const segmentQuery = '?segment=b2s';

                setTimeout(() => {
                    window.location.replace(ROUTES.HOME + segmentQuery);
                }, 3500);
            }
        } catch (err) {
            console.error('Logout failed', err);
        }

        dispatch(authLogoutStart());
        deleteAuthToken();
        deleteTradeInInfo();
        clearLocationHistoryStack();
        dispatch(authLogoutSuccess());
    };
}

export function handleUpdateUserProfile(id, params) {
    return async (dispatch) => {
        dispatch(updateProfileStart());

        try {
            const { data } = await updateUserProfile(id, params);

            dispatch(updateProfile(data));
        } catch (err) {
            dispatch(updateProfile({}));
        }
    };
}

export function handleGetStudentVerificationOTP({ email, onSuccess, onFailure }) {
    return async (dispatch) => {
        dispatch(authStart());

        try {
            const res = await getStudentVerificationCode({ email: email });

            if (res.data?.status_code === 200) {
                notify('OTP code sent to your email.', notifType.SUCCESS);
                await dispatch(handleShowStudentVerificationCode());
                onSuccess();
            } else {
                onFailure();
            }

            dispatch(registrationError());
        } catch (err) {
            onFailure();
            dispatch(registrationError());
        }
    };
}

export function handleVerifyStudentVerificationCode({ code, email, onSuccess, onFailure }) {
    return async (dispatch) => {
        try {
            const payload = {
                code,
                email
            };
            const { data } = await verifyStudentVerificationCode(payload);
            const profileResp = await getAuthedProfile();
            const token = getAuthToken();

            await dispatch(authSuccess(profileResp.data, token, true, profileResp.invited));

            if (data?.status_code === 200) {
                notify('OTP code verified successfully.', notifType.SUCCESS);
                onSuccess();
            } else {
                onFailure();
            }
        } catch (err) {
            onFailure();
            dispatch(registrationError());
        }
    };
}

export const handleOpenSignUp = () => ({
    type: OPEN_SIGNUP
});

export const handleCloseSignUp = () => ({
    type: CLOSE_SIGNUP
});

// eslint-disable-next-line valid-jsdoc
/**
 * add items to cart
 * @param {Object} form
 * @param {Boolean} form.include_noa
 * @param {Function} onSuccess
 * @param {Function} onFailure
 * @returns {Promise}
 */
export const handleAuthorizeSingpass = (form, onSuccess = () => { }, onFailure = () => { }, isRefresh) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        const updatedForm = {
            ...form,
            channel: 'samsung'
        };

        try {
            const { data } = await instance.post('/authorize/singpass', updatedForm);

            if (isRefresh) {
                dispatch(showSubmit(true));
            }

            if (data && data?.redirect_url) {
                onSuccess(data?.redirect_url);
            } else {
                onFailure();
                dispatch(showSubmit(false));
            }
        } catch (e) {
            onFailure();
            dispatch(showSubmit(false));
        }
    };
};

/**
* @param {String} code
* @param {Function} onSuccess
* @param {Function} onFailure
* @returns {Promise}
*/

export const handleGetSubmissionKey = (code, onSuccess = () => { }, onFailure = () => { }) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.post('/authorize/singpass/person', { code });

            onSuccess(data);
        } catch (e) {
            onFailure();
        }
    };
};

/**
 * add items to cart
 * @param {Object} form
 * @param {String} form.submission_key
 * @param {String} form.martial_status
 * @param {String} form.residency_status
 * @param {String} form.email_address
 * @param {String} form.mobile
 * @param {Function} onSuccess
 * @param {Function} onFailure
 * @returns {Promise}
 */
export const handleSingpassSubmitData = (form, onSuccess = () => { }, onFailure = () => { }) => {
    return async () => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.post('/authorize/singpass/submit', form);

            // update user singpass status
            // dispatch(handleAutoLogin());
            // dispatch(showSubmit(false));
            if (data) {
                onSuccess(data);
            } else {
                onFailure();
            }
        } catch (e) {
            onFailure();
        }
    };
};

export const handleFetchSingpassData = (onSuccess = () => { }, onFailure = () => { }) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/authorize/singpass/index');

            if (data && data?.data) {
                dispatch(updateProfile({ singpass: data?.data }));
                onSuccess();
            } else {
                onFailure();
            }
        } catch (e) {
            onFailure();
        }
    };
};

export const handleVerifySingpassOtp = (form, onSuccess = () => { }, onFailure = () => { }) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.post('authorize/singpass/otp/submit', form);
            dispatch(handleAutoLogin());
            onSuccess();
        } catch (err) {
            const msg = err?.response?.data?.message;

            onFailure(msg || null);
        }
    };
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_LOGIN:
            return {
                ...state,
                openLogin: action.openLogin
            };

        case IS_APP_READY:
            return {
                ...state,
                isAppReady: true
            };

        case AUTH_START:
            return {
                ...state,
                isAuthenticating: true
            };

        case AUTH_FAILED_LOGIN:
        case AUTH_FAILED_REGISTRATION:
            return {
                ...state,
                isAuthenticating: false,
                token: ''
            };

        case AUTH_SUCCESS: {
            const { profile, userDoLoggedin, invited } = action;

            Sentry.configureScope((scope) => {
                scope.setUser({ id: profile.id, email: profile.email });
            });

            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                openLogin: false,
                token: action.token,
                profile,
                userDoLoggedin: !userDoLoggedin,
                invited
            };
        }

        case UPDATE_PROFILE_START:
            return {
                ...state,
                isUpdatingProfile: true
            };

        case UPDATE_PROFILE: {
            const { profile } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    ...profile
                }
            };
        }

        case AUTH_LOGOUT_START:
            return {
                ...state,
                isLoggingOut: true
            };

        case AUTH_LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE,
                isAppReady: true
            };

        case SHOW_OTP:
            return {
                ...state,
                showOTP: true,
                isAuthenticating: false
            };
        case HIDE_OTP:
            return {
                ...state,
                showOTP: false,
                isAuthenticating: false
            };

        case OPEN_SIGNUP:
            return {
                ...state,
                signUp: true
            };

        case CLOSE_SIGNUP:
            return {
                ...state,
                signUp: false
            };

        case UPDATE_SINGPASS_SUBMISSION_KEY:
            return {
                ...state,
                singpassSubmissionKey: action.payload
            };

        case SHOW_SUBMIT:
            localStorage.setItem('showSubmit', action.payload);

            return {
                ...state,
                showSubmit: action.payload
            };

        case NOA:
            localStorage.setItem('includeNoa', action.payload);

            return {
                ...state,
                includeNoa: action.payload
            };

        case ENABLESINGPASS:
            return {
                ...state,
                profile: { singpass_status: null }
            };

        case SHOW_STUDENT_VERIFICATION_CODE:
            return {
                ...state,
                showStudentVerificationCode: true,
                isAuthenticating: false
            };
        case HIDE_STUDENT_VERIFICATION_CODE:
            return {
                ...state,
                showStudentVerificationCode: false,
                isAuthenticating: false
            };

        case TOGGLE_INVOICE_MODAL:
            return {
                ...state,
                isInvoiceModalOpen: action.isOpen,
                productId: action.productId,
                orderNumber: action.orderNumber,
                invoiceUrl: action.invoiceUrl
            };

        default:
            return state;
    }
};
