import Button from 'containers/App/components/Button/Button';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import style from './ForgotForm.module.scss';
import { notifType, notify } from 'utils/notifSender';
import { storeUserEvent } from 'api/user';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import backIcon from 'assets/images/general/back-icon.png';

ForgotForm.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onFormDataItemChange: PropTypes.func,
    emailAddress: PropTypes.string,
    fromPopup: PropTypes.bool
};

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#AAA'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#AAA'
        },
        '&:hover fieldset': {
            borderColor: '#AAA'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#AAA'
        }
    }
});

function ForgotForm({
    submit,
    changeForm,
    loading,
    onFormDataItemChange,
    emailAddress,
    fromPopup
}) {
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    const [email, setEmail] = useState(emailAddress);

    const handleSubmit = async e => {
        e.preventDefault();

        if (email === '') {
            notify('Email is required.', notifType.DANGER);

            return true;
        }

        submit(email);
    };

    useEffect(() => {
        const triggerUserEvent = async () => {
            const payload = {
                event: 'forgot_password'
            };

            await storeUserEvent(payload);
        };

        triggerUserEvent();
    }, []);

    return (
        <div className={style.section}>
            <div className='auth-form-header'>
                <img
                    src={backIcon}
                    alt='Back'
                    className='auth-form-header__back'
                    onClick={changeForm} />
                <div className='auth-form-header__title'>Reset password</div>
            </div>
            <div className={style.description}>Enter the email address or mobile phone number associated with your Cinch account.</div>
            <form onSubmit={handleSubmit}>
                <div className={style.row}>
                    <div className={style.value}>
                        <CssTextField value={email} name='email' className={style.value__input}
                            autoComplete='off'
                            label={'Email*'} variant='standard'
                            onChange={e => { onFormDataItemChange('email', e.target.value); setEmail(e.target.value); }} />
                    </div>
                </div>

                {fromPopup && (
                    <div className={style.buttons}>
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Send OTP'}
                            className={style.buttons__submit}
                            disabled={email === ''} />
                    </div>
                )}
                {(!fromPopup && !isMobile) && (
                    <div className={style.buttons}>
                        <Button
                            type='button'
                            title={'Back'}
                            className={`${style.buttons__back} ${style.w_160}`}
                            onClick={changeForm} />
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Send OTP'}
                            className={`${style.buttons__submit} ${style.w_160}`}
                            disabled={email === ''} />
                    </div>
                )}
            </form>
        </div>
    );
}

export default ForgotForm;
