import React from 'react';
import { Spinner } from 'reactstrap';

import './FullscreenLoader.scss';

const FullscreenLoader = () => (
    <div className='FullscreenLoader'>
        <Spinner animation='border' role='status' />
    </div>
);

export default FullscreenLoader;
