import { lazy } from 'react';

export const HomeContainer = lazy(() => import('containers/Home/HomeContainer'));
export const PageNotFoundContainer = lazy(() => import('containers/PageNotFound/PageNotFoundContainer'));
export const ProductContainer = lazy(() => import('containers/Product/ProductContainer'));
export const OrderedProductContainer = lazy(() => import('containers/OrderedProduct/OrderedProductContainer'));
export const CartContainer = lazy(() => import('containers/Cart/CartContainer'));
export const OrderSummaryContainer = lazy(() => import('containers/OrderSummary/OrderSummaryContainer'));
export const AboutUsContainer = lazy(() => import('containers/AboutUs/AboutUsContainer'));
export const TermsAndConditionsContainer = lazy(() => import('containers/TermsAndConditions/TermsAndConditionsContainer'));
export const FAQContainer = lazy(() => import('containers/FAQ/FAQContainer'));
export const PrivacyPolicyContainer = lazy(() => import('containers/PrivacyPolicy/PrivacyPolicyContainer'));
export const HowItWorksContainer = lazy(() => import('containers/HowItWorks/HowItWorksContainer'));
export const MyAccountContainer = lazy(() => import('containers/MyAccount/MyAccountContainer'));
export const SingPassContainer = lazy(() => import('containers/SingPass/SingPassContainer'));
export const SingpassManualFormContainer = lazy(() => import('containers/SingpassManualForm/SingpassManualFormContainer'));
export const SingpassResultContainer = lazy(() => import('containers/SingpassResult/SingpassResultContainer'));
export const MyInformationContainer = lazy(() => import('containers/MyInformation/MyInformationContainer'));
export const OrdersContainer = lazy(() => import('containers/Orders/OrdersContainer'));
export const CheckoutContainer = lazy(() => import('containers/Checkout/CheckoutContainer'));
export const KnoxDisclaimerContainer = lazy(() => import('containers/KnoxDisclaimer/KnoxDisclaimerContainer'));
export const PurchaseSalesRestrictionsContainer = lazy(() => import('containers/PurchaseSalesRestrictions/PurchaseSalesRestrictionsContainer'));
export const SubscriptionsContainer = lazy(() => import('containers/Subscriptions/SubscriptionsContainer'));
export const OtpVerificationContainer = lazy(() => import('containers/OtpVerification/OtpVerificationContainer'));
export const SignInContainer = lazy(() => import('containers/SignIn/SignInContainer'));
export const SignUpContainer = lazy(() => import('containers/SignUp/SignUpContainer'));
export const ResetPasswordContainer = lazy(() => import('containers/ResetPassword/ResetPasswordContainer'));
export const ReferAndEarnContainer = lazy(() => import('containers/ReferAndEarn/ReferAndEarnContainer'));
export const BuyingOrderSummaryContainer = lazy(() => import('containers/BuyingOrderSummary/BuyingOrderSummaryContainer'));
export const PaymentInfoContainer = lazy(() => import('containers/PaymentInfo/PaymentInfoContainer'));
export const ShippingInfoContainer = lazy(() => import('containers/ShippingInfo/ShippingInfoContainer'));
export const OrderSummaryScreenConfirmationContainer = lazy(() => import('containers/OrderSummaryConfirmation/OrderSummaryConfirmationContainer'));
export const ProductsContainer = lazy(() => import('containers/Products/ProductsContainer'));
export const LuckyDrawContainer = lazy(() => import('containers/LuckyDraw/LuckyDrawContainer'));
export const TradeInCalculatorContainer = lazy(() => import('containers/TradeInCalculator/TradeInCalculatorContainer'));
export const NafaContainer = lazy(() => import('containers/Nafa/NafaContainer'));
export const WaitListContainer = lazy(() => import('containers/WaitList/WaitListContainer'));
export const ContactUsContainer = lazy(() => import('containers/ContactUs/ContactUsContainer'));
export const Q6B6Container = lazy(() => import('containers/Q6B6/Q6B6Container'));
export const InvoiceContainer = lazy(() => import('containers/Invoice/InvoiceContainer'));
export const SocialAuthHandlerContainer = lazy(() => import('containers/SocialAuthHandler/SocialAuthHandlerContainer'));
