import axios from 'axios';
import { notifType, notify } from 'utils/notifSender';
import { AUTH_API_URL, COMPASIA_BASE_URL, COMPASIA_API_KEY, COMPASIA_USERNAME } from 'config/constants';
import { getAuthToken } from 'utils/helpers';

const getProductList = async () => {
    const url = `${AUTH_API_URL}/trade-ins/products`;
    const credentials = {
        apiKey: COMPASIA_API_KEY,
        userName: COMPASIA_USERNAME
    };

    try {
        const response = await axios.get(url, credentials, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        notify('Something went wrong. Please try again.', notifType.DANGER);
        throw new Error(error);
    }
};

const imeiValidation = async (productId, imei) => {
    const url = `${COMPASIA_BASE_URL}/api/v1/partner-integration/imei-validation`;
    const data = new FormData();

    data.append('apiKey', COMPASIA_API_KEY);
    data.append('userName', COMPASIA_USERNAME);
    data.append('productId', productId);
    data.append('IMEINumber', imei);

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        logCompAsiaError({
            reqBody: { productId, imei },
            resBody: error.response.data,
            endpoint: 'IMEI Validation',
            status: error.response.status
        });
        notify('Something went wrong. Please try again.', notifType.DANGER);
        throw new Error(error);
    }
};

const productAssessmentQuestion = async (productId) => {
    const url = `${COMPASIA_BASE_URL}/api/v1/partner-integration/product-assessment`;
    const data = new FormData();

    data.append('apiKey', COMPASIA_API_KEY);
    data.append('userName', COMPASIA_USERNAME);
    data.append('productId', productId);

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data.msg.questions;
    } catch (error) {
        logCompAsiaError({
            reqBody: { productId },
            resBody: error.response.data,
            endpoint: 'Product Assessment Questions',
            status: error.response.status
        });
        notify('Something went wrong. Please try again.', notifType.DANGER);
        throw new Error(error);
    }
};

const fetchEstimatedPrice = async (productId, imeiNumber, deviceConditionString) => {
    const url = `${COMPASIA_BASE_URL}/api/v1/partner-integration/result/quoted-price`;
    const data = new FormData();

    data.append('apiKey', COMPASIA_API_KEY);
    data.append('userName', COMPASIA_USERNAME);
    data.append('productId', productId);
    data.append('IMEINumber', imeiNumber);
    data.append('deviceConditionString', deviceConditionString);

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        logCompAsiaError({
            reqBody: { productId, imeiNumber, deviceConditionString },
            resBody: error.response.data,
            endpoint: 'Quote Price',
            status: error.response.status
        });
        notify('Something went wrong. Please try again.', notifType.DANGER);
        throw new Error(error);
    }
};

const calculateTradeIn = async (tradeInData) => {
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'multipart/form-data'
        },
        url: AUTH_API_URL + '/trade-ins/calculate',
        data: tradeInData
    };

    try {
        const resp = await axios(options);

        return resp.data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);
        throw new Error(err);
    }
};

const logCompAsiaError = async ({ reqBody, resBody, endpoint, status }) => {
    const payload = {
        requestData: reqBody,
        responseData: resBody,
        api: endpoint,
        status: status
    };

    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: `${AUTH_API_URL}/trade-ins/log-error`,
        data: payload
    };

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        // console.log('Error logging CompAsia error', err);
        throw new Error(err);
    }
};

export { getProductList, imeiValidation, productAssessmentQuestion, fetchEstimatedPrice, calculateTradeIn, logCompAsiaError };
