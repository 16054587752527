import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'config/constants';

import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';

import { updateAuthenticationDataFromStateToStore } from 'redux/reducers/user';
import { storeUserEvent } from 'api/user';

import eye from 'assets/images/general/eye.svg';
import eyeOff from 'assets/images/general/eye-off.svg';
import backIcon from 'assets/images/general/back-icon.png';
import close from 'assets/images/general/close.svg';

import style from './LoginForm.module.scss';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#AAA'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#AAA'
        },
        '&:hover fieldset': {
            borderColor: '#AAA'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#AAA'
        }
    }
});

const LoginForm = ({
    submit,
    changeForm,
    loading,
    isStudent,
    email: initialEmail,
    backToMainLogin,
    fromPopup,
    onFormDataItemChange
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [type, setType] = useState(true);
    const [formData, setFormData] = useState({ email: initialEmail || '', password: '' });
    const { password } = formData;
    const isLoginDisabled = formData.email === '' || formData.password === '';
    const [hasEmailError, setHasEmailError] = useState(false);
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;

    const handleChange = (e) => {
        onFormDataItemChange(e.target.name, e);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = e => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;

        if (email === '' || password === '') {
            notify('Please fill in all required fields.', notifType.DANGER);

            return true;
        }

        submit({ email, password, isRemember: true });
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();

        console.log(formData.email);

        if (formData.email === '') {
            setHasEmailError(true);

            return true;
        }

        setHasEmailError(false);

        changeForm('forgot', formData.email);
    };

    useEffect(() => {
        const triggerUserEvent = async () => {
            const payload = {
                event: 'sign_in'
            };

            await storeUserEvent(payload);
        };

        triggerUserEvent();
    }, []);

    useEffect(() => {
        dispatch(updateAuthenticationDataFromStateToStore(formData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        if (initialEmail) {
            setFormData((prevState) => ({ ...prevState, email: initialEmail }));
        }
    }, [initialEmail]);

    const backToTheCart = () => history.push(ROUTES.SIGN_IN);

    return (
        <div className={style.section}>
            <div className='auth-form-header'>
                <img
                    src={backIcon}
                    alt='Back'
                    className='auth-form-header__back'
                    onClick={backToMainLogin} />
                <div className='auth-form-header__title'>Welcome back!</div>
            </div>
            <div className={style.description}>Please sign in to continue.</div>
            <form onSubmit={handleSubmit}>
                <div className={style.row}>
                    <div className={style.value}>
                        <CssTextField name='email' type='email' className={style.value__input}
                            autoComplete='off' label={`${isStudent === 'b2s' ? 'Student ' : ''}Email`} variant='standard'
                            onChange={handleChange} value={formData.email} required='true' />
                        {hasEmailError && <span className={style.value__error}>*Enter a valid email</span>}
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <CssTextField name='password' type={type ? 'password' : 'text'} autoComplete='off'
                                className={style.value__input + ' ' + style.withIcon__input}
                                label='Enter Password' variant='standard' onChange={handleChange}
                                required='true' />
                            <img
                                src={type ? eye : eyeOff}
                                alt='Toggle password visibility'
                                onClick={() => setType(!type)}
                                className={type ? style.withIcon__img : style.withIcon__img + ' ' + style.withIcon__imgActive} />
                        </div>
                    </div>
                </div>
                <div className={style.forgot}>
                    <span className={style.forgot__title} onClick={handleForgotPassword}>
                        Forgot Password?
                    </span>
                </div>
                {fromPopup && (
                    <div className={style.buttons}>
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Sign in'}
                            className={style.buttons__submit}
                            disabled={isLoginDisabled} />
                    </div>
                )}
                {(!fromPopup && !isMobile) && (
                    <div className={style.buttons}>
                        <Button
                            type='button'
                            title={'Back'}
                            className={`${style.buttons__back} ${style.w_160}`}
                            onClick={backToMainLogin} />
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Sign in'}
                            className={`${style.buttons__submit} ${style.w_160}`}
                            disabled={isLoginDisabled} />
                    </div>
                )}
            </form>
        </div>
    );
};

LoginForm.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isStudent: PropTypes.bool,
    email: PropTypes.string,
    backToMainLogin: PropTypes.func,
    fromPopup: PropTypes.bool,
    onFormDataItemChange: PropTypes.func
};

export default LoginForm;
