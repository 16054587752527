import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'config/constants';

import Title from '../title';

import { handleAuthorizeSingpass } from 'redux/reducers/auth';
import { storeUserEvent } from 'api/user';

import RetrieveMyInfo from 'assets/images/RetrieveMyInfo.svg';

import style from './FormHeader.module.scss';

const FormHeader = ({ title }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [, setIsLoading] = useState(false);

    const handleClick = () => history.push(ROUTES.SINGPASS);

    const onAuthorizedSingpass = redirectUrl => {
        window.location.href = redirectUrl;
    };

    const onRejectSingpassAuthorization = () => setIsLoading(false);

    const onRetrieveInfo = () => {
        localStorage.removeItem('notificationplacedonetime');
        const payload = {
            event: 'singpass_attempt'
        };

        storeUserEvent(payload);

        dispatch(handleAuthorizeSingpass({ include_noa: true }, onAuthorizedSingpass, () => onRejectSingpassAuthorization(true)));
    };

    return (
        <div className={style.header}>
            <div className={style.header__flex + ' ' + style.header__flexOne}>
                {!title
                    ? <div className={style.header__title}>SingPass manual form <span>*manual data review may take up to 30 days</span></div>
                    : <div className={style.header__label}><Title /></div>
                }
            </div>
            <div className={style.header__flex + ' ' + style.header__pointer} onClick={handleClick}>
                <div className={style.header__text}>
                    <span className={style.header__SingPassVerification__text}>For instant review:</span>
                    <button className={style.header__SingPassVerification__button} onClick={onRetrieveInfo}>
                        <img src={RetrieveMyInfo} alt='Retrieve My Info on SingPass' />
                    </button>
                </div>
            </div>
        </div>
    );
};

FormHeader.propTypes = {
    title: PropTypes.bool
};

export default FormHeader;
