import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

import FormFooter from '../FormFooter';
import FormSelcetion from '../FormSelection';
import Uploader from '../Uploader';
import FormMessage from '../FormMessage';
import FormInput from '../FormInput';

import style from './Form.module.scss';

import { employmentSector, incomeNoa, occupation } from 'screens/SingpassManualForm/options';

Fifth.propTypes = {
    errors: PropTypes.object.isRequired,
    setErrors: PropTypes.func.isRequired,
    fifthForm: PropTypes.object.isRequired,
    setFifthForm: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    handlePrevStep: PropTypes.func.isRequired,
    errorMsgs: PropTypes.object,
    loading: PropTypes.bool,
    openModal: PropTypes.bool,
    handleUploadedFile: PropTypes.func,
    handleErrorMsg: PropTypes.func
};

function Fifth({
    errors,
    setErrors,
    fifthForm,
    setFifthForm,
    handleNextStep,
    handlePrevStep,
    errorMsgs,
    loading,
    openModal,
    handleUploadedFile,
    handleErrorMsg
}) {
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setFifthForm(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: false }));
    };

    return (
        <div className={style.body}>
            <div>
                <div className={style.col}>
                    <FormSelcetion
                        name='employment_sector'
                        error={errors.employment_sector}
                        errorMsg={errorMsgs.employment_sector}
                        value={fifthForm.employment_sector ? ({ label: fifthForm.employment_sector, value: fifthForm.employment_sector }) : null}
                        onChange={handleChange}
                        options={employmentSector}
                        label='What is your employment sector?'/>
                </div>
                <div className={style.col}>
                    <FormSelcetion
                        isSearchable={true}
                        name='occupation'
                        error={errors.occupation}
                        errorMsg={errorMsgs.occupation}
                        value={fifthForm.occupation ? ({ label: fifthForm.occupation, value: fifthForm.occupation }) : null}
                        onChange={handleChange}
                        options={occupation}
                        label='What is your occupation? '/>
                </div>
                <div className={style.col}>
                    <FormInput
                        type='text'
                        name={'employer_name'}
                        error={errors.employer_name}
                        errorMsg={errorMsgs.employer_name}
                        value={fifthForm.employer_name}
                        onChange={handleChange}
                        label='What is the name of your employer?' />
                </div>
                <div className={style.col}>
                    <FormInput
                        type='number'
                        name='income_noa'
                        error={errors.income_noa}
                        errorMsg={errorMsgs.income_noa}
                        value={fifthForm.income_noa}
                        onChange={handleChange}
                        options={incomeNoa}
                        label='What is your annual basic income?'/>

                </div>
                <div className={style.col}>
                    <FormInput
                        maxLength={4}
                        placeHolder='YYYY'
                        type='number'
                        name={'assessed_year_income'}
                        error={errors.assessed_year_income}
                        errorMsg={errorMsgs.assessed_year_income}
                        value={fifthForm.assessed_year_income}
                        onChange={handleChange}
                        label='What is your assessed year of assessable income?' />
                </div>
                <div className={style.col}>
                    <Uploader
                        text='*Only PDF files are accepted'
                        handleErrorMsg={handleErrorMsg}
                        accept='application/pdf,application/vnd.ms-excel'
                        maxSizeBytes={'3e+6'}
                        multiple={true}
                        maxFiles={3}
                        label='Please upload your latest 3 months salary crediting bank statement'
                        name='last_3_slips'
                        values={fifthForm.last_3_slips}
                        error={errors.last_3_slips}
                        errorMsg={errorMsgs.last_3_slips}
                        handleUploadedFile={handleUploadedFile}/>
                </div>
                <div className={style.col}>
                    <FormFooter
                        loading={loading}
                        prevLabel='Previous'
                        nextLabel='Next'
                        prevClick={handlePrevStep}
                        nextClick={handleNextStep}/>
                </div>
            </div>

            <Modal
                className={style.modal}
                centered={true}
                isOpen={openModal}>
                <FormMessage />
            </Modal>
        </div>
    );
}

export default Fifth;
