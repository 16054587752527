import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import styles from './TradeInExplanation.module.scss';
import InfoIcon from 'assets/images/Info Icon.png';
import ParallelArrows from 'assets/images/general/parallel-arrows.svg';
import CreditCardIcon from 'assets/images/Credit Card Icon.png';

const TradeInExplanationModal = ({ cinchCreditsOnly, customButton }) => {
    const howItWorks = [
        'Fill out a form with your device’s information and condition.',
        'Receive an estimated trade-in value, revised subscription price and Cinch Credit value.',
        'Pay the first month’s full rate to confirm your order.',
        'Our trade-in partner will schedule a pickup for your old device.',
        'After inspection, we’ll adjust your subscription rate and issue you Cinch Credit if applicable.'
    ];
    const cinchCredits = [
        {
            subTitle: 'What Are Cinch Credits',
            content: 'Use Cinch Credits towards subscription payments, new subscriptions, repair fees, and device purchases within the Cinch platform.'
        }, {
            subTitle: 'Earn Through Trade-Ins',
            content: 'Get Cinch Credits when your trade-in value exceeds the amount that can be applied to reduce your monthly subscription below SGD20.'
        }, {
            subTitle: 'Non-Transferable & Non-Cashable',
            content: 'Cinch Credits cannot be transferred to other accounts or converted to cash. They are for use within the Cinch ecosystem only.'
        }, {
            subTitle: 'Check Balance & Expiry',
            content: 'Easily check your Cinch Credits balance in your account dashboard and remember, they expire 24 months from the date of issuance.'
        }
    ];
    const limitations = [
        'If you don’t schedule a trade-in or change your mind, your subscription will stay at the original rate.',
        'Misdeclared conditions will lead to a revised valuation, which you can accept or reject at the pickup.',
        'Minimum subscription value is $20/month.'
    ];

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    let customButtonWithToggle = customButton;

    if (customButton && !customButton.props.onClick) {
        customButtonWithToggle = React.cloneElement(customButton, {
            onClick: toggle
        });
    }

    return (
        <>
            {customButtonWithToggle ?? (
                <Button className={styles.infoButton} onClick={toggle}>
                    <img src={InfoIcon} alt='Info' />
                </Button>
            )}
            <Modal isOpen={modal} toggle={toggle} >
                <ModalBody className={styles.modalBody}>
                    <Button close={true} onClick={toggle} className={styles.closeButton} />
                    <>
                        {cinchCreditsOnly ? (
                            <>
                                <div className={styles.modalHeader}>
                                    <img src={CreditCardIcon} alt='Cinch Credit' className={styles.tradeInIcon} />
                                    <h3>Cinch Credit</h3>
                                </div>
                                {cinchCredits.map((item, index) => (
                                    <div key={index} className={styles.cinchCreditsOnly}>
                                        <strong>{item.subTitle}</strong>
                                        <ul><li>{item.content}</li></ul>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className={styles.modalHeader}>
                                    <img src={ParallelArrows} alt='Trade in' className={styles.tradeInIcon} />
                                    <h3>Trade In</h3>
                                </div>

                                <p>Save more by trading in your old device.</p>

                                <strong>How it works:</strong>
                                <ul>
                                    {howItWorks.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>

                                <strong>Cinch Credit:</strong>
                                {cinchCredits.map((item, index) => (
                                    <div key={index}>
                                        <p className={styles.subtitle}>{item.subTitle}</p>
                                        <ul><li>{item.content}</li></ul>
                                    </div>
                                ))}

                                <strong>Limitations:</strong>
                                <ul>
                                    {limitations.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </>
                </ModalBody>
                <ModalFooter>
                    <Button color='dark' onClick={toggle} block={true}
                        className={styles.buttonDismissButton}>
                        Okay
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

TradeInExplanationModal.propTypes = {
    cinchCreditsOnly: PropTypes.bool,
    customButton: PropTypes.object
};

export default TradeInExplanationModal;
