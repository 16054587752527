import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Radio } from 'antd';

import { handleAuthorizeSingpass, handleNOA } from 'redux/reducers/auth';
import { storeUserEvent } from 'api/user';
import { trackKlaviyoEvent } from 'api/tracking';

import SingPassLogo from 'assets/images/general/singpass-logo.png';
import RetrieveMyInfo from 'assets/images/RetrieveMyInfo.svg';

import './SingPassVerification.scss';

const SingPassVerification = ({ onToggleLoading, onAuthorizedSingpass, onRejectSingpassAuthorization, isMobile }) => {
    const dispatch = useDispatch();
    const [includeNotice, setIncludeNotice] = useState(true);

    const onToggleIncludeNotice = () => {
        setIncludeNotice(!includeNotice);
        dispatch(handleNOA(!includeNotice));
    };

    const onRetrieveInfo = () => {
        localStorage.removeItem('notificationplacedonetime');
        onToggleLoading();

        const payload = {
            event: 'singpass_attempt'
        };

        storeUserEvent(payload);

        trackKlaviyoEvent('singpass-attempt', {});

        dispatch(handleAuthorizeSingpass({ include_noa: includeNotice }, onAuthorizedSingpass, () => onRejectSingpassAuthorization(includeNotice)));
    };

    return (
        <div className='SingPassVerification'>
            <div className='SingPassVerification__logo'>
                <img src={SingPassLogo} alt='SingPass Logo' />
            </div>
            <div className='SingPassVerification__body'>
                <h5>My Information</h5>
                <p>Singpass enables you to retrieve your personal data from participating Government agencies. With your consent, your identity information form can be auto-filled. This makes your application more convenient with no need for supporting documents. Cinch uses Singpass to verify your identity and to make sure you are eligible for our rental service. Your personal information is completely secure and will not be shared with any other parties. Cinch only uses encrypted connections that are approved by Singpass.</p>
                <p>To be eligible, you must be over 18 years old and meet our proprietary credit check criteria.</p>
                <p>For faster approval of process, you can include Notice of Assessment while retrieving your info. Please note if Notice of Assessment is not provided you may not have access to all of Cinch&apos;s features.</p>
            </div>

            <div className='SingPassVerification__notice'>
                <button className='SingPassVerification__notice-assessment-toggle' onClick={onToggleIncludeNotice}>
                    {<Radio checked={includeNotice} />}
                    <span>Include Notice of Assessment.</span>
                </button>
                <p>Unchecking this box may lead to a slower approval process.</p>
            </div>

            {(!isMobile) &&
                <div className='SingPassVerification__buttons-container'>
                    <button className='SingPassVerification__button' onClick={onRetrieveInfo}>
                        <img src={RetrieveMyInfo} alt='Retrieve My Info on SingPass' />
                    </button>
                </div>}
        </div>
    );
};

SingPassVerification.propTypes = {
    onToggleLoading: PropTypes.func.isRequired,
    onAuthorizedSingpass: PropTypes.func.isRequired,
    onRejectSingpassAuthorization: PropTypes.func.isRequired,
    isMobile: PropTypes.bool
};

export default SingPassVerification;
