import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import error from 'assets/images/danger.png';
import warning from 'assets/images/warning.png';
import success from 'assets/images/success.png';
import info from 'assets/images/info.png';
import close from 'assets/images/toast_close.png';

import 'animate.css';

export const notifType = {
    SUCCESS: 0,
    DANGER: 1,
    WARN: 2
};

const Notification = ({ message, type, duration }) => {
    const [progress, setProgress] = useState(100);
    const updateInterval = 100;
    let content;

    useEffect(() => {
        let intervalCount = 0;

        const interval = setInterval(() => {
            const remainingTime = duration - intervalCount * updateInterval;
            const calculatedProgress = (remainingTime / duration) * 100;

            setProgress(calculatedProgress);

            if (remainingTime <= 0) {
                clearInterval(interval);
            }

            intervalCount++;
        }, updateInterval);

        return () => clearInterval(interval);
    }, [duration]);

    const closeNotification = () => {
        setProgress(0);
        content = '';
    };

    switch (type) {
        case notifType.SUCCESS:
            content = (
                <div className='d-flex flex-col w-100 notification-container notification-container__success'>
                    <div className='notification__message notification__message__success'>
                        <img className='notification__info__icon' src={success} alt='Success icon' /> {message}
                        <img className='notification__close__icon' src={close} alt='close' />
                    </div>
                    <div className='notification__progress__container'>
                        <div className={`notification__progress__success__initial ${progress < 2 ? 'notification__progress__success' : ''}`} style={{ width: `${progress}%` }}/>
                    </div>
                </div>
            );
            break;
        case notifType.DANGER:
            content = (
                <div className='d-flex flex-col w-100 notification-container notification-container__danger'>
                    <div className='notification__message notification__message__danger'>
                        <img className='notification__info__icon' src={error} alt='Error icon' /> {message}
                        <img className='notification__close__icon' src={close} alt='close'
                            onClick={closeNotification} />
                    </div>
                    <div className={`notification__progress__danger__initial ${progress < 2 ? 'notification__progress__danger' : ''} `} style={{ width: `${progress}%` }}/>
                </div>
            );
            break;
        case notifType.WARN:
            content = (
                <div className='d-flex flex-col w-100 notification-container notification-container__warning'>
                    <div className='notification__message notification__message__warning'>
                        <img className='notification__info__icon' src={warning} alt='Warning icon' /> {message}
                        <img className='notification__close__icon' src={close} alt='close'
                            onClick={closeNotification} />
                    </div>
                    <div className={`notification__progress__warning__initial ${progress < 2 ? 'notification__progress__warning' : ''} `} style={{ width: `${progress}%` }}/>
                </div>
            );
            break;
        default:
            content = (
                <div className='d-flex flex-col w-100 notification-container notification-container__info'>
                    <div className='notification__message notification__message__info'>
                        <img className='notification__info__icon' src={info} alt='Info icon' /> {message}
                        <img className='notification__close__icon' src={close} alt='close'
                            onClick={closeNotification} />
                    </div>
                    <div className={`notification__progress__info__initial ${progress < 2 ? 'notification__progress__info' : ''}`} style={{ width: `${progress}%` }}/>
                </div>
            );
            break;
    }

    return <div>{content}</div>;
};

Notification.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired
};

export default Notification;
