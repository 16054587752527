import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'config/constants';

import Button from 'containers/App/components/Button/Button';

import logo from 'assets/images/general/logo.svg';

import style from './FormMessage.module.scss';

FormMessage.propTypes = {
    singpass: PropTypes.bool,
    handleEnableSingpass: PropTypes.func
};

function FormMessage({ singpass, handleEnableSingpass }) {
    const history = useHistory();
    const { segment } = useSelector(state => ({ segment: state.common.segment }));
    const segmentQuery = segment ? `?segment=${segment}` : '';

    return (
        <div className={style.message}>
            <div className={style.message__logo}>
                <img src={logo} alt='Cinch Logo' />
            </div>
            <div className={style.message__text}>
                <div className={style.message__lines}>
                We are sorry, you are not eligible for our services at the moment.
                </div>
                {/* <div className={style.message__lines}>
                If you feel that this is a mistake or to better understand our policies
                </div>
                <div className={style.message__lines}>
                    please feel free to contact us at support@cinch.sg or +65 8168-8604.
                </div> */}
            </div>
            <div className={style.message__row}>
                <div className={style.message__col}>
                    <Button
                        className='submit'
                        onClick={() => history.push(ROUTES.HOME + segmentQuery)}
                        title='Go to Homepage'/>
                </div>
                <div className={style.message__col}>
                    {!singpass
                        ? <Button
                            className='ignore'
                            onClick={() => history.push(ROUTES.ABOUT_US)}
                            title='Contact Us'/>
                        : <Button
                            className='ignore'
                            onClick={handleEnableSingpass}
                            title='Re-Submit Singpass'/>
                    }
                </div>
            </div>
        </div>
    );
}

export default FormMessage;
