import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'underscore';
import { Spinner, Table } from 'reactstrap';

import { ROUTES, APP_CURRENCY_NAME, STORAGEKEY_TRADE_IN_DEVICE, AUTO_APPLY_PROMO_CODE, PRE_ORDER_PRODUCTS } from 'config/constants';

import { notifType, notify } from 'utils/notifSender';
import CheckoutPaymentInfo from 'layouts/CartLayout/CheckoutPaymentInfo/CheckoutPaymentInfo';
import { getStorageAttribute, isAutoApplyCouponValid } from 'utils/helpers';
import AfterTradeIn from 'components/AfterTradeIn/AfterTradeIn';

import { checkoutRules } from 'api/cart';
import { getAuthedProfile } from 'api/user';
import { trackKlaviyoEvent } from 'api/tracking';

import {
    handleSupportDocsUpload,
    updatedOnSupportUploadedEvent,
    handleGuarantorInformationUpdate,
    guarantorInformationFromStateToStore,
    updateSupportingDocumentsDatFromStateToStore
} from 'redux/reducers/user';
import { handleRemoveCartItem } from 'redux/reducers/cart';
import { triggerOrderPlacingEventFromOutside, orderTotal, triggerOrderPlacingFinalEvent } from 'redux/reducers/order';

import styles from './ToProceed.module.scss';

const ToProceed = ({ products, userCart, hideBottomCTABtns, cartStickyBottomExpanded, submitCoupon, coupon, setCoupon, handleSubmit, order, orderExist, type, Loading, onClick = () => { } }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    const cartLocalStorageJSON = localStorage.getItem('products');
    const { cardList } = useSelector((state) => ({ cardList: state.payment.cardList }));
    const cartLocalStorage = cartLocalStorageJSON ? JSON.parse(cartLocalStorageJSON) : [];
    const [triggerPlaceOrderEvent, setTriggerPlaceOrderEvent] = useState(false);
    const [triggeringThePlaceOrderButtonEvent, setTriggeringThePlaceOrderButtonEvent] = useState(false);
    const [currentCartItemPricingObjLocalStorage, setCurrentCartItemPricingObjLocalStorage] = useState({});
    const emptyCart = !(products?.length > 0 || cartLocalStorage?.length > 0);
    const { isAuthed } = useSelector(state => ({ isAuthed: state.auth.isAuthed }));
    const { isVerified } = useSelector(state => ({ isVerified: state.auth.profile.singpass_status === 'verified' }));
    const { supportingDocs, supportingDocsUploaded } = useSelector(state => ({
        supportingDocs: state.user.supportingDocs,
        supportingDocsUploaded: state.user.supportingDocsUploaded
    }));
    const { orderPlaced } = useSelector(state => ({ orderPlaced: state.order.orderPlaced }));
    const { guarantor } = useSelector(state => ({ guarantor: state.user.guarantor }));
    const { profile } = useSelector(state => ({ profile: state?.auth?.profile }));
    const { orderPlacementPageTermsAndCondition } = useSelector(state => ({
        orderPlacementPageTermsAndCondition: state.order.orderPlacementPageTermsAndCondition,
        canProceedToOrderPlacement: state.order.canProceedToOrderPlacement
    }));
    const { selectedCartItem, currentCartItemPricingObj, cartProducts, meta } = useSelector(state => ({
        selectedCartItem: state.cart.selectedCartItem,
        currentCartItemPricingObj: state.cart.currentCartItemPricingObj,
        cartProducts: state.cart.fetchedProducts,
        meta: state.cart.overview
    }));
    const { segment } = useSelector(state => ({ segment: state.common.segment }));
    const segmentQuery = segment ? `?segment=${segment}` : '';
    const [couponAmount, setCouponAmount] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [isExtraMonthPromo, setIsExtraMonthPromo] = useState(false);
    const [productName, setProductName] = useState(null);
    const [productColor, setProductColor] = useState(null);
    const [productStorage, setProductStorage] = useState(null);
    const { selectedMethodId } = useSelector((state) => ({ selectedMethodId: state.payment.selectedPaymentMethodId }));
    const tradeInDevice = JSON.parse(localStorage.getItem(STORAGEKEY_TRADE_IN_DEVICE));
    const [retailPrice, setRetailPrice] = useState((selectedCartItem?.base_product?.retail_value || selectedCartItem?.retail_value)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));

    const onFormSubmitSuccess = () => {
        setIsLoading(false);
        dispatch(updateSupportingDocumentsDatFromStateToStore('studentIDCard', null));
        dispatch(updateSupportingDocumentsDatFromStateToStore('studentVisa', null));
        dispatch(updateSupportingDocumentsDatFromStateToStore('studentIDExpiryDate', null));
        dispatch(updateSupportingDocumentsDatFromStateToStore('studentVisaExpiryDate', null));

        if (profile?.guarantor_required) {
            dispatch(updatedOnSupportUploadedEvent(true));
        } else {
            history.push(ROUTES.ORDER_SUMMARY);
        }

        notify('Documents uploaded successfully.', notifType.SUCCESS);
    };

    const onGurantorFormSubmitSuccess = () => {
        setIsLoading(false);
        dispatch(guarantorInformationFromStateToStore('guarantorName', ''));
        dispatch(guarantorInformationFromStateToStore('guarantorRelation', ''));
        dispatch(guarantorInformationFromStateToStore('guarantorEmail', ''));
        dispatch(guarantorInformationFromStateToStore('guarantorMobile', ''));

        notify('Guarantor information updated successfully.', notifType.SUCCESS);
        history.push(ROUTES.ORDER_SUMMARY);
    };

    const onFormSubmitFailure = () => {
        setIsLoading(false);

        if (profile?.is_student) {
            if (
                (supportingDocs?.studentIDCard === null || supportingDocs?.studentIDCard === undefined) ||
                (supportingDocs?.studentIDExpiryDate === null || supportingDocs?.studentIDExpiryDate === undefined)
            ) {
                notify('Upload your ID card and fill in the expiry date.', notifType.DANGER);
            }
        }

        if (profile?.pass_proof_of_expiry_required) {
            if (
                (supportingDocs?.studentVisa === null || supportingDocs?.studentVisa === undefined) ||
                (supportingDocs?.studentVisaExpiryDate === null || supportingDocs?.studentVisaExpiryDate === undefined)
            ) {
                notify('Upload a copy of your Visa and fill in the expiry date.', notifType.DANGER);
            }
        }
    };

    useEffect(() => {
        if (currentCartItemPricingObj === undefined || isEmpty(currentCartItemPricingObj)) {
            setCurrentCartItemPricingObjLocalStorage(JSON.parse(localStorage.getItem('currentCartItemPricingObj')));
        } else {
            setCurrentCartItemPricingObjLocalStorage(currentCartItemPricingObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCartItemPricingObj]);

    useEffect(() => {
        if (triggerPlaceOrderEvent === true && triggeringThePlaceOrderButtonEvent === true) {
            dispatch(triggerOrderPlacingEventFromOutside(true));
            setIsOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerPlaceOrderEvent]);

    const handleProceedToOrder = async (isStudent) => {
        if (!isAuthed) {
            history.push(ROUTES.SIGN_IN, { isStudent: isStudent });
        } else if (isAuthed && profile?.is_student && !profile?.emailVerified) {
            history.push(ROUTES.OTP_VERIFICATION);
        } else if (!isVerified) {
            setIsLoading(true);
            await getRemovedItemsFromCart();
            history.push(ROUTES.SINGPASS, { isStudent: isStudent });
            setIsLoading(false);
        } else {
            setIsLoading(true);
            const profileData = await getAuthedProfile();

            if (location.pathname === ROUTES.DOCUMENTSPROOF && !profile?.document_proof_submitted) {
                const formData = new FormData();

                if (!supportingDocsUploaded) {
                    if (profile?.is_student && !profile?.pass_proof_of_expiry_required) {
                        if (supportingDocs?.studentIDCard !== null && supportingDocs?.studentIDCard !== undefined && supportingDocs?.studentIDExpiryDate !== null && supportingDocs?.studentIDExpiryDate !== undefined) {
                            formData.append('student_id_image_url', supportingDocs?.studentIDCard);
                            formData.append('student_id_expiry_date', supportingDocs?.studentIDExpiryDate);
                            dispatch(handleSupportDocsUpload(formData, onFormSubmitSuccess, onFormSubmitFailure));
                        } else {
                            if (supportingDocs?.studentIDCard === null || supportingDocs?.studentIDCard === '' || supportingDocs?.studentIDCard === undefined) {
                                notify('Upload your student ID card.', notifType.DANGER, 5000);
                            }

                            if (supportingDocs?.studentIDExpiryDate === null || supportingDocs?.studentIDExpiryDate === '' || supportingDocs?.studentIDExpiryDate === undefined) {
                                notify('Update your student ID expiry date.', notifType.DANGER, 5000);
                            }
                        }
                    } else if (!profile?.is_student && profile?.pass_proof_of_expiry_required) {
                        if (supportingDocs?.studentVisa !== null && supportingDocs?.studentVisa !== undefined && supportingDocs?.studentVisaExpiryDate !== null && supportingDocs?.studentVisaExpiryDate !== undefined) {
                            formData.append('visa_image_url', supportingDocs?.studentVisa);
                            formData.append('visa_expiry_date', supportingDocs?.studentVisaExpiryDate);
                            dispatch(handleSupportDocsUpload(formData, onFormSubmitSuccess, onFormSubmitFailure));
                        } else {
                            if (supportingDocs?.studentVisa === null || supportingDocs?.studentVisa === '' || supportingDocs?.studentVisa === undefined) {
                                notify('Upload your student Visa.', notifType.DANGER, 5000);
                            }

                            if (supportingDocs?.studentVisaExpiryDate === null || supportingDocs?.studentVisaExpiryDate === '' || supportingDocs?.studentVisaExpiryDate === undefined) {
                                notify('Update the expiry date of the Student Visa.', notifType.DANGER, 5000);
                            }
                        }
                    } else if (profile?.pass_proof_of_expiry_required && profile?.is_student) {
                        if (
                            supportingDocs?.studentVisa !== null &&
                            supportingDocs?.studentVisa !== undefined &&
                            supportingDocs?.studentVisaExpiryDate !== null &&
                            supportingDocs?.studentVisaExpiryDate !== undefined
                        ) {
                            formData.append('visa_image_url', supportingDocs?.studentVisa);
                            formData.append('visa_expiry_date', supportingDocs?.studentVisaExpiryDate);

                            dispatch(handleSupportDocsUpload(formData, onFormSubmitSuccess, onFormSubmitFailure));
                        } else {
                            if (supportingDocs?.studentVisa === null || supportingDocs?.studentVisa === '' || supportingDocs?.studentVisa === undefined) {
                                notify('Upload your student Visa.', notifType.DANGER, 5000);
                            }

                            if (supportingDocs?.studentVisaExpiryDate === null || supportingDocs?.studentVisaExpiryDate === '' || supportingDocs?.studentVisaExpiryDate === undefined) {
                                notify('Update the expiry date of the Student Visa.', notifType.DANGER, 5000);
                            }
                        }
                    }
                } else {
                    if (
                        guarantor?.guarantorName === null ||
                        guarantor?.guarantorName === '' ||
                        guarantor?.guarantorRelation === null ||
                        guarantor?.guarantorRelation === '' ||
                        guarantor?.guarantorEmail === null ||
                        guarantor?.guarantorEmail === '' ||
                        guarantor?.guarantor_mobile === null ||
                        guarantor?.guarantor_mobile === ''
                    ) {
                        notify('Please fill in all required fields.', notifType.DANGER, 5000);
                    } else {
                        formData.append('guarantor_name', guarantor?.guarantorName);
                        formData.append('guarantor_relation', guarantor?.guarantorRelation);
                        formData.append('guarantor_email', guarantor?.guarantorEmail);
                        formData.append('guarantor_mobile', guarantor?.guarantor_mobile);

                        dispatch(handleGuarantorInformationUpdate(formData, onGurantorFormSubmitSuccess, onFormSubmitFailure));
                    }
                }

                setIsLoading(false);
            } else if (location.pathname === ROUTES.CART) {
                setIsLoading(true);

                if (!isEmpty(selectedCartItem)) {
                    const itemsToRemove = getUnwantedCartItems();
                    const removedCartItems = await getRemovedItemsFromCart();

                    if (itemsToRemove.length === 0 || removedCartItems.length === itemsToRemove.length) {
                        const resp = await checkoutRules();

                        setIsLoading(false);

                        if (resp?.status === 204) {
                            trackKlaviyoEvent('start-checkout', {
                                cartMeta: meta, cartItems: cartProducts
                            });

                            history.push(ROUTES.ORDER_SUMMARY);
                        } else {
                            notify(resp?.data?.message?.message, notifType.DANGER, 5000);
                        }
                    } else {
                        setIsLoading(false);
                        notify('Error! Something went wrong, please try again!', notifType.DANGER, 5000);
                    }
                }
            } else if (location.pathname === ROUTES.ORDER_SUMMARY) {
                if (profileData?.data?.is_student) {
                    if (profileData?.data?.document_proof_submitted || !profileData?.data?.pass_proof_of_expiry_required) {
                        if (selectedMethodId === undefined || selectedMethodId === '' || selectedMethodId === null) {
                            notify('Error ! Please enter the payment method!', notifType.DANGER);
                        } else {
                            setIsOpen(true);
                        }
                    } else {
                        history.push(ROUTES.ORDER_SUMMARY);
                    }
                } else {
                    if (profileData?.data?.pass_proof_of_expiry_required) {
                        if (profileData?.data?.document_proof_submitted) {
                            if (selectedMethodId === undefined || selectedMethodId === '' || selectedMethodId === null) {
                                notify('Error ! Please enter the payment method!', notifType.DANGER);
                            } else {
                                setIsOpen(true);
                            }
                        } else {
                            history.push(ROUTES.ORDER_SUMMARY);
                        }
                    } else {
                        if (type !== 'rent' && type !== 'purchase') {
                            if (selectedMethodId === undefined || selectedMethodId === '' || selectedMethodId === null) {
                                notify('Error ! Please enter the payment method!', notifType.DANGER);
                                dispatch(triggerOrderPlacingEventFromOutside(true));
                            } else {
                                dispatch(triggerOrderPlacingEventFromOutside(true));
                            }
                        } else {
                            onClick();
                        }
                    }
                }

                setIsLoading(false);
            } else {
                validateCheckout(isStudent);
            }
        }
    };

    useEffect(() => {
        if (triggeringThePlaceOrderButtonEvent) {
            setTriggerPlaceOrderEvent(true);
            setIsLoading(false);
        } else {
            setTriggeringThePlaceOrderButtonEvent(false);
        }
    }, [triggeringThePlaceOrderButtonEvent]);

    const handleThePreviousRouteBeforeTheOrder = async () => {
        switch (location.pathname) {
            case ROUTES.CART:
            default:
                if (!isAuthed) {
                    history.push(ROUTES.SIGN_IN);
                }

                // eslint-disable-next-line no-case-declarations
                const previousProductPage = localStorage.getItem('previousProductPage');

                if (previousProductPage) {
                    history.push(previousProductPage);
                } else {
                    history.push(ROUTES.HOME + segmentQuery);
                }

                break;
            case ROUTES.SIGN_UP:
                history.push(ROUTES.CART);
                break;
            case ROUTES.DOCUMENTSPROOF:
                if (profile?.singpass_status !== 'verified') {
                    history.push(ROUTES.SINGPASS);
                } else if (profile?.singpass_status === 'verified') {
                    history.push(ROUTES.CART);
                }

                break;
            case ROUTES.ORDER_SUMMARY:
                if (profile?.singpass_status !== 'verified') {
                    history.push(ROUTES.SINGPASS);
                } else if (profile?.singpass_status === 'verified') {
                    history.push(ROUTES.CART);
                }

                break;
        }
    };

    const validateCheckout = async () => {
        setIsLoading(true);
        const resp = await checkoutRules();

        if (resp?.status === 204) {
            setIsLoading(false);
            history.push(ROUTES.ORDER_SUMMARY);
        } else {
            setIsLoading(false);
            notify(resp?.data?.message?.message, notifType.DANGER, 5000);
        }
    };

    const getCurrency = () => APP_CURRENCY_NAME;

    // const getTotalSubscription = () => {
    //     let subscriptionAmount = 0;

    //     if (products?.length > 0) {
    //         if (!isEmpty(selectedCartItem) && selectedCartItem) {
    //             const selectedCartItemSubscription = products.filter(e => e?.product_attribute_value_id === selectedCartItem?.product_attribute_value_id)[0];

    //             const duration = Number(selectedCartItemSubscription?.rental_period?.duration_time);
    //             const subscriptionValue = selectedCartItemSubscription?.total;

    //             subscriptionAmount += subscriptionValue * duration;
    //         }
    //     } else if (userCart?.length > 0) {
    //         if (!isEmpty(selectedCartItem) && selectedCartItem) {
    //             const selectedCartItemSubscription = userCart.filter(e => e?.id === selectedCartItem?.id)[0];

    //             const duration = Number(selectedCartItemSubscription?.selectedRentalPeriod?.duration_time);
    //             const rentalPeriodValue = Number(selectedCartItemSubscription?.selectedRentalPeriod?.price);

    //             let priceImpactValue = 0;

    //             if (selectedCartItemSubscription?.selectedStorage?.item?.price_impact) {
    //                 const rentalPeriodId = selectedCartItemSubscription?.selectedRentalPeriod?.id;
    //                 const matchingPriceImpactValue = selectedCartItemSubscription?.selectedStorage?.item?.price_impacts.find(av => av.rental_period_id === rentalPeriodId);

    //                 priceImpactValue = Number(matchingPriceImpactValue?.price_impacts);
    //             }

    //             subscriptionAmount += Math.floor(rentalPeriodValue + priceImpactValue) * duration;
    //         }
    //     } else if (orderExist) {
    //         subscriptionAmount = order.newTotalPrice * order.newRentalPeriod.duration_time;
    //     }

    //     return subscriptionAmount > 0 ? subscriptionAmount : 0;
    // };

    const getMonthlyFee = () => {
        let fees = 0;
        const currency = getCurrency();

        if (products?.length > 0) {
            const product = selectedCartItem;

            fees = parseInt(product?.total).toFixed(0);
        } else if (userCart?.length > 0) {
            fees = parseInt(selectedCartItem?.total).toFixed(0);
        } else if (order?.newTotalPrice) {
            fees = order.newTotalPrice;
        }

        return currency + ' ' + fees;
    };

    const formatAmount = (amount) => {
        if (amount % 1 === 0) {
            amount = parseInt(amount, 10);
        } else {
            amount = amount.toFixed(2);
        }

        return amount;
    };

    const getCouponAmount = () => {
        let discountAmount = 0;
        const currency = getCurrency();

        if (meta.discount && meta.discount > 0) {
            discountAmount = parseFloat(meta.discount);
        } else if (order?.newDiscount) {
            discountAmount = parseFloat(order.newDiscount);
        }

        return currency + ' ' + formatAmount(discountAmount);
    };

    const getTotalPayable = () => {
        const currency = getCurrency();

        return currency + ' ' + getTotalPayableAmount();
    };

    const getTotalPayableAmount = () => {
        let fees = 0;

        if (products?.length > 0) {
            const product = selectedCartItem;

            fees = parseInt(product?.total).toFixed(0);
        } else if (userCart?.length > 0) {
            fees = parseInt(selectedCartItem?.total).toFixed(0);
        } else if (order?.newTotalPrice) {
            fees = parseInt(order?.newTotalPrice).toFixed(0);
        }

        let discountAmount = 0;

        if (meta.discount && meta.discount > 0) {
            discountAmount = parseFloat(meta.discount);
        } else if (order?.newDiscount) {
            discountAmount = parseFloat(order.newDiscount);
        }

        discountAmount = formatAmount(discountAmount);

        let totalPayableAmount = parseFloat(fees - discountAmount);

        totalPayableAmount = formatAmount(totalPayableAmount);

        return totalPayableAmount;
    };

    const getDuration = () => {
        const duration = getDurationCount();
        const months = duration === 1 ? 'month' : 'months';

        return `${duration} ${months}`;
    };

    const getDurationCount = () => {
        let duration = 0;

        if (products?.length > 0) {
            const product = selectedCartItem;

            duration = product?.rental_period?.duration_time;
        } else if (userCart?.length > 0) {
            duration = currentCartItemPricingObjLocalStorage?.data?.duration_time;
        } else if (orderExist) {
            duration = order.newRentalPeriod.duration_time;
        }

        return duration;
    };

    const onToggle = () => setIsOpen(!isOpen);

    const placeOrder = () => {
        setIsOpen(false);
        dispatch(triggerOrderPlacingFinalEvent(true));
    };

    const backToOrderSummary = () => setIsOpen(!isOpen);

    const getProductName = () => {
        if (selectedCartItem && !isEmpty(selectedCartItem)) {
            return selectedCartItem.name || selectedCartItem.base_product?.name;
        }

        return productName;
    };

    const getProductColorStorage = () => {
        if (selectedCartItem && !isEmpty(selectedCartItem)) {
            const colorName = selectedCartItem.color?.name ?? selectedCartItem.selectedColor?.text;
            const storageAttribute = getStorageAttribute(selectedCartItem);

            return `${colorName} | ${storageAttribute}`;
        }

        return `${productColor} | ${productStorage}`;
    };

    // const getFormattedRetailPrice = () => {
    //     const retailValue = selectedCartItem?.base_product?.retail_value?.toLocaleString('en-US') || selectedCartItem?.retail_value?.toLocaleString('en-US');

    //     return `${APP_CURRENCY_NAME} ${retailValue || productRetailValue?.toLocaleString('en-US')}`;
    // };

    const updateOrderPrice = (value) => dispatch(orderTotal(value));

    const getRemovedItemsFromCart = async () => {
        const itemsToRemove = getUnwantedCartItems();
        const removedCartItems = [];

        const removeItems = async () => {
            if (itemsToRemove.length > 0) {
                const removePromises = itemsToRemove.map(item =>
                    new Promise(resolve => {
                        dispatch(handleRemoveCartItem(item.product_attribute_value_id, () => {
                            removedCartItems.push(item.product_attribute_value_id);
                            resolve();
                        }));
                    })
                );

                await Promise.all(removePromises);
            }
        };

        await removeItems();

        return removedCartItems;
    };

    const getUnwantedCartItems = () => userCart.filter(e => e?.product_attribute_value_id !== selectedCartItem?.product_attribute_value_id);

    useEffect(() => {
        if (meta.coupon) {
            setCoupon(meta.coupon);
            setCouponCode(meta.coupon);
            setCouponAmount(meta.coupon);

            if (isAutoApplyCouponValid() && meta?.coupon === AUTO_APPLY_PROMO_CODE) {
                const isPreOrderProduct = PRE_ORDER_PRODUCTS.includes(selectedCartItem?.base_product?.slug);

                if (!isPreOrderProduct || selectedCartItem?.rental_period?.duration_time !== 12) {
                    setCoupon('');
                    setCouponCode('');
                    setCouponAmount('');
                }

                setIsExtraMonthPromo(meta.discount === '0.00' && isPreOrderProduct && selectedCartItem?.rental_period?.duration_time === 12);
            } else {
                setIsExtraMonthPromo(meta.discount === '0.00');
            }
        } else if (orderExist && order.newDiscount) {
            setCoupon(order.coupon);
            setCouponCode(order.coupon);
            setCouponAmount(order.coupon);
            setIsExtraMonthPromo(order.newDiscount === '0.00');
        } else {
            setCoupon('');
            setCouponCode('');
            setCouponAmount('');
            setIsExtraMonthPromo('');
        }

        if (!tradeInDevice) {
            updateOrderPrice(getTotalPayableAmount());
        }

        if (selectedCartItem) {
            const storages = selectedCartItem?.base_product?.attributes?.find(e => e.type === 'storage');
            const storage = storages?.attribute_values?.find(e => e.id === selectedCartItem?.product_attribute_value_id);
            const retailValue = Number(storage?.meta?.retail_value)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            setRetailPrice(retailValue);
        }
    }, [meta, order, selectedCartItem]);

    useEffect(() => {
        if (orderExist) {
            const orderColorNameData = order?.variation?.product?.colors?.find(e => e.id === order.color_id);

            setProductName(order.variation.product.name);
            setProductColor(orderColorNameData.name);
            setProductStorage(order.variation.value);
        }
    }, [orderExist, order]);

    const getButtonText = (isLoading, type) => {
        if (isLoading) return <Spinner className='spinner' />;

        if (type === 'rent') {
            return 'Update';
        } else {
            const isOrderSummary = location.pathname === ROUTES.ORDER_SUMMARY;

            return isOrderSummary ? 'Place Order' : 'Next';
        }
    };

    return (
        <>
            <CheckoutPaymentInfo isOpen={isOpen} onToggle={onToggle} placeOrder={placeOrder}
                backToOrderSummary={backToOrderSummary} />
            <div className='right_panel_container'>
                <section className='sticky__bottom'>
                    {(((selectedCartItem !== null && selectedCartItem !== undefined && !isEmpty(selectedCartItem)) && (!isMobile && !emptyCart)) || (orderExist && !isMobile)) && <>
                        <div className={styles.subscriptionSummary}>
                            <h6>Subscription Summary</h6>
                            <ProductInfoTable
                                productName={getProductName()}
                                productColorStorage={getProductColorStorage()}
                                retailPrice={retailPrice} />
                            <hr />
                            <DurationAndPriceTable
                                durationTime={getDuration()}
                                baseRentalPrice={getMonthlyFee()}
                                tradeInDevice={tradeInDevice} />
                            <hr />
                            {couponAmount && couponCode && (
                                <>
                                    <DiscountBreakdownTable
                                        coupon={couponCode}
                                        discountAmount={getCouponAmount()}
                                        discountedAmount={getTotalPayable()}
                                        baseRentalPrice={getMonthlyFee()}
                                        tradeInDevice={tradeInDevice}
                                        durationTime={isExtraMonthPromo ? getDurationCount() + 1 : getDurationCount()}
                                        isExtraMonthPromo={isExtraMonthPromo} />
                                    <hr />
                                </>
                            )}
                            {tradeInDevice && (
                                <>
                                    <AfterTradeIn showTableOnly={true} />
                                    <hr />
                                </>
                            )}
                        </div>

                        {(location.pathname === ROUTES.CART || (location.pathname === ROUTES.ORDER_SUMMARY && type === 'rent')) && <>
                            <div className='promo_code_section'>
                                <form onSubmit={handleSubmit}>
                                    <h6 className='promo_code_title'>Promo Code</h6>
                                    <div className='promo_code_input_container'>
                                        <input type='text' className='promo_code_input' name='coupon'
                                            value={coupon}
                                            onChange={(e) => setCoupon(e.target.value)} />
                                        <button className='promo_code_apply_button' onClick={handleSubmit} type='submit'
                                            disabled={Loading} >
                                            {Loading ? <Spinner className='spinner' /> : 'Apply'}
                                        </button>
                                    </div>
                                </form>
                                <hr />
                            </div>
                        </>}
                    </>}
                    {((userCart?.length > 1 && isEmpty(selectedCartItem)) && !isMobile) &&
                        <div className='extraItemsNoteContainer'>
                            <p>You may only checkout one item at a time.</p>
                        </div>}
                    {(isMobile && location.pathname !== ROUTES.SIGNUP) && <div>
                        {isEmpty(selectedCartItem) && orderExist === false
                            ? <p className='sticky__bottom__restriction__note'>You may only checkout one item at a time.</p>
                            : <>
                                {cartStickyBottomExpanded ? (
                                    <div className={styles.expandedStickyBottom}>
                                        <h6 className='sticky__bottom__heading'>Order Summary</h6>
                                        <ProductInfoTable
                                            productName={getProductName()}
                                            productColorStorage={getProductColorStorage()}
                                            retailPrice={retailPrice} />
                                        <hr />
                                        <DurationAndPriceTable
                                            durationTime={getDuration()}
                                            baseRentalPrice={getMonthlyFee()}
                                            tradeInDevice={tradeInDevice} />
                                        <hr />
                                        {couponAmount && couponCode && (
                                            <>
                                                <DiscountBreakdownTable
                                                    coupon={couponCode}
                                                    discountAmount={getCouponAmount()}
                                                    discountedAmount={getTotalPayable()}
                                                    baseRentalPrice={getMonthlyFee()}
                                                    tradeInDevice={tradeInDevice}
                                                    durationTime={isExtraMonthPromo ? getDurationCount() + 1 : getDurationCount()}
                                                    isExtraMonthPromo={isExtraMonthPromo} />
                                                <hr />
                                            </>
                                        )}
                                        {tradeInDevice && (
                                            <>
                                                <AfterTradeIn showTableOnly={true} />
                                                <hr />
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div className={styles.collapsedStickyBottom}>
                                        <h6 className='sticky__bottom__heading'>Order Summary</h6>
                                        <Table borderless={true} size='sm' className={styles.table}>
                                            <tbody>
                                                <tr>
                                                    <td className={styles.productName}><strong>{getProductName()}</strong></td>
                                                    <td className='d-flex gap-2 align-items-end justify-content-end'>
                                                        {getMonthlyFee() !== getTotalPayable() && <small className='text-muted text-decoration-line-through'>SGD {getMonthlyFee()}/mo</small>}
                                                        <span className={styles.accent}>{tradeInDevice && !isEmpty(selectedCartItem)
                                                            ? <>
                                                                SGD <AfterTradeIn reducedMonthlyPriceOnly={true} />/mo
                                                            </>
                                                            : `${getTotalPayable()}/mo`}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        {tradeInDevice && (<AfterTradeIn showCreditOnly={true} />)}
                                        {(isExtraMonthPromo)
                                            ? <span className='w-100 d-flex justify-content-between'>Discount <span className={styles.accent}>+ FREE {getDurationCount() + 1}th month</span></span>
                                            : couponAmount && couponCode && (<span>Discount Applied: {couponCode}</span>)
                                        }
                                        {tradeInDevice && (<small>*The first month is billed at the original subscription rate. When trade-in is complete, the new subscription rate will apply, and you will be credited Cinch Credit if applicable.</small>)}
                                    </div>
                                )}
                            </>}
                    </div>}
                    {(!hideBottomCTABtns && (!emptyCart || orderExist) && location.pathname !== ROUTES.SIGNUP) &&
                        <div className='cta_buttons'>
                            <button onClick={handleThePreviousRouteBeforeTheOrder}>Back</button>
                            <button onClick={() => handleProceedToOrder(true)}
                                disabled={(isLoading || (userCart.length > 1 && isEmpty(selectedCartItem)) || emptyCart || selectedCartItem === undefined || selectedCartItem === null || isEmpty(selectedCartItem))}>
                                {getButtonText(isLoading, type)}
                            </button>
                        </div>}
                    {(hideBottomCTABtns && ((!emptyCart && userCart.length > 0) || orderExist) && location.pathname === ROUTES.ORDER_SUMMARY && isMobile && orderPlaced === false) &&
                        <div className='cta_buttons'>
                            <button onClick={handleThePreviousRouteBeforeTheOrder}>Back</button>
                            <button
                                onClick={() => handleProceedToOrder(true)}
                                disabled={(emptyCart && orderExist === false) || (
                                    orderPlacementPageTermsAndCondition === false ||
                                    orderPlacementPageTermsAndCondition === undefined ||
                                    isLoading ||
                                    (!cardList || cardList.length === 0)
                                )}>
                                {getButtonText(isLoading, type)}
                            </button>
                        </div>}
                    <div className='bottom_buttons'>
                        {(emptyCart && userCart.length === 0 && orderExist === false) && (<p>*Your cart is empty. Please add at least one item in the cart.</p>)}
                    </div>
                    {((selectedCartItem !== null && selectedCartItem !== undefined && userCart?.length === 1) || orderExist) && <p className='sticky__bottom__final__note'>By submitting your order, you agree to our <a href={ROUTES.TERMS_AND_CONDITIONS} target='_blank'
                        rel='noreferrer'>Terms and Conditions</a> and <a href={ROUTES.PRIVACY_POLICY} target='_blank' rel='noreferrer'>Privacy Policy</a>.</p>}
                </section>
            </div>
        </>
    );
};

const ProductInfoTable = ({ productName, productColorStorage, retailPrice }) => {
    return (
        <Table borderless={true} size='sm' className={styles.table}>
            <tbody>
                <tr>
                    <td><strong>{productName}</strong></td>
                </tr>
                <tr>
                    <td>{productColorStorage} | RRP: <span className='text-decoration-line-through'>SGD {retailPrice}</span></td>
                </tr>
            </tbody>
        </Table>
    );
};

const DurationAndPriceTable = ({ durationTime, baseRentalPrice, tradeInDevice }) => {
    return (
        <Table borderless={true} size='sm' className={styles.table}>
            <tbody>
                <tr>
                    <td>Duration</td>
                    <td className={styles.accent}>{durationTime}</td>
                </tr>
                <tr>
                    <td>{tradeInDevice ? 'Original rate' : 'Monthly rate'}</td>
                    <td>{baseRentalPrice}/mo</td>
                </tr>
            </tbody>
        </Table>
    );
};

const DiscountBreakdownTable = ({ coupon, discountAmount, discountedAmount, baseRentalPrice, tradeInDevice, durationTime, isExtraMonthPromo }) => {
    return (
        <Table borderless={true} size='sm' className={styles.table}>
            <tbody>
                <tr>
                    <td>Discount: {coupon !== AUTO_APPLY_PROMO_CODE && coupon}</td>
                    {coupon !== AUTO_APPLY_PROMO_CODE && <td>- {discountAmount}/mo</td>}
                </tr>
                <tr>
                    <td>{coupon !== AUTO_APPLY_PROMO_CODE ? 'After Discount:' : coupon}</td>
                    <td className='d-flex gap-2 align-items-end justify-content-end'>
                        {(isExtraMonthPromo)
                            ? <span className={styles.accent}>+ FREE {durationTime}th month</span>
                            : (coupon && discountAmount) && <>
                                <small className='text-decoration-line-through'>{baseRentalPrice}/mo</small>
                                <span className={tradeInDevice ? '' : `${styles.accent}`}>{discountedAmount}/mo</span>
                            </>}
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

ProductInfoTable.propTypes = {
    productName: PropTypes.string,
    productColorStorage: PropTypes.string,
    retailPrice: PropTypes.number
};

DurationAndPriceTable.propTypes = {
    durationTime: PropTypes.number,
    baseRentalPrice: PropTypes.number,
    tradeInDevice: PropTypes.object
};

DiscountBreakdownTable.propTypes = {
    coupon: PropTypes.string,
    discountAmount: PropTypes.number,
    discountedAmount: PropTypes.number,
    baseRentalPrice: PropTypes.number,
    tradeInDevice: PropTypes.object,
    durationTime: PropTypes.number,
    isExtraMonthPromo: PropTypes.bool
};

ToProceed.propTypes = {
    products: PropTypes.array,
    userCart: PropTypes.array,
    hideBottomCTABtns: PropTypes.bool,
    cartStickyBottomExpanded: PropTypes.bool,
    form: PropTypes.string,
    triggerLoginSubmit: PropTypes.func,
    triggerRegistrationSubmit: PropTypes.func,
    coupon: PropTypes.string,
    setCoupon: PropTypes.func,
    submitCoupon: PropTypes.func,
    handleSubmit: PropTypes.func,
    Loading: PropTypes.bool,
    order: PropTypes.array,
    orderExist: PropTypes.bool,
    type: PropTypes.string,
    onClick: PropTypes.func
};

export default ToProceed;
