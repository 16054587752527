import React from 'react';
import PropTypes from 'prop-types';
import './Selector.scss';
import Select from 'react-select';
import { ReactComponent as Icon } from 'assets/images/general/exclamation.svg';

FormSelcetion.propTypes = {
    options: PropTypes.array.isRequired,
    defaultValue: PropTypes.object,
    classNamePrefix: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    placeholder: PropTypes.string,
    isSearchable: PropTypes.bool
};

function FormSelcetion({
    options,
    defaultValue,
    classNamePrefix,
    onChange,
    value,
    label,
    name,
    error,
    errorMsg,
    isSearchable,
    placeholder
}) {
    return (
        <div className={!error ? 'selection' : 'selection--error'} data-name={name}>
            {label && <div className='selection__label'>{label}</div>}
            <Select
                placeholder={placeholder || 'Select an option'}
                defaultValue={defaultValue}
                classNamePrefix={classNamePrefix || 'select'}
                isSearchable={isSearchable}
                value={value}
                onChange={e => onChange({ target: { name, value: e.value } })}
                options={options}/>
            {error &&
            <div className='selection__error'>
                <Icon />
                {errorMsg}</div>
            }
        </div>
    );
}

export default FormSelcetion;
