import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import { uploadFile } from 'api/upload';

import UploadFile from 'assets/images/upload-icon.png';
import { ReactComponent as Icon } from 'assets/images/general/exclamation.svg';
import { ReactComponent as UploadedIcon } from 'assets/images/general/uploadedFile.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/general/pinkDelete.svg';

import './uploader.scss';

Uploader.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    values: PropTypes.array,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    multiple: PropTypes.bool,
    maxFiles: PropTypes.number,
    handleUploadedFile: PropTypes.func,
    maxSizeBytes: PropTypes.string,
    accept: PropTypes.string,
    handleErrorMsg: PropTypes.func,
    text: PropTypes.string
};

function Uploader({
    label,
    name,
    values,
    error,
    errorMsg,
    multiple,
    maxFiles,
    handleUploadedFile,
    maxSizeBytes,
    accept,
    handleErrorMsg,
    text
}) {
    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async ({ file }) => {
        setLoading(true);

        const body = new FormData();

        body.append('file', file);

        try {
            const url = await uploadFile(body);

            handleUploadedFile(name, url, 'add');
        } catch (error) {
            //
        }

        setLoading(false);
    };

    // called every time a file's `status` changes
    const handleChangeStatus = ({ file, remove, meta }, status) => {
        if (meta.status === 'rejected_file_type') {
            handleErrorMsg('File type is wrong', name);

            return;
        }

        if (meta.status === 'error_file_size') {
            const mb = parseFloat((maxSizeBytes / 1000000));

            handleErrorMsg('Max file size is : ' + mb + ' mb', name);

            return;
        }

        if (status === 'done') {
            handleSubmit({ file });
            remove();
        }
    };

    useEffect(() => {
        if (values.length < maxFiles) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [maxFiles, values.length]);

    return (
        <div className={'uploader'} data-name={name}>
            {label &&
            <div className={'uploader__label'}>{label}</div>
            }
            <div className={'uploader__itemsFlex'}>
                {text &&
                <div className={'uploader__col'}>
                    <div className={'uploader__text'}>
                        {text}
                    </div>
                </div>
                }
                <div className={'uploader__col'}>
                    <div className={'uploader__items'}>
                        {values.map((url, i) =>
                            <div className={'uploader__item'} key={i}>
                                <UploadedIcon className={'uploader__uploadedIcon'}/>
                                <div className={'uploader__uploadedLabel'}>File {i + 1}</div>
                                <DeleteIcon
                                    className={'uploader__remove'}
                                    onClick={() => handleUploadedFile(name, url, 'remove')}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={'uploader__box'}>
                <Dropzone
                    maxSizeBytes={maxSizeBytes}
                    canRemove={true}
                    inputContent={<div key='1' className={'uploader__desc'}>
                        {loading
                            ? <div className={'uploader__loading'}>
                                <Spin size='large' />
                                <div className={'uploader__loadingText'}>
                                    Uploading please wait ...
                                </div>
                            </div>
                            : <>
                                <div className={'uploader__icon'}><img src={UploadFile} alt='' height={64} /></div>
                                <div className={'uploader__detail'}>Choose file or drag here</div>
                            </>
                        }
                    </div>}
                    disabled={!show || loading}
                    classNames='uploader'
                    multiple={multiple}
                    maxFiles={maxFiles}
                    autoUpload={true}
                    // SubmitButtonComponent={item => {
                    //     return <div>
                    //         {show &&
                    //                 <button
                    //                     className='uploader__submit'
                    //                     disabled={loading}
                    //                     onClick={() => item.onSubmit(item)}>
                    //                     {!loading
                    //                         ? 'Press to Upload file(s) ...'
                    //                         : <div className={'uploader__loading'}>
                    //                             <ActivityIndicator />
                    //                             <div className={'uploader__loadingText'}>
                    //                                 Uploading please wait ...
                    //                             </div>
                    //                         </div>
                    //                     }
                    //                 </button>
                    //         }
                    //     </div>;
                    // }}
                    onChangeStatus={handleChangeStatus}
                    // onSubmit={handleSubmit}
                    accept={accept || '*'}/>
            </div>
            {error &&
            <div className={'uploader__error'}>
                <Icon />
                {errorMsg}
            </div>
            }
        </div>
    );
}

export default Uploader;
