import { singpassForm } from 'api/authorize';
import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

import { validateEmail } from 'utils/helpers';

import SingpassManualFormScreen from 'screens/SingpassManualForm/SingpassManualFormScreen';

const SingpassManualFormContainer = () => {
    const myRef = useRef(null);

    useEffect(() => {
        window.document.title = 'Manual Form';
    }, []);

    const [step, setStep] = useState(1);
    const [firstForm, setFirstForm] = useState({
        legal_name: '',
        alias_name: '',
        nric_fin: '',
        nric_fin_front_image: [],
        nric_fin_back_image: []
    });
    const [secondForm, setSecondForm] = useState({
        sex: '',
        martial_status: '',
        maried_name: '',
        mobile: '',
        email_address: ''
    });
    const [thirdForm, setThirdForm] = useState({
        dob: '',
        birth_country: '',
        ethnicity: '',
        secondary_race: ''
    });
    const [fourthForm, setFourthForm] = useState({
        residency_status: '',
        postal_code: '',
        residential_address: '',
        housing_type: '',
        pass_type: '',
        // pass_status: '',
        workpass_expiry_date: ''
    });
    const [fifthForm, setFifthForm] = useState({
        occupation: '',
        employment_sector: '',
        employer_name: '',
        income_noa: '',
        assessed_year_income: '',
        last_3_slips: []
    });
    const [errors, setErrors] = useState({
        legal_name: false,
        alias_name: false,
        nric_fin: false,
        nric_fin_front_image: false,
        nric_fin_back_image: false,

        sex: false,
        martial_status: false,
        maried_name: false,
        mobile: false,
        email_address: false,

        dob: false,
        birth_country: false,
        ethnicity: false,
        secondary_race: false,

        residency_status: false,
        postal_code: false,
        residential_address: false,
        housing_type: false,
        pass_type: false,
        // pass_status: false,
        workpass_expiry_date: false,

        occupation: false,
        employment_sector: false,
        employer_name: false,
        income_noa: false,
        assessed_year_income: false,
        last_3_slips: false
    });
    const errorMsg = {
        legal_name: 'This field is required *',
        alias_name: 'This field is required *',
        nric_fin: 'This field is required *',
        nric_fin_front_image: 'This field is required *',
        nric_fin_back_image: 'This field is required *',

        sex: 'This field is required *',
        martial_status: 'This field is required *',
        maried_name: 'This field is required *',
        mobile: 'This field is required *',
        email_address: 'This field is required *',

        dob: 'This field is required *',
        birth_country: 'This field is required *',
        ethnicity: 'This field is required *',
        secondary_race: 'This field is required *',

        residency_status: 'This field is required *',
        postal_code: 'This field is required *',
        residential_address: 'This field is required *',
        housing_type: 'This field is required *',
        pass_type: 'This field is required *',
        // pass_status: 'This field is required *',
        workpass_expiry_date: 'This field is required *',

        occupation: 'This field is required *',
        employment_sector: 'This field is required *',
        employer_name: 'This field is required *',
        income_noa: 'This field is required *',
        assessed_year_income: 'This field is required *',
        last_3_slips: 'This field is required *'
    };
    const [errorMsgs, setErrorMsgs] = useState(errorMsg);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const getData = localStorage.getItem('manualForm');

        if (getData) {
            const manualForm = JSON.parse(getData);

            setFirstForm(manualForm.firstForm);
            setSecondForm(manualForm.secondForm);
            setThirdForm(manualForm.thirdForm);
            setFourthForm(manualForm.fourthForm);
            setFifthForm(manualForm.fifthForm);
        }
    }, []);

    const validateForm = () => {
        let check = false;

        const checkForm = fields => {
            const keys = Object.keys(fields);

            for (const field of keys) {
                // const ref = field;

                if (fields[field] === '' ||
                fields[field] === null ||
                (field === 'mobile' && fields[field].length < 10) ||
                (field === 'mobile' && fields[field] === '65') ||
                (field === 'nric_fin_front_image' && fields[field].length === 0) ||
                (field === 'nric_fin_back_image' && fields[field].length === 0) ||
                (field === 'last_3_slips' && fields[field].length === 0)
                ) {
                // check if fields are empty
                    const elementPosition = myRef.current.querySelector(`[data-name=${field}]`).getBoundingClientRect().top + window.scrollY;

                    const offsetPosition = elementPosition - 200;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                    setErrors(prev => ({ ...prev, [field]: true }));
                    setErrorMsgs(errorMsg);

                    if (field === 'mobile' && fields[field].length < 10) {
                        setErrorMsgs(prev => ({ ...prev, [field]: 'wrong format*' }));
                    }

                    check = true;

                    return true;
                }

                if (field === 'email_address' && fields[field] !== '' && !validateEmail(fields[field])) {
                    const elementPosition = myRef.current.querySelector(`[data-name=${field}]`).getBoundingClientRect().top + window.scrollY;

                    const offsetPosition = elementPosition - 200;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                    setErrors(prev => ({ ...prev, [field]: true }));
                    setErrorMsgs(prev => ({ ...prev, [field]: 'Please enter the correct email address' }));
                    check = true;

                    return true;
                }

                if (field === 'postal_code' && fields[field].length !== 6) {
                    const elementPosition = myRef.current.querySelector(`[data-name=${field}]`).getBoundingClientRect().top + window.scrollY;

                    const offsetPosition = elementPosition - 200;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                    setErrors(prev => ({ ...prev, [field]: true }));
                    setErrorMsgs(prev => ({ ...prev, [field]: 'Please enter the correct postal code' }));
                    check = true;

                    return true;
                }
            }
        };

        switch (step) {
            case 1:
                checkForm(firstForm);
                break;
            case 2:
                checkForm(secondForm);
                break;
            case 3:
                checkForm(thirdForm);
                break;
            case 4:
                checkForm(fourthForm);
                break;
            case 5:
                checkForm(fifthForm);
                break;

            default:
                break;
        }

        return check;
    };

    const handleNextStep = async() => {
        const check = validateForm();

        if (check) {
            return true;
        }

        if (step !== 5) {
            let dob = '';

            if (thirdForm.dob) {
                const dobDate = new Date(thirdForm.dob);
                const year = dobDate.getFullYear();
                const month = dobDate.getMonth() + 1;
                const day = dobDate.getDate();

                dob = year + '-' + month + '-' + day;
            }

            const manualForm = JSON.stringify({
                firstForm,
                secondForm,
                thirdForm,
                fourthForm,
                fifthForm,
                dob,
                nric_fin_front_image: firstForm.nric_fin_front_image[0],
                nric_fin_back_image: firstForm.nric_fin_back_image[0]
            });

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            localStorage.setItem('manualForm', manualForm);
            setStep(step + 1);
        } else {
            const tmp = [];

            const formattedDate = format(new Date(thirdForm.dob), 'yyyy-MM-dd');

            const formattedThirdForm = await reFormattedThirdForm(formattedDate);

            fifthForm.last_3_slips.map(url => tmp.push({ url }));

            setLoading(true);

            try {
                await singpassForm({
                    ...firstForm,
                    ...secondForm,
                    ...formattedThirdForm,
                    ...fourthForm,
                    ...fifthForm,
                    assessed_year_income: fifthForm.assessed_year_income + '-01-01',
                    last_3_slips: tmp,
                    nric_fin_front_image: firstForm.nric_fin_front_image[0],
                    nric_fin_back_image: firstForm.nric_fin_back_image[0]
                });

                localStorage.removeItem('manualForm');
                setTimeout(() => {
                    setOpenModal(true);
                    setLoading(false);
                }, 8000);
            } catch (err) {
                //
                setLoading(false);
            }
        }
    };

    const handlePrevStep = () => setStep(step - 1);

    const reFormattedThirdForm = async(formattedDate) => {
        return {
            dob: formattedDate,
            birth_country: thirdForm.birth_country,
            ethnicity: thirdForm.ethnicity,
            secondary_race: thirdForm.secondary_race
        };
    };

    const handleUploadedFile = async(name, url, type) => {
        if (firstForm[name] !== undefined) {
            const tmp = firstForm[name];

            if (type === 'add') {
                tmp.push(url);
                setFirstForm(prev => ({ ...prev, [name]: tmp }));
            }

            if (type === 'remove') {
                const removeTmp = tmp.filter(i => i !== url);

                setFirstForm(prev => ({ ...prev, [name]: removeTmp }));
            }
        }

        if (fifthForm[name] !== undefined) {
            const tmp = fifthForm[name];

            if (type === 'add') {
                tmp.push(url);
                setFifthForm(prev => ({ ...prev, [name]: tmp }));
            }

            if (type === 'remove') {
                const removeTmp = tmp.filter(i => i !== url);

                setFifthForm(prev => ({ ...prev, [name]: removeTmp }));
            }
        }

        setErrors(prev => ({ ...prev, [name]: false }));
    };

    const handleErrorMsg = (msg, field) => {
        const elementPosition = myRef.current.querySelector(`[data-name=${field}]`).getBoundingClientRect().top + window.scrollY;

        const offsetPosition = elementPosition - 200;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
        setErrors(prev => ({ ...prev, [field]: true }));
        setErrorMsgs(prev => ({ ...prev, [field]: msg }));
    };

    return (
        <>
            <SingpassManualFormScreen
                handleErrorMsg={handleErrorMsg}
                handleUploadedFile={handleUploadedFile}
                openModal={openModal}
                setOpenModal={setOpenModal}
                loading={loading}
                errorMsgs={errorMsgs}
                myRef={myRef}
                errors={errors}
                setErrors={setErrors}
                step={step}
                firstForm={firstForm}
                setFirstForm={setFirstForm}
                secondForm={secondForm}
                setSecondForm={setSecondForm}
                thirdForm={thirdForm}
                setThirdForm={setThirdForm}
                fourthForm={fourthForm}
                setFourthForm={setFourthForm}
                fifthForm={fifthForm}
                setFifthForm={setFifthForm}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}/>
            <Helmet>
                <title>Manual SingPass</title>
                <meta name='description' content="Rent your favourite tech for low monthly subscriptions. Use it worry-free and return, purchase or upgrade whenever you're ready."/>
                <meta property='og:description' content="Rent your favourite tech for low monthly subscriptions. Choose from hundreds of products, use it worry-free and return, purchase or upgrade whenever you're ready." />
            </Helmet>
        </>
    );
};

export default SingpassManualFormContainer;
