import { createAxiosInstance } from 'utils/helpers';

const initialState = {
    segment: 'b2s'
};

const SUBMIT_CONTACT_FORM_START = 'SUBMIT_CONTACT_FORM_START';
const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS';
const SUBMIT_CONTACT_FORM_FAILURE = 'SUBMIT_CONTACT_FORM_FAILURE';
const SET_SEGMENT = 'SET_SEGMENT';
const FETCH_SEGMENT = 'FETCH_SEGMENT';
const TRADE_IN_DISCOUNTED_PRICE = 'TRADE_IN_DISCOUNTED_PRICE';

export const submitContactFormStart = () => ({
    type: SUBMIT_CONTACT_FORM_START
});

export const submitContactFormSuccess = (data) => ({
    type: SUBMIT_CONTACT_FORM_SUCCESS,
    data
});

export const submitContactFormFailure = () => ({
    type: SUBMIT_CONTACT_FORM_FAILURE
});

export const setSegment = (segment) => ({
    type: SET_SEGMENT,
    segment
});

export const getSegment = () => ({
    type: FETCH_SEGMENT
});

export const tradeInDiscountedPrice = (data) => ({
    type: TRADE_IN_DISCOUNTED_PRICE,
    data
});

export const handleSubmitContactForm = (form, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(submitContactFormStart());

        const instance = createAxiosInstance();

        try {
            await instance.post('/contact/create', form);

            dispatch(submitContactFormSuccess());
            onSuccess();
        } catch (e) {
            dispatch(submitContactFormFailure());
            onFailure();
        }
    };
};

export default function cart(state = initialState, action) {
    switch (action.type) {
        case FETCH_SEGMENT:
            return JSON.parse(state.segment);
        case SET_SEGMENT:
            return {
                ...state,
                segment: action.segment
            };
        case TRADE_IN_DISCOUNTED_PRICE:
            return {
                ...state,
                tradeInDiscountedPrice: action.data
            };
        default:
            return state;
    }
}
