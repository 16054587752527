import axios from 'axios';

import { AUTH_API_URL } from 'config/constants';

import { notifType, notify } from 'utils/notifSender';
import { getAuthToken } from 'utils/helpers';

export async function singpassForm(data) {
    const options = {
        method: 'POST',
        data,
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/authorize/singpass/form'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again. ', notifType.DANGER);

        throw err;
    }
}

export async function singpassResend(data) {
    const options = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/authorize/singpass/otp/resend',
        data
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        throw err;
    }
}
