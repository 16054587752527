import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { useSelector } from 'react-redux';

import { ROUTES } from 'config/constants';
import { checkoutRules, getUserCart } from 'api/cart';

import FullscreenLoader from '../../components/FullscreenLoader/FullscreenLoader';
import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';

import logo from 'assets/images/general/logo.svg';

import { storeUserEvent } from 'api/user';

import './SingpassResult.scss';

const SingpassResultContainer = ({ profile }) => {
    const location = useLocation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [openForDuplicateNRIC, setOpenForDuplicateNRIC] = useState(false);
    const products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];
    const { segment } = useSelector(state => ({ segment: state.common.segment }));
    const segmentQuery = segment ? `?segment=${segment}` : '';

    useEffect(() => {
        window.document.title = 'Singpass Result';
    }, []);

    const checkSingpassStatus = status => {
        if (status === 'success') {
            checkUserCart();
            triggerUserEvent('singpass_success');
        } else if (status === 'duplicate_nric_fin') {
            setIsLoading(false);
            setOpenForDuplicateNRIC(true);
            triggerUserEvent('singpass_duplicate_nric_fin');
        } else {
            setIsLoading(false);
            setOpen(true);
            triggerUserEvent('singpass_error');
        }
    };

    const triggerUserEvent = async (event) => {
        const payload = {
            event: event
        };

        await storeUserEvent(payload);
    };

    const checkUserCart = async () => {
        const resp = await getUserCart();

        if (resp?.data?.products?.length > 0) {
            validateCheckout();
        } else {
            setIsLoading(false);

            if (products?.length > 0) {
                // if (profile?.is_student || profile?.pass_proof_of_expiry_required) {
                //     history.push(ROUTES.DOCUMENTSPROOF);
                // } else {
                //     history.push(ROUTES.ORDER_SUMMARY);
                // }
                history.push(ROUTES.ORDER_SUMMARY);
            } else {
                history.push(ROUTES.CART);
            }
        }
    };

    const validateCheckout = async () => {
        const resp = await checkoutRules();

        setIsLoading(false);

        if (resp?.status === 204) {
            // if (profile?.is_student || profile?.pass_proof_of_expiry_required) {
            //     if (profile?.pass_proof_of_expiry_required) {
            //         history.push(ROUTES.DOCUMENTSPROOF);
            //     } else {
            //         history.push(ROUTES.ORDER_SUMMARY);
            //     }
            // } else {
            //     history.push(ROUTES.ORDER_SUMMARY);
            // }
            history.push(ROUTES.ORDER_SUMMARY);
        } else {
            notify(resp?.data?.message?.message, notifType.DANGER, 5000);
            history.push(ROUTES.CART);
        }
    };

    useEffect(() => {
        if (!location.search) {
            history.push(ROUTES.HOME + segmentQuery);

            return;
        }

        checkSingpassStatus(location.search.split('?status=')[1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ height: '60vh' }}>
            {isLoading && <FullscreenLoader />}
            <Modal
                isOpen={open}
                centered={true}
                className='modalError'>
                <div className='errorModal'>
                    <div className='errorModal__logo'><img src={logo} alt='Cinch Logo' /></div>
                    <div className='errorModal__text'>Unable to retrieve your information from Singpass.</div>
                    <div>
                        <Button
                            className='submit'
                            onClick={() => history.push(ROUTES.CART)}
                            title='Proceed with your application' />
                    </div>
                </div>
            </Modal>
            <Modal
                centered={true}
                className='modalError'
                isOpen={openForDuplicateNRIC}>
                <div className='errorModal'>
                    <div className='errorModal__logo'><img src={logo} alt='Cinch Logo' /></div>
                    <div className='errorModal__text'>Duplicate NRIC found.</div>
                    <div>
                        <Button
                            className='submit'
                            onClick={() => history.push(ROUTES.CART)}
                            title='Proceed with your application' />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

SingpassResultContainer.propTypes = {
    profile: PropTypes.object
};

export default SingpassResultContainer;
