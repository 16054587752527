import React from 'react';
import PropTypes from 'prop-types';

import Title from '../title';
import FormInput from '../FormInput';
import Uploader from '../Uploader';
import FormFooter from '../FormFooter';

import style from './Form.module.scss';

First.propTypes = {
    errors: PropTypes.object.isRequired,
    setErrors: PropTypes.func.isRequired,
    firstForm: PropTypes.object.isRequired,
    setFirstForm: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    errorMsgs: PropTypes.object,
    handleUploadedFile: PropTypes.func,
    handleErrorMsg: PropTypes.func
};

function First({
    errors,
    setErrors,
    firstForm,
    setFirstForm,
    handleNextStep,
    errorMsgs,
    handleUploadedFile,
    handleErrorMsg
}) {
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setFirstForm(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: false }));
    };

    return (
        <div className={style.body}>
            <div>
                <Title />
            </div>
            <div>
                <div className={style.col}>
                    <FormInput
                        errorMsg={errorMsgs.legal_name}
                        error={errors.legal_name}
                        type='text'
                        name={'legal_name'}
                        value={firstForm.legal_name}
                        onChange={handleChange}
                        desc='We will be using those information to verify your identity.'
                        placeHolder='Enter your full name'
                        label='Full name as in your NRIC/FIN:' />
                </div>
                <div className={style.col}>
                    <FormInput
                        type='text'
                        name={'alias_name'}
                        errorMsg={errorMsgs.alias_name}
                        error={errors.alias_name}
                        value={firstForm.alias_name}
                        onChange={handleChange}
                        placeHolder='Enter your alias name'
                        label='Alias name: ' />
                </div>
                <div className={style.col}>
                    <FormInput
                        type='text'
                        name={'nric_fin'}
                        errorMsg={errorMsgs.nric_fin}
                        error={errors.nric_fin}
                        value={firstForm.nric_fin}
                        onChange={handleChange}
                        placeHolder='Enter your NRIC/FIN Number'
                        label='NRIC/FIN Number:' />
                </div>
                <div className={style.col}>
                    <Uploader
                        text='*Only PDF files are accepted'
                        handleErrorMsg={handleErrorMsg}
                        accept='application/pdf,application/vnd.ms-excel'
                        maxSizeBytes={'3e+6'}
                        multiple={false}
                        maxFiles={1}
                        label='Upload the front of your NRIC/FIN:'
                        name='nric_fin_front_image'
                        values={firstForm.nric_fin_front_image}
                        error={errors.nric_fin_front_image}
                        errorMsg={errorMsgs.nric_fin_front_image}
                        handleUploadedFile={handleUploadedFile}/>
                </div>
                <div className={style.col}>
                    <Uploader
                        text='*Only PDF files are accepted'
                        handleErrorMsg={handleErrorMsg}
                        accept='application/pdf,application/vnd.ms-excel'
                        maxSizeBytes={'3e+6'}
                        multiple={false}
                        maxFiles={1}
                        label='Upload the back of your NRIC/FIN:'
                        name='nric_fin_back_image'
                        values={firstForm.nric_fin_back_image}
                        error={errors.nric_fin_back_image}
                        errorMsg={errorMsgs.nric_fin_back_image}
                        handleUploadedFile={handleUploadedFile}/>
                </div>
                <div className={style.col}>
                    <FormFooter
                        prevLabel=''
                        nextLabel='Next'
                        nextClick={handleNextStep}/>
                </div>
            </div>
        </div>
    );
}

export default First;
