export const gender = [
    { label: 'FEMALE', value: 'FEMALE' },
    { label: 'MALE', value: 'MALE' },
    { label: 'UNKNOWN', value: 'UNKNOWN' }
];

export const martialStatus = [
    { label: 'SINGLE', value: 'SINGLE' },
    { label: 'MARRIED', value: 'MARRIED' },
    { label: 'WIDOWED', value: 'WIDOWED' },
    { label: 'DIVORCED', value: 'DIVORCED' }
];

export const ethnicity = [
    { label: 'ANGLO BURMESE', value: 'ANGLO BURMESE' },
    { label: 'ANGLO CHINESE', value: 'ANGLO CHINESE' },
    { label: 'AMERINDIAN', value: 'AMERINDIAN' },
    { label: 'AFRICAN', value: 'AFRICAN' },
    { label: 'AFGHAN', value: 'AFGHAN' },
    { label: 'ANGLO THAI', value: 'ANGLO THAI' },
    { label: 'ANGLO INDIAN', value: 'ANGLO INDIAN' },
    { label: 'ACHEHNESE', value: 'ACHEHNESE' },
    { label: 'ALBANIAN', value: 'ALBANIAN' },
    { label: 'ARMENIAN', value: 'ARMENIAN' },
    { label: 'ANNAMITE', value: 'ANNAMITE' },
    { label: 'AMBONESE', value: 'AMBONESE' },
    { label: 'ANGLO FILIPINO', value: 'ANGLO FILIPINO' },
    { label: 'ARAB', value: 'ARAB' },
    { label: 'ASSAMI', value: 'ASSAMI' },
    { label: 'AUSTRIAN', value: 'AUSTRIAN' },
    { label: 'AUSTRALIAN', value: 'AUSTRALIAN' },
    { label: 'ANGLO SAXON', value: 'ANGLO SAXON' },
    { label: 'ARYAN', value: 'ARYAN' },
    { label: 'AZERI', value: 'AZERI' },
    { label: 'BATAK', value: 'BATAK' },
    { label: 'BULGARIAN', value: 'BULGARIAN' },
    { label: 'BUTONESE', value: 'BUTONESE' },
    { label: 'BANGLADESHI', value: 'BANGLADESHI' },
    { label: 'BELGIAN', value: 'BELGIAN' },
    { label: 'BAJAU', value: 'BAJAU' },
    { label: 'BUGIS', value: 'BUGIS' },
    { label: 'BURGHER', value: 'BURGHER' },
    { label: 'BENGALI', value: 'BENGALI' },
    { label: 'BANJARESE', value: 'BANJARESE' },
    { label: 'BAMAR', value: 'BAMAR' },
    { label: 'BANGALA', value: 'BANGALA' },
    { label: 'BALINESE', value: 'BALINESE' },
    { label: 'BHUTANESE', value: 'BHUTANESE' },
    { label: 'BANTEN', value: 'BANTEN' },
    { label: 'BASQUE', value: 'BASQUE' },
    { label: 'BRAHMIN', value: 'BRAHMIN' },
    { label: 'BISAYA', value: 'BISAYA' },
    { label: 'BRITISH', value: 'BRITISH' },
    { label: 'BURMESE', value: 'BURMESE' },
    { label: 'BOSNIAK', value: 'BOSNIAK' },
    { label: 'BETAWI', value: 'BETAWI' },
    { label: 'BOYANESE', value: 'BOYANESE' },
    { label: 'BRAZILIAN', value: 'BRAZILIAN' },
    { label: 'CAUCASIAN', value: 'CAUCASIAN' },
    { label: 'CANADIAN', value: 'CANADIAN' },
    { label: 'CAPE COLOURED', value: 'CAPE COLOURED' },
    { label: 'CAMBODIAN', value: 'CAMBODIAN' },
    { label: 'CEYLONESE', value: 'CEYLONESE' },
    { label: 'CORNISH', value: 'CORNISH' },
    { label: 'CREOLE', value: 'CREOLE' },
    { label: 'SWISS', value: 'SWISS' },
    { label: 'CROAT', value: 'CROAT' },
    { label: 'CHAMORRO', value: 'CHAMORRO' },
    { label: 'CEYLON MOOR', value: 'CEYLON MOOR' },
    { label: 'CHINESE', value: 'CHINESE' },
    { label: 'COCOS', value: 'COCOS' },
    { label: 'CARIBBEAN', value: 'CARIBBEAN' },
    { label: 'CZECHOSLOVAK', value: 'CZECHOSLOVAK' },
    { label: 'CZECH', value: 'CZECH' },
    { label: 'DANE', value: 'DANE' },
    { label: 'DUTCH BURGHER', value: 'DUTCH BURGHER' },
    { label: 'BIDAYUH', value: 'BIDAYUH' },
    { label: 'DUSUN', value: 'DUSUN' },
    { label: 'DUTCH', value: 'DUTCH' },
    { label: 'DAYAK', value: 'DAYAK' },
    { label: 'ENGLISH', value: 'ENGLISH' },
    { label: 'EUROPEAN', value: 'EUROPEAN' },
    { label: 'SPANISH', value: 'SPANISH' },
    { label: 'ETHIOPIAN', value: 'ETHIOPIAN' },
    { label: 'EURASIAN', value: 'EURASIAN' },
    { label: 'EGYPTIAN', value: 'EGYPTIAN' },
    { label: 'FINN', value: 'FINN' },
    { label: 'FIJIAN', value: 'FIJIAN' },
    { label: 'FLEMISH', value: 'FLEMISH' },
    { label: 'FRENCH', value: 'FRENCH' },
    { label: 'GOAN', value: 'GOAN' },
    { label: 'GUJARATI', value: 'GUJARATI' },
    { label: 'GHANAIAN', value: 'GHANAIAN' },
    { label: 'GURKHA', value: 'GURKHA' },
    { label: 'GERMAN', value: 'GERMAN' },
    { label: 'GOANESE', value: 'GOANESE' },
    { label: 'GREEK', value: 'GREEK' },
    { label: 'HAITIAN', value: 'HAITIAN' },
    { label: 'HISPANIC', value: 'HISPANIC' },
    { label: 'HOLLANDER', value: 'HOLLANDER' },
    { label: 'HINDUSTANI', value: 'HINDUSTANI' },
    { label: 'HUNGARIAN', value: 'HUNGARIAN' },
    { label: 'HAWAIIAN', value: 'HAWAIIAN' },
    { label: 'IRANIAN', value: 'IRANIAN' },
    { label: 'IBAN', value: 'IBAN' },
    { label: 'INDONESIAN', value: 'INDONESIAN' },
    { label: 'ISOKO', value: 'ISOKO' },
    { label: 'ISRAELI', value: 'ISRAELI' },
    { label: 'INDIAN', value: 'INDIAN' },
    { label: 'IRAQI', value: 'IRAQI' },
    { label: 'IRISH', value: 'IRISH' },
    { label: 'ICELANDER', value: 'ICELANDER' },
    { label: 'ITALIAN', value: 'ITALIAN' },
    { label: 'INUIT', value: 'INUIT' },
    { label: 'JAVANESE', value: 'JAVANESE' },
    { label: 'JAKUN', value: 'JAKUN' },
    { label: 'JAMAICAN', value: 'JAMAICAN' },
    { label: 'JORDANIAN', value: 'JORDANIAN' },
    { label: 'JAPANESE', value: 'JAPANESE' },
    { label: 'JEW', value: 'JEW' },
    { label: 'KACHIN', value: 'KACHIN' },
    { label: 'KHASI', value: 'KHASI' },
    { label: 'KAYAH', value: 'KAYAH' },
    { label: 'KAYIN', value: 'KAYIN' },
    { label: 'KENYAN', value: 'KENYAN' },
    { label: 'KINH', value: 'KINH' },
    { label: 'KYRGYZ', value: 'KYRGYZ' },
    { label: 'KHMER', value: 'KHMER' },
    { label: 'KENYAH', value: 'KENYAH' },
    { label: 'KAZAKH', value: 'KAZAKH' },
    { label: 'KELABIT', value: 'KELABIT' },
    { label: 'KAMPUCHEAN', value: 'KAMPUCHEAN' },
    { label: 'KAREN', value: 'KAREN' },
    { label: 'KOREAN', value: 'KOREAN' },
    { label: 'KAYAN', value: 'KAYAN' },
    { label: 'KADAZAN', value: 'KADAZAN' },
    { label: 'LAO', value: 'LAO' },
    { label: 'LEBANESE', value: 'LEBANESE' },
    { label: 'LITHUANIAN', value: 'LITHUANIAN' },
    { label: 'SRI LANKAN', value: 'SRI LANKAN' },
    { label: 'LATIN', value: 'LATIN' },
    { label: 'LATVIAN', value: 'LATVIAN' },
    { label: 'LUXEMBOURGER', value: 'LUXEMBOURGER' },
    { label: 'LIBYAN', value: 'LIBYAN' },
    { label: 'MADURESE', value: 'MADURESE' },
    { label: 'MALABARI', value: 'MALABARI' },
    { label: 'MAGYARS', value: 'MAGYARS' },
    { label: 'MOLDAVIAN', value: 'MOLDAVIAN' },
    { label: 'MINANGKABAU', value: 'MINANGKABAU' },
    { label: 'MANX', value: 'MANX' },
    { label: 'MALAGASY', value: 'MALAGASY' },
    { label: 'MAHRATTA', value: 'MAHRATTA' },
    { label: 'MAORI', value: 'MAORI' },
    { label: 'MURUT', value: 'MURUT' },
    { label: 'MAKASARESE', value: 'MAKASARESE' },
    { label: 'MALDIVIAN', value: 'MALDIVIAN' },
    { label: 'MALAYALEE', value: 'MALAYALEE' },
    { label: 'MELANESIAN', value: 'MELANESIAN' },
    { label: 'MONGOLIAN', value: 'MONGOLIAN' },
    { label: 'MANIPURI', value: 'MANIPURI' },
    { label: 'MESTIZO', value: 'MESTIZO' },
    { label: 'MARATHI', value: 'MARATHI' },
    { label: 'METIS', value: 'METIS' },
    { label: 'MALTESE', value: 'MALTESE' },
    { label: 'MAURITIAN', value: 'MAURITIAN' },
    { label: 'MON', value: 'MON' },
    { label: 'MOROCCAN', value: 'MOROCCAN' },
    { label: 'MEXICAN', value: 'MEXICAN' },
    { label: 'MALAY', value: 'MALAY' },
    { label: 'MELANAU', value: 'MELANAU' },
    { label: 'NAGA', value: 'NAGA' },
    { label: 'NEGRO', value: 'NEGRO' },
    { label: 'NIGERIAN', value: 'NIGERIAN' },
    { label: 'NETHERLANDER', value: 'NETHERLANDER' },
    { label: 'NORWEGIAN', value: 'NORWEGIAN' },
    { label: 'NEPALESE', value: 'NEPALESE' },
    { label: 'NAURUAN', value: 'NAURUAN' },
    { label: 'NEWAR', value: 'NEWAR' },
    { label: 'NEW ZEALANDER', value: 'NEW ZEALANDER' },
    { label: 'PENAN', value: 'PENAN' },
    { label: 'PALESTINE', value: 'PALESTINE' },
    { label: 'POLISH', value: 'POLISH' },
    { label: 'PERSIAN', value: 'PERSIAN' },
    { label: 'FILIPINO', value: 'FILIPINO' },
    { label: 'PUNJABI', value: 'PUNJABI' },
    { label: 'PAKISTANI', value: 'PAKISTANI' },
    { label: 'POLE', value: 'POLE' },
    { label: 'PATHAN', value: 'PATHAN' },
    { label: 'PORTUGUESE', value: 'PORTUGUESE' },
    { label: 'PERUVIAN', value: 'PERUVIAN' },
    { label: 'PARSEE', value: 'PARSEE' },
    { label: 'PUNAN', value: 'PUNAN' },
    { label: 'POLYNESIAN', value: 'POLYNESIAN' },
    { label: 'RAJPUT', value: 'RAJPUT' },
    { label: 'RAKHINE', value: 'RAKHINE' },
    { label: 'ROMANIAN', value: 'ROMANIAN' },
    { label: 'RUSSIAN', value: 'RUSSIAN' },
    { label: 'SINO KADAZAN', value: 'SINO KADAZAN' },
    { label: 'SAMMARINESE', value: 'SAMMARINESE' },
    { label: 'SCOT', value: 'SCOT' },
    { label: 'SINDHI', value: 'SINDHI' },
    { label: 'SWEDE', value: 'SWEDE' },
    { label: 'SERBIA/MONTENGERIN', value: 'SERBIA/MONTENGERIN' },
    { label: 'SAMOAN', value: 'SAMOAN' },
    { label: 'SHAN', value: 'SHAN' },
    { label: 'SINHALESE', value: 'SINHALESE' },
    { label: 'SINO JAPANESE', value: 'SINO JAPANESE' },
    { label: 'SIKH', value: 'SIKH' },
    { label: 'SLOVAK', value: 'SLOVAK' },
    { label: 'SUMATRAN', value: 'SUMATRAN' },
    { label: 'SINO INDIAN', value: 'SINO INDIAN' },
    { label: 'SOMALI', value: 'SOMALI' },
    { label: 'SLAVIC', value: 'SLAVIC' },
    { label: 'SERANI', value: 'SERANI' },
    { label: 'SUNDANESE', value: 'SUNDANESE' },
    { label: 'SUDANESE', value: 'SUDANESE' },
    { label: 'SWEDISH', value: 'SWEDISH' },
    { label: 'SEYCHELLOIS', value: 'SEYCHELLOIS' },
    { label: 'TELUGU', value: 'TELUGU' },
    { label: 'THAI', value: 'THAI' },
    { label: 'TIBETAN', value: 'TIBETAN' },
    { label: 'TAJIK', value: 'TAJIK' },
    { label: 'TAMIL', value: 'TAMIL' },
    { label: 'TURKMEN', value: 'TURKMEN' },
    { label: 'TONGAN', value: 'TONGAN' },
    { label: 'TIMOR', value: 'TIMOR' },
    { label: 'TURK', value: 'TURK' },
    { label: 'UNKNOWN', value: 'UNKNOWN' },
    { label: 'UKRAINIAN', value: 'UKRAINIAN' },
    { label: 'AMERICAN', value: 'AMERICAN' },
    { label: 'UYGHUR', value: 'UYGHUR' },
    { label: 'UZBEK', value: 'UZBEK' },
    { label: 'VENEZUELAN', value: 'VENEZUELAN' },
    { label: 'VIETNAMESE', value: 'VIETNAMESE' },
    { label: 'WELSH', value: 'WELSH' },
    { label: 'OTHER INDONESIAN', value: 'OTHER INDONESIAN' },
    { label: 'OTHER EURASIAN', value: 'OTHER EURASIAN' },
    { label: 'OTHER INDIAN', value: 'OTHER INDIAN' },
    { label: 'OTHERS', value: 'OTHERS' },
    { label: 'YUGOSLAV', value: 'YUGOSLAV' },
    { label: 'ZIMBABWEAN', value: 'ZIMBABWEAN' }
];

export const residency = [
    { label: 'Employment Pass', value: 'Employment Pass' },
    { label: 'Permanent Resident', value: 'Permanent Resident' },
    { label: 'Citizen', value: 'Citizen' },
    { label: 'Other', value: 'Other' }
];

export const housing = [
    { label: 'DETACHED HOUSE', value: 'DETACHED HOUSE' },
    { label: 'SEMI-DETACHED HOUSE', value: 'SEMI-DETACHED HOUSE' },
    { label: 'TERRACE HOUSE', value: 'TERRACE HOUSE' },
    { label: 'CONDOMINIUM', value: 'CONDOMINIUM' },
    { label: 'EXECUTIVE CONDOMINIUM', value: 'EXECUTIVE CONDOMINIUM' },
    { label: 'APARTMENT', value: 'APARTMENT' }
];

export const passType = [
    { label: 'Work Permit', value: 'Work Permit' },
    { label: 'S Pass', value: 'S Pass' },
    { label: 'Employment Pass', value: 'Employment Pass' },
    { label: 'Personalised Employment Pass', value: 'Personalised Employment Pass' },
    { label: 'Work Holiday Pass', value: 'Work Holiday Pass' },
    { label: 'Training Employment Pass', value: 'Training Employment Pass' },
    { label: 'EntrePass', value: 'EntrePass' },
    { label: 'Overseas Networks & Expertise Pass', value: 'Overseas Networks & Expertise Pass' },
    { label: 'Dependent Pass', value: 'Dependent Pass' },
    { label: 'Long Term Visit Pass', value: 'Long Term Visit Pass' },
    { label: 'Letter of Consent', value: 'Letter of Consent' },
    { label: 'Miscellaneous Work Pass', value: 'Miscellaneous Work Pass' },
    { label: 'Student Pass', value: 'Student Pass' },
    { label: 'Long Term Visit Pass +', value: 'Long Term Visit Pass +' },
    { label: 'Immigration Exemption Order', value: 'Immigration Exemption Order' }
];

export const occupation = [
    { label: 'SINGAPORE TOURISM BOARD', value: 'SINGAPORE TOURISM BOARD' },
    { label: 'ECONOMIC DEVELOPMENT BOARD', value: 'ECONOMIC DEVELOPMENT BOARD' },
    { label: 'INFO-COMMUNICATIONS MEDIA DEVELOPMENT AUTHORITY', value: 'INFO-COMMUNICATIONS MEDIA DEVELOPMENT AUTHORITY' },
    { label: 'MINISTRY OF TRADE AND INDUSTRY', value: 'MINISTRY OF TRADE AND INDUSTRY' },
    { label: 'MINISTRY OF HOME AFFAIRS', value: 'MINISTRY OF HOME AFFAIRS' },
    { label: 'SINGAPORE PHARMACY COUNCIL', value: 'SINGAPORE PHARMACY COUNCIL' },
    { label: 'GOVERNMENT TECHNOLOGY AGENCY', value: 'GOVERNMENT TECHNOLOGY AGENCY' },
    { label: 'SINGAPORE POLICE FORCE', value: 'SINGAPORE POLICE FORCE' },
    { label: 'SINGAPORE LAND AUTHORITY', value: 'SINGAPORE LAND AUTHORITY' },
    { label: 'SINGAPORE LABOUR FOUNDATION', value: 'SINGAPORE LABOUR FOUNDATION' },
    { label: 'CIVIL AVIATION AUTHORITY OF SINGAPORE', value: 'CIVIL AVIATION AUTHORITY OF SINGAPORE' },
    { label: 'TCM PRACTITIONERS BOARD', value: 'TCM PRACTITIONERS BOARD' },
    { label: 'SINGAPORE DENTAL COUNCIL', value: 'SINGAPORE DENTAL COUNCIL' },
    { label: 'BOARD OF ARCHITECTS', value: 'BOARD OF ARCHITECTS' },
    { label: 'HEALTH SCIENCES AUTHORITY', value: 'HEALTH SCIENCES AUTHORITY' },
    { label: 'ENERGY MARKET AUTHORITY', value: 'ENERGY MARKET AUTHORITY' },
    { label: 'LAND SURVEYORS BOARD', value: 'LAND SURVEYORS BOARD' },
    { label: 'DEFENCE SCIENCE AND TECHNOLOGY AGENCY', value: 'DEFENCE SCIENCE AND TECHNOLOGY AGENCY' },
    { label: 'COUNCIL FOR ESTATE AGENCIES', value: 'COUNCIL FOR ESTATE AGENCIES' },
    { label: 'MINISTRY OF LAW', value: 'MINISTRY OF LAW' },
    { label: 'BUILDING AND CONSTRUCTION AUTHORITY', value: 'BUILDING AND CONSTRUCTION AUTHORITY' },
    { label: 'PUBLIC SERVICE COMMISSION', value: 'PUBLIC SERVICE COMMISSION' },
    { label: 'THE CABINET', value: 'THE CABINET' },
    { label: 'MINISTRY OF CULTURE, COMMUNITY AND YOUTH', value: 'MINISTRY OF CULTURE, COMMUNITY AND YOUTH' },
    { label: 'SINGAPORE MEDICAL COUNCIL', value: 'SINGAPORE MEDICAL COUNCIL' },
    { label: 'INDUSTRIAL ARBITRATION COURT', value: 'INDUSTRIAL ARBITRATION COURT' },
    { label: 'AGRI-FOOD AND VETERINARY AUTHORITY OF SINGAPORE', value: 'AGRI-FOOD AND VETERINARY AUTHORITY OF SINGAPORE' },
    { label: 'JUDICIARY, SUPREME COURT', value: 'JUDICIARY, SUPREME COURT' },
    { label: 'CASINO REGULATORY AUTHORITY OF SINGAPORE', value: 'CASINO REGULATORY AUTHORITY OF SINGAPORE' },
    { label: 'SINGAPORE ACCOUNTANCY COMMISSION', value: 'SINGAPORE ACCOUNTANCY COMMISSION' },
    { label: 'REGISTRY OF SOCIETIES', value: 'REGISTRY OF SOCIETIES' },
    { label: 'JTC CORPORATION', value: 'JTC CORPORATION' },
    { label: 'LAND TRANSPORT AUTHORITY', value: 'LAND TRANSPORT AUTHORITY' },
    { label: 'INLAND REVENUE AUTHORITY OF SINGAPORE', value: 'INLAND REVENUE AUTHORITY OF SINGAPORE' },
    { label: 'AGENCY FOR SCIENCE, TECHNOLOGY AND RESEARCH', value: 'AGENCY FOR SCIENCE, TECHNOLOGY AND RESEARCH' },
    { label: 'HEALTH PROMOTION BOARD', value: 'HEALTH PROMOTION BOARD' },
    { label: 'NATIONAL PARKS BOARD', value: 'NATIONAL PARKS BOARD' },
    { label: 'SPORT SINGAPORE', value: 'SPORT SINGAPORE' },
    { label: 'MONETARY AUTHORITY OF SINGAPORE', value: 'MONETARY AUTHORITY OF SINGAPORE' },
    { label: 'PUBLIC TRANSPORT COUNCIL', value: 'PUBLIC TRANSPORT COUNCIL' },
    { label: 'REPUBLIC POLYTECHNIC', value: 'REPUBLIC POLYTECHNIC' },
    { label: 'HOTELS LICENSING BOARD', value: 'HOTELS LICENSING BOARD' },
    { label: 'ISTANA', value: 'ISTANA' },
    { label: 'MINISTRY OF NATIONAL DEVELOPMENT', value: 'MINISTRY OF NATIONAL DEVELOPMENT' },
    { label: 'NGEE ANN POLYTECHNIC', value: 'NGEE ANN POLYTECHNIC' },
    { label: 'SINGAPORE CORPORATION OF REHABILITATIVE ENTERPRISES', value: 'SINGAPORE CORPORATION OF REHABILITATIVE ENTERPRISES' },
    { label: 'NATIONAL LIBRARY BOARD', value: 'NATIONAL LIBRARY BOARD' },
    { label: 'URBAN REDEVELOPMENT AUTHORITY', value: 'URBAN REDEVELOPMENT AUTHORITY' },
    { label: 'SINGAPORE NURSING BOARD', value: 'SINGAPORE NURSING BOARD' },
    { label: 'HOUSING & DEVELOPMENT BOARD', value: 'HOUSING & DEVELOPMENT BOARD' },
    { label: 'SMART NATION AND DIGITAL GOVERNMENT', value: 'SMART NATION AND DIGITAL GOVERNMENT' },
    { label: 'MINISTRY OF FOREIGN AFFAIRS', value: 'MINISTRY OF FOREIGN AFFAIRS' },
    { label: 'NATIONAL HERITAGE BOARD', value: 'NATIONAL HERITAGE BOARD' },
    { label: 'PARLIAMENT OF SINGAPORE', value: 'PARLIAMENT OF SINGAPORE' },
    { label: 'ACCOUNTING AND CORPORATE REGULATORY AUTHORITY', value: 'ACCOUNTING AND CORPORATE REGULATORY AUTHORITY' },
    { label: 'COMPETITION AND CONSUMER COMMISSION OF SINGAPORE', value: 'COMPETITION AND CONSUMER COMMISSION OF SINGAPORE' },
    { label: 'SINGAPORE FOOD AGENCY', value: 'SINGAPORE FOOD AGENCY' },
    { label: 'CIVIL SERVICE COLLEGE', value: 'CIVIL SERVICE COLLEGE' },
    { label: 'SINGAPORE EXAMINATIONS AND ASSESSMENT BOARD', value: 'SINGAPORE EXAMINATIONS AND ASSESSMENT BOARD' },
    { label: 'MINISTRY OF THE ENVIRONMENT AND WATER RESOURCES', value: 'MINISTRY OF THE ENVIRONMENT AND WATER RESOURCES' },
    { label: 'ENTERPRISE SINGAPORE', value: 'ENTERPRISE SINGAPORE' },
    { label: 'TOTE BOARD', value: 'TOTE BOARD' },
    { label: 'PUB, Singapore\'s National Water Agency', value: 'PUB, Singapore\'s National Water Agency' },
    { label: 'JUDICIARY, STATE COURTS', value: 'JUDICIARY, STATE COURTS' },
    { label: 'ATTORNEY-GENERAL\'S CHAMBERS', value: 'ATTORNEY-GENERAL\'S CHAMBERS' },
    { label: 'SINGAPORE POLYTECHNIC', value: 'SINGAPORE POLYTECHNIC' },
    { label: 'MINISTRY OF SOCIAL AND FAMILY DEVELOPMENT', value: 'MINISTRY OF SOCIAL AND FAMILY DEVELOPMENT' },
    { label: 'MINISTRY OF EDUCATION', value: 'MINISTRY OF EDUCATION' },
    { label: 'NANYANG POLYTECHNIC', value: 'NANYANG POLYTECHNIC' },
    { label: 'MINISTRY OF FINANCE', value: 'MINISTRY OF FINANCE' },
    { label: 'ISEAS-YUSOF ISHAK INSTITUTE', value: 'ISEAS-YUSOF ISHAK INSTITUTE' },
    { label: 'SKILLSFUTURE SINGAPORE', value: 'SKILLSFUTURE SINGAPORE' },
    { label: 'MINISTRY OF HEALTH', value: 'MINISTRY OF HEALTH' },
    { label: 'AUDITOR-GENERAL\'S OFFICE', value: 'AUDITOR-GENERAL\'S OFFICE' },
    { label: 'INTELLECTUAL PROPERTY OFFICE OF SINGAPORE', value: 'INTELLECTUAL PROPERTY OFFICE OF SINGAPORE' },
    { label: 'MINISTRY OF MANPOWER', value: 'MINISTRY OF MANPOWER' },
    { label: 'WORKFORCE SINGAPORE', value: 'WORKFORCE SINGAPORE' },
    { label: 'NATIONAL ENVIRONMENT AGENCY', value: 'NATIONAL ENVIRONMENT AGENCY' },
    { label: 'PROFESSIONAL ENGINEERS BOARD, SINGAPORE', value: 'PROFESSIONAL ENGINEERS BOARD, SINGAPORE' },
    { label: 'NATIONAL ARTS COUNCIL', value: 'NATIONAL ARTS COUNCIL' },
    { label: 'NATIONAL COUNCIL OF SOCIAL SERVICE', value: 'NATIONAL COUNCIL OF SOCIAL SERVICE' },
    { label: 'PEOPLE\'S ASSOCIATION', value: 'PEOPLE\'S ASSOCIATION' },
    { label: 'MINISTRY OF COMMUNICATIONS AND INFORMATION', value: 'MINISTRY OF COMMUNICATIONS AND INFORMATION' },
    { label: 'MINISTRY OF TRANSPORT', value: 'MINISTRY OF TRANSPORT' },
    { label: 'PRIME MINISTER\'S OFFICE', value: 'PRIME MINISTER\'S OFFICE' },
    { label: 'SCIENCE CENTRE BOARD', value: 'SCIENCE CENTRE BOARD' },
    { label: 'CENTRAL PROVIDENT FUND BOARD', value: 'CENTRAL PROVIDENT FUND BOARD' },
    { label: 'MAJLIS UGAMA ISLAM, SINGAPURA', value: 'MAJLIS UGAMA ISLAM, SINGAPURA' },
    { label: 'JUDICIARY, FAMILY JUSTICE COURTS', value: 'JUDICIARY, FAMILY JUSTICE COURTS' },
    { label: 'INSTITUTE OF TECHNICAL EDUCATION', value: 'INSTITUTE OF TECHNICAL EDUCATION' },
    { label: 'TEMASEK POLYTECHNIC', value: 'TEMASEK POLYTECHNIC' },
    { label: 'MINISTRY OF DEFENCE', value: 'MINISTRY OF DEFENCE' },
    { label: 'MARITIME AND PORT AUTHORITY OF SINGAPORE', value: 'MARITIME AND PORT AUTHORITY OF SINGAPORE' }
];

export const incomeNoa = [
    { label: 'Less than $25,000', value: 'Less than $25,000' },
    { label: 'From $25,000 to $ 50,000', value: 'From $25,000 to $ 50,000' },
    { label: 'From $ 50,000 to $80,000', value: 'From $ 50,000 to $80,000' },
    { label: 'More than $80,000', value: 'More than $80,000' }
];

export const employmentSector = [
    { label: 'Private', value: 'Private' },
    { label: 'Public', value: 'Public' },
    { label: 'Unknown', value: 'Unknown' }
];

export const samplePostCode = [
    { label: '18906', value: '18906' },
    { label: '18907', value: '18907' },
    { label: '18910', value: '18910' },
    { label: '18915', value: '18915' },
    { label: '18916', value: '18916' },
    { label: '18925', value: '18925' },
    { label: '18926', value: '18926' },
    { label: '18927', value: '18927' },
    { label: '18928', value: '18928' },
    { label: '18929', value: '18929' },
    { label: '18930', value: '18930' },
    { label: '18935', value: '18935' },
    { label: '18936', value: '18936' },
    { label: '18937', value: '18937' },
    { label: '18940', value: '18940' },
    { label: '18946', value: '18946' },
    { label: '18947', value: '18947' },
    { label: '18948', value: '18948' },
    { label: '18951', value: '18951' },
    { label: '18953', value: '18953' },
    { label: '18955', value: '18955' },
    { label: '18956', value: '18956' },
    { label: '18957', value: '18957' },
    { label: '18958', value: '18958' },
    { label: '18959', value: '18959' },
    { label: '18960', value: '18960' },
    { label: '18961', value: '18961' },
    { label: '18962', value: '18962' },
    { label: '18965', value: '18965' },
    { label: '18969', value: '18969' },
    { label: '18971', value: '18971' },
    { label: '18972', value: '18972' },
    { label: '18973', value: '18973' },
    { label: '18974', value: '18974' },
    { label: '18978', value: '18978' },
    { label: '18979', value: '18979' },
    { label: '18980', value: '18980' },
    { label: '18981', value: '18981' },
    { label: '18982', value: '18982' }
];
