import React from 'react';
import PropTypes from 'prop-types';
import style from './FormInput.module.scss';
import InputPhone from 'containers/App/components/InputPhone';
import './phone.scss';
import NumberInput from 'containers/App/components/NumberInput/NumberInput';
import { ReactComponent as Icon } from 'assets/images/general/exclamation.svg';

FormInput.propTypes = {
    label: PropTypes.string,
    desc: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    placeHolder: PropTypes.string,
    maxLength: PropTypes.number
};

function FormInput({
    label,
    desc,
    type,
    value,
    onChange,
    name,
    error,
    errorMsg,
    placeHolder,
    maxLength
}) {
    return (
        <div className={!desc ? style.FormInput : style.FormInput + ' ' + style.FormInputDesc} data-name={name}>
            <label
                className={style.FormInput__label}>
                {label}
            </label>
            {desc &&
            <div
                className={style.FormInput__desc}>
                {desc}
            </div>
            }
            {type === 'phone'
                ? <InputPhone
                    countryCodeEditable={false}
                    disableDropdown={true}
                    containerClass={!error ? 'FormInputPhone' : 'FormInputPhone FormInputPhone__error'}
                    country={'sg'}
                    phone={value}
                    setPhone={e => onChange({ target: { name, value: e } })}
                    onlyCountries={['sg']}/>
                : type === 'number'
                    ? <NumberInput
                        maxLength={maxLength}
                        name={name}
                        placeholder={placeHolder || 'Type your answer here'}
                        className={!error ? style.FormInput__input : style.FormInput__input + ' ' + style.FormInput__inputError}
                        value={value}
                        onChange={e => onChange({ target: { name, value: e } })}/>
                    : <input
                        name={name}
                        placeholder={placeHolder || 'Type your answer here'}
                        type={type}
                        className={!error ? style.FormInput__input : style.FormInput__input + ' ' + style.FormInput__inputError}
                        value={value}
                        onChange={onChange} />
            }
            {error &&
            <div className={style.FormInput__error}>
                <Icon />
                {errorMsg}
            </div>
            }
        </div>
    );
}

export default FormInput;
