import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'underscore';
import { calculateTradeIn } from 'api/trade_in';
import { notify, notifType } from 'utils/notifSender';
import { STORAGEKEY_TRADE_IN_DEVICE, STORAGEKEY_TRADE_IN_PLACED } from 'config/constants';
import TradeInExplanationModal from 'screens/Product/elements/TradeIn/TradeInExplanationModal';
import SeeTheMathModal from 'screens/Product/elements/TradeIn/SeeTheMathModal';
import { orderTotal } from 'redux/reducers/order';
import { tradeInDiscountedPrice } from 'redux/reducers/common';

import styles from './AfterTradeIn.module.scss';
import Trash from 'assets/images/general/trash.svg';
import ParallelArrows from 'assets/images/general/parallel-arrows.svg';

const AfterTradeIn = ({ orderedCart, metaTotal, showCreditOnly, showTableOnly, reducedMonthlyPriceOnly }) => {
    const dispatch = useDispatch();
    const { selectedCartItem, meta, products } = useSelector(state => ({
        selectedCartItem: state.cart.selectedCartItem,
        meta: state.cart.overview,
        products: state.cart.fetchedProducts
    }));

    const total_ = selectedCartItem?.total || orderedCart && orderedCart[0]?.total;
    const discount = meta?.discount;
    const subtotal = selectedCartItem?.total || orderedCart && orderedCart[0]?.total;
    const total = metaTotal ? Number(metaTotal).toFixed(2) : parseFloat(total_ - discount).toFixed(2);
    const durationTime = selectedCartItem?.rental_period?.duration_time || orderedCart && orderedCart[0]?.rental_period?.duration_time;
    const [tradeInDevice, setTradeInDevice] = useState();
    const [tradeInCalculationValues, setTradeInCalculationValues] = useState();

    const retrieveTradeInDevice = () => {
        const tradeInDeviceLocalStorage = localStorage.getItem(STORAGEKEY_TRADE_IN_DEVICE);
        const tradeInPlacedLocalStorage = localStorage.getItem(STORAGEKEY_TRADE_IN_PLACED);

        if (!tradeInDeviceLocalStorage && !tradeInPlacedLocalStorage) return;

        setTradeInDevice(JSON.parse(orderedCart ? tradeInPlacedLocalStorage : tradeInDeviceLocalStorage));
    };

    const removeTradeInDevice = () => {
        setTradeInDevice(undefined);
        localStorage.removeItem(STORAGEKEY_TRADE_IN_DEVICE);
        dispatch(tradeInDiscountedPrice(undefined));
    };

    const getTradeInValue = async () => {
        const payload = {
            subtotal: subtotal,
            total: total,
            trade_in_quote: tradeInDevice?.estimates.total,
            rental_period_duration: durationTime
        };

        if (Object.values(payload).some((value) => isNaN(Number(value)))) return;

        const data = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            data.append(key, Number(value));
        });

        await calculateTradeIn(data)
            .then((res) => setTradeInCalculationValues(res))
            .catch((err) => {
                console.error('Error calculating trade-in:', err);
                notify('Error! Something went wrong, please try again!', notifType.DANGER);
            });
    };

    const wrapperStyles = () => {
        // eslint-disable-next-line
        let classes = [styles.afterTradeInWrapper];

        if (orderedCart) classes.push(styles.orderSummaryConfirmation);

        return classes.join(' ');
    };

    const updateOrderPrice = (value) => {
        dispatch(orderTotal(value));
        dispatch(tradeInDiscountedPrice(value));
    };

    useEffect(() => {
        retrieveTradeInDevice();
    }, []);

    useEffect(() => {
        if (!total || !subtotal || !tradeInDevice || !durationTime) return;
        getTradeInValue();
    }, [total, discount, tradeInDevice, durationTime]);

    useEffect(() => {
        updateOrderPrice(tradeInCalculationValues?.reducedMonthlyPrice?.toFixed(2));
    }, [tradeInCalculationValues]);

    if (!tradeInDevice) return null;

    if (tradeInCalculationValues) {
        if (reducedMonthlyPriceOnly) return tradeInCalculationValues?.reducedMonthlyPrice?.toFixed(2);

        if (showCreditOnly) {
            return (
                <div className={`${styles.afterTradeIn} w-100`}>
                    <Table borderless={true} size='sm'>
                        <tbody>
                            {tradeInCalculationValues?.cinchCashAmount > 0 && (
                                <tr>
                                    <td className='d-flex align-items-center gap-1'>
                                        <span>Cinch Credit</span>
                                        <TradeInExplanationModal cinchCreditsOnly={true} />
                                    </td>
                                    <td>+ SGD {tradeInCalculationValues?.cinchCashAmount?.toFixed(2)}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            );
        }

        if (showTableOnly) {
            return (
                <div className={styles.afterTradeIn}>
                    <div className={styles.title}>
                        <h5>After Trade-In*</h5>
                        <TradeInExplanationModal />
                    </div>
                    <strong>{tradeInDevice.device.name}</strong>
                    <Table borderless={true} size='sm'>
                        <tbody>
                            <tr>
                                <td>Estimated Trade-In Value</td>
                                <td>SGD {tradeInDevice?.estimates.total.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Final rate</td>
                                <td className={styles.newRate}>SGD {tradeInCalculationValues?.reducedMonthlyPrice?.toFixed(2)}/mo</td>
                            </tr>
                            {tradeInCalculationValues?.cinchCashAmount > 0 && (
                                <tr>
                                    <td className='d-flex align-items-center gap-1'>
                                        <span>Cinch Credit</span>
                                        <TradeInExplanationModal cinchCreditsOnly={true} />
                                    </td>
                                    <td>+ SGD {tradeInCalculationValues?.cinchCashAmount?.toFixed(2)}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <small>*The first month is billed at the original subscription rate. When trade-in is complete, the new subscription rate will apply, and you will be credited Cinch Credit if applicable.</small>
                </div>
            );
        }
    }

    return (
        <div className={`${wrapperStyles()} mt-0 ${!orderedCart ? 'd-block' : ''}`}>
            {orderedCart && <img src={ParallelArrows} alt='Trade in' className={styles.tradeInIcon} />}
            <div className={styles.afterTradeIn}>
                <div className={styles.title}>
                    <h4 className={!orderedCart ? styles.tradeInApplied : ''}>{orderedCart ? 'After Trade-in*' : 'Trade-In Applied'}</h4>
                    {orderedCart
                        ? <TradeInExplanationModal />
                        : <img src={Trash} className={styles.removeTradeInIcon} onClick={() => { removeTradeInDevice(); }}
                            alt='Remove Trade-In' />}
                </div>
                <div className={styles.deviceInfo}>
                    <span>{tradeInDevice.device.name}</span>
                    <span className={styles.deviceInfo__price}>SGD {tradeInDevice?.estimates.total.toFixed(2)}</span>
                </div>
                {orderedCart &&
                    <Table borderless={true} size='sm'>
                        <tbody>
                            <tr>
                                <td>Estimated Trade-In Value</td>
                                <td>SGD {tradeInDevice?.estimates.total.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Final rate</td>
                                <td className={styles.newRate}>SGD {tradeInCalculationValues?.reducedMonthlyPrice?.toFixed(2)}/mo</td>
                            </tr>
                            {tradeInCalculationValues?.cinchCashAmount > 0 && (
                                <tr>
                                    <td className='d-flex align-items-center gap-1'>
                                        <span>Cinch Credit</span>
                                        <TradeInExplanationModal cinchCreditsOnly={true} />
                                    </td>
                                    <td>+ SGD {tradeInCalculationValues?.cinchCashAmount?.toFixed(2)}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>}
                <small>When the trade-in is complete, the new subscription rate will be applied and you will be credited Cinch Credit if applicable.</small>
                {orderedCart && <div>
                    <SeeTheMathModal tradeInDevice={tradeInDevice} total={total} subtotal={subtotal}
                        durationTime={durationTime} />
                </div>}
            </div>
        </div>
    );
};

AfterTradeIn.propTypes = {
    orderedCart: PropTypes.object,
    metaTotal: PropTypes.string,
    showCreditOnly: PropTypes.bool,
    showTableOnly: PropTypes.bool,
    reducedMonthlyPriceOnly: PropTypes.bool
};

export default AfterTradeIn;
