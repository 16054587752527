import React from 'react';
import PropTypes from 'prop-types';

import Button from 'containers/App/components/Button/Button';

import './StickyBottom.scss';

export default function StickyBottom({
    actionBtnLabel = 'Submit',
    actionBtnDisabled,
    onSubmit,
    showBackBtn = true,
    onBack,
    isLoading
}) {
    return (
        <>
            <div className='StickyBottom'>
                <div className='StickyBottom__cta_buttons'>
                    {showBackBtn && <Button
                        title='Back'
                        onClick={() => onBack()} />}
                    <Button
                        title={actionBtnLabel}
                        type='submit'
                        disabled={actionBtnDisabled}
                        onClick={() => onSubmit()}
                        loading={isLoading} />
                </div>
            </div>
        </>
    );
}

StickyBottom.propTypes = {
    actionBtnLabel: PropTypes.string,
    actionBtnDisabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    onBack: PropTypes.func,
    isLoading: PropTypes.bool,
    showBackBtn: PropTypes.bool
};
