import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StickyBottom from 'components/StickyBottom/StickyBottom';
import Button from 'containers/App/components/Button/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import googIcon from 'assets/images/general/google_icon.png';
import facebookIcon from 'assets/images/general/facebook_icon.svg';

import style from './InitialLoginForm.module.scss';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#AAA'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#AAA'
        },
        '&:hover fieldset': {
            borderColor: '#AAA'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#AAA'
        }
    }
});

const InitialLoginForm = ({
    containerClass,
    handleSocialLogin,
    setEmailId,
    checkEmailAvailability,
    onBack,
    loading,
    fromPopup
}) => {
    const [emailError, setEmailError] = useState(true);
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;

    const onEmailChange = (e) => {
        if (e.target.value === '') {
            setEmailError(true);

            return;
        }

        setEmailError(false);
        setEmailId(e.target.value);
    };

    return (
        <>
            <div className={`${style.container} ${containerClass}`}>
                <h2 className={style.title}>Welcome to Cinch</h2>
                <p className={style.desc}>To get started, please enter your email or sign in with Google or Facebook.</p>
                <div className={style.socialButtons}>
                    <button onClick={() => handleSocialLogin('google')} className={style.socialButton}>
                        <img src={googIcon} alt='Google' />
                        Sign in with Google
                    </button>
                    <button onClick={() => handleSocialLogin('facebook')} className={style.socialButton}>
                        <img src={facebookIcon} alt='Facebook' />
                        Sign in with Facebook
                    </button>
                </div>
                <div className={style.separator}>
                    <span className={style.separator__line} />
                    <span className={style.separator__text}>Or</span>
                    <span className={style.separator__line} />
                </div>
                <div className={style.inputWrapper}>
                    <CssTextField
                        type='email'
                        label='Email*'
                        onChange={(e) => onEmailChange(e)}
                        variant='standard'
                        className={style.inputWrapper__email}/>
                </div>
                {!isMobile && !fromPopup &&
                    <div className={style.actionButtons}>
                        <Button
                            type='button'
                            title={'Back'}
                            onClick={onBack}
                            className={style.actionButtons__back} />
                        <Button
                            type='submit'
                            title={'Continue'}
                            className={style.actionButtons__submit}
                            onClick={checkEmailAvailability}
                            loading={loading}
                            disabled={emailError} />
                    </div>
                }
                {fromPopup &&
                    <Button
                        loading={loading}
                        type='submit'
                        title={'Continue'}
                        onClick={checkEmailAvailability}
                        disabled={emailError}
                        className={`${style.actionButtons__submit} ${style.actionButtons__modal_button}`}/>
                }
            </div>
            {!fromPopup &&
                <StickyBottom
                    actionBtnLabel='Continue'
                    actionBtnDisabled={emailError}
                    onSubmit={checkEmailAvailability}
                    onBack={onBack}
                    isLoading={loading} />}
        </>
    );
};

InitialLoginForm.propTypes = {
    containerClass: PropTypes.string,
    handleSocialLogin: PropTypes.func.isRequired,
    setEmailId: PropTypes.func.isRequired,
    checkEmailAvailability: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    fromPopup: PropTypes.bool
};

export default InitialLoginForm;
