import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import AuthForm from 'containers/App/components/AuthForm/AuthForm';

import close from 'assets/images/general/close.svg';

import style from './LoginModal.module.scss';

LoginModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func
};

function LoginModal({
    isOpen,
    onToggle
}) {
    return (
        <>
            <Modal
                isOpen={isOpen}
                className={style.modal}
                toggle={onToggle}
                backdrop={true}>
                <div className={style.section}>
                    <div className='w-100 d-flex justify-content-end'>
                        <img className={style.close} src={close}
                            onClick={() => { onToggle(); }}
                            alt='Close' />
                    </div>
                    <AuthForm fromPopup={true}/>
                </div>
            </Modal>
        </>
    );
}

export default LoginModal;
