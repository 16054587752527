import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'reactstrap';
import { toggleInvoiceModal } from 'redux/reducers/auth';
import style from './InvoiceModal.module.scss';
import close from 'assets/images/general/close.svg';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config/constants';
import { getLastPageUrlWithoutAuth } from 'utils/helpers';

const InvoiceModal = () => {
    const isOpen = useSelector((state) => state.auth.isInvoiceModalOpen);
    const invoiceUrl = useSelector((state) => state.auth.invoiceUrl);
    const orderNumber = useSelector((state) => state.auth.orderNumber);
    const productId = useSelector((state) => state.auth.productId);
    const dispatch = useDispatch();
    const history = useHistory();

    if (!isOpen) return null;

    const handleClose = () => {
        dispatch(toggleInvoiceModal(false));
        history.push(getLastPageUrlWithoutAuth());
    };

    const viewInvoice = (productId, orderNumber, invoiceUrl) => {
        dispatch(toggleInvoiceModal(false));

        if (invoiceUrl) {
            window.open(invoiceUrl);
            history.push(getLastPageUrlWithoutAuth());
        } else {
            history.push(ROUTES.ORDEREDPRODUCT(productId, orderNumber));
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} className={style.modal}>
                <div className={style.section}>
                    <div className={style.header}>
                        <div className={style.header__title}>
                            Payment Pending
                        </div>
                        <div className={style.header__close}>
                            <img className={style.header__close__img}
                                onClick={handleClose}
                                src={close}
                                alt='close' />
                        </div>
                    </div>
                    <div className={style.body}>
                        *There is an <b className={style.unpaid_invoice}>unpaid balance</b> on your account.<br /><br />

                        To avoid any service interruptions, please settle any outstanding balances as soon as possible.<br />
                        If you are having difficulty paying, please contact our customer support team<br />
                        via Whatsapp at <b className={style.unpaid_invoice}>+65 8168-8604</b> or via email at <b className={style.unpaid_invoice}>support@cinch.sg</b>.<br /><br />

                        Thanks for your attention to this matter!
                    </div>
                    <div className={style.buttonContainer}>
                        <Button
                            type='button'
                            title='View Invoice'
                            className={style.button}
                            onClick={() => viewInvoice(productId, orderNumber, invoiceUrl)} >
                            View Invoice
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default InvoiceModal;
