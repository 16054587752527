import React from 'react';
import PropTypes from 'prop-types';

const CustomStepIcon = ({ completed, active, icon }) => {
    return (
        <div className='d-flex align-items-center'>
            {completed ? (
                <div className='CartLayout__stepper__step CartLayout__stepper__completed__step'>{icon}</div>
            ) : (
                active
                    ? <div className='CartLayout__stepper__step CartLayout__stepper__notcompleted__step CartLayout__stepper__activecompleted__step'>{icon}</div>
                    : <div className='CartLayout__stepper__step CartLayout__stepper__notcompleted__step'>{icon}</div>
            )}
        </div>
    );
};

CustomStepIcon.propTypes = {
    completed: PropTypes.bool,
    active: PropTypes.bool,
    icon: PropTypes.any
};

export default CustomStepIcon;
