import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from 'config/constants';

import Header from 'containers/App/components/Header/Header';

import { ReactComponent as CrossIcon } from 'assets/images/general/close.svg';

import './Layout.scss';

const Layout = ({ children, profile }) => {
    const history = useHistory();
    const [isOpenOnceSessionTimeout, setIsOpenOnceSessionTimeout] = useState(false);
    const { segment } = useSelector(state => ({ segment: state.common.segment }));
    const segmentQuery = segment ? `?segment=${segment}` : '';

    useEffect(() => {
        const storageKey = 'sk-authToken';

        const handleStorageChange = (event) => {
            if (event.key === storageKey) {
                if (window.location.pathname !== ROUTES.SIGN_UP || window.location.pathname !== ROUTES.SIGN_IN) {
                    setIsOpenOnceSessionTimeout(true);
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const triggerPageRedirect = () => {
        setIsOpenOnceSessionTimeout(false);

        if (
            (window.location.pathname === ROUTES.SINGPASS) ||
            (window.location.pathname === ROUTES.DOCUMENTSPROOF) ||
            (window.location.pathname === ROUTES.ORDER_SUMMARY) ||
            (window.location.pathname === ROUTES.MYINFORMATION) ||
            (window.location.pathname === ROUTES.SINGPASS_MANUALL_FORM) ||
            (window.location.pathname === ROUTES.SUBSCRIPTIONS)
        ) {
            history.push({ pathname: ROUTES.HOME + segmentQuery });
        }

        window.location.reload();
    };

    return (
        <div className='layout'>
            <Header profile={profile} />
            {children}
            <Modal
                size='sm'
                className='SessionTimeout__modal'
                isOpen={isOpenOnceSessionTimeout}
                centered={true}>
                <ModalHeader className='SessionTimeout__modal__header'>
                    <span className='SessionTimeout__modal__header__heading'>Session Timeout</span>

                    <button className='SessionTimeout__modal__close-btn' onClick={triggerPageRedirect}>
                        <CrossIcon />
                    </button>
                </ModalHeader>

                <ModalBody className='SessionTimeout__modal__body'>
                    <p>Your session has been time out! Please login again!</p>
                    <div className='SessionTimeout__modal__body__btn__container d-flex justify-content-end align-items-center'>
                        <button onClick={triggerPageRedirect}>
                            OK
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.any.isRequired,
    profile: PropTypes.object
};

export default Layout;
