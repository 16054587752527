import React from 'react';
import Picker from 'react-datepicker';
import PropTypes from 'prop-types';
import { ReactComponent as Icon } from 'assets/images/general/exclamation.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

export default function DatePicker({
    date,
    onChange,
    error,
    errorMsg,
    otherProps,
    label,
    classname,
    name
}) {
    return (<div className={!classname ? 'date-picker' : `${classname} date-picker`} data-name={name}>
        {label && label !== '' &&
        <div className='date-picker__label'>
            {label}
        </div>
        }

        <div className={!error ? 'date-picker__picker' : 'date-picker__picker date-picker__picker--error'}>
            <Picker
                {...otherProps}
                selected={date}
                onChange={onChange} />
        </div>

        {error &&
        <div className='date-picker__error'>
            <Icon />
            {errorMsg}
        </div>
        }

    </div>);
}

DatePicker.propTypes = {
    date: PropTypes.any,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    otherProps: PropTypes.object,
    label: PropTypes.string,
    classname: PropTypes.string,
    name: PropTypes.string
};
