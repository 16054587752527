import React, { useState, useMemo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { ROUTES, PDP_FLIP6 } from 'config/constants';

import CustomLinearProgress from 'containers/App/components/Loading/CustomLinearProgress';
import { AuthedRoute } from './components/RouteWrappers';
import Layout from '../Layout/Layout';
import CheckoutContainer from 'containers/Checkout/CheckoutContainer';
import Footer from '../Footer/Footer.js';

import {
    HomeContainer,
    ProductsContainer,
    PageNotFoundContainer,
    ProductContainer,
    OrderedProductContainer,
    CartContainer,
    OrderSummaryContainer,
    AboutUsContainer,
    TermsAndConditionsContainer,
    FAQContainer,
    PrivacyPolicyContainer,
    SingPassContainer,
    MyInformationContainer,
    OrdersContainer,
    KnoxDisclaimerContainer,
    SubscriptionsContainer,
    SignInContainer,
    SignUpContainer,
    OtpVerificationContainer,
    ResetPasswordContainer,
    ReferAndEarnContainer,
    BuyingOrderSummaryContainer,
    PaymentInfoContainer,
    ShippingInfoContainer,
    OrderSummaryScreenConfirmationContainer,
    LuckyDrawContainer,
    TradeInCalculatorContainer,
    NafaContainer,
    WaitListContainer,
    ContactUsContainer,
    Q6B6Container,
    InvoiceContainer,
    SocialAuthHandlerContainer
} from './config/routes';

const AppRouter = ({ isAuthed, profile }) => {
    const [hideBottomSheetIfOpen, setHideBottomSheetIfOpen] = useState(false);
    const [sidePanelOpen] = useState(false);

    const layoutProps = useMemo(() => ({
        profile,
        stateForBottomSheet: hideBottomSheetIfOpen,
        sidePanelOpen
    }), [hideBottomSheetIfOpen, sidePanelOpen, profile]);

    return (
        <Suspense fallback={<CustomLinearProgress />}>
            <QueryParamProvider ReactRouterRoute={Route}>
                <Layout {...layoutProps}>
                    <Switch>
                        <Route
                            path={ROUTES.HOME}
                            exact={true}
                            render={props => (
                                <HomeContainer {...props} isAuthed={isAuthed} />
                            )} />
                        <Route
                            path={ROUTES.PRODUCTS}
                            exact={true}
                            render={props => (
                                <ProductsContainer {...props} isAuthed={isAuthed} />
                            )} />
                        <Route
                            path={`${ROUTES.PRODUCT.path}/:rental_term?`}
                            exact={true}
                            render={(props) => (
                                <ProductContainer {...props} isAuthed={isAuthed} />
                            )} />
                        <Route
                            path={ROUTES.ABOUT_US}
                            exact={true}
                            component={AboutUsContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.FAQ}
                            exact={true}
                            component={FAQContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.TERMS_AND_CONDITIONS}
                            exact={true}
                            component={TermsAndConditionsContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.PRIVACY_POLICY}
                            exact={true}
                            component={PrivacyPolicyContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.CHECKOUT}
                            exact={true}
                            component={(props) => (
                                <CheckoutContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.SIGNUP}
                            exact={true}
                            component={(props) => (
                                <CheckoutContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.CHECKOUT}
                            exact={true}
                            component={(props) => (
                                <CheckoutContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.SINGPASS}
                            exact={true}
                            component={(props) => (
                                <CheckoutContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.DOCUMENTSPROOF}
                            exact={true}
                            component={(props) => (
                                <CheckoutContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.SINGPASS_RESULT}
                            exact={true}
                            component={(props) => (
                                <CheckoutContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.SINGPASS_MANUALL_FORM}
                            exact={true}
                            component={(props) => (
                                <CheckoutContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.CART}
                            exact={true}
                            component={CartContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.KNOX_DISCLAIMER}
                            exact={true}
                            component={KnoxDisclaimerContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.ORDER_SUMMARY}
                            exact={true}
                            component={OrderSummaryContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.ORDER_SUMMARY_CONFIRMATION}
                            exact={true}
                            component={OrderSummaryScreenConfirmationContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.ORDEREDPRODUCT()}
                            exact={true}
                            component={OrderedProductContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.MYSINGPASS}
                            exact={true}
                            component={(props) => (
                                <SingPassContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.MYINFORMATION}
                            exact={true}
                            component={(props) => (
                                <MyInformationContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.ORDERS}
                            exact={true}
                            component={(props) => (
                                <OrdersContainer {...props} profile={profile} />
                            )}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.SUBSCRIPTIONS}
                            exact={true}
                            component={SubscriptionsContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.OTP_VERIFICATION}
                            exact={true}
                            component={OtpVerificationContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.REFER_AND_EARN}
                            exact={true}
                            component={ReferAndEarnContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.PAYMENTINFO}
                            exact={true}
                            component={PaymentInfoContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.SHIPPINGINFO}
                            exact={true}
                            component={ShippingInfoContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.PRODUCTORDERSUMMARY()}
                            exact={true}
                            component={BuyingOrderSummaryContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.INVOICE()}
                            exact={true}
                            component={InvoiceContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.SIGN_IN}
                            exact={true}
                            component={SignInContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.SIGN_UP}
                            exact={true}
                            component={SignUpContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.FORGOT_PASSWORD}
                            exact={true}
                            component={ResetPasswordContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.PRODUCTS}
                            exact={true}
                            component={ProductsContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.LUCKY_DRAW}
                            exact={true}
                            component={LuckyDrawContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.TRADE_IN_CALCULATOR}
                            exact={true}
                            component={TradeInCalculatorContainer} />
                        <Route
                            path={ROUTES.NAFA}
                            exact={true}
                            component={NafaContainer} />
                        <Route
                            path={ROUTES.UNPACKED}
                            exact={true}
                            component={WaitListContainer} />
                        <Route
                            path={ROUTES.CONTACT_US}
                            exact={true}
                            component={ContactUsContainer} />
                        <Route
                            path={ROUTES.Q6B6}
                            exact={true}
                            component={Q6B6Container}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.Q6B6_flip6}
                            exact={true}
                            component={Q6B6Container}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.Q6B6_fold6}
                            exact={true}
                            component={Q6B6Container}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.GALAXY_Z_SEM}
                            exact={true}
                            component={Q6B6Container}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.GALAXY_Z_AFF}
                            exact={true}
                            component={Q6B6Container}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.GALAXY_Z_EDM}
                            exact={true}
                            component={Q6B6Container}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.AUTH_CALLBACK_GOOGLE}
                            exact={true}
                            component={SocialAuthHandlerContainer} />
                        <Route
                            path={ROUTES.AUTH_CALLBACK_FACEBOOK}
                            exact={true}
                            component={SocialAuthHandlerContainer} />
                        <Route
                            path={ROUTES.GALAXY_Z_ND_FLASH_SALE}
                            exact={true}
                            render={() => {
                                window.location.href = PDP_FLIP6;

                                return null;
                            }}/>
                        <Route component={PageNotFoundContainer} />
                    </Switch>
                </Layout>
                <Footer />
            </QueryParamProvider>
        </Suspense>
    );
};

AppRouter.propTypes = {
    isAuthed: PropTypes.bool.isRequired,
    profile: PropTypes.object
};

export default AppRouter;
