import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { calculateTradeIn } from 'api/trade_in';
import { notify, notifType } from 'utils/notifSender';
import ArithmeticIcon from 'assets/images/Arithmetic Icon.png';
import styles from './SeeTheMathModal.module.scss';

const SeeTheMathModal = ({ tradeInDevice, total, subtotal, durationTime }) => {
    const [modal, setModal] = useState(false);
    const [tradeInCalculationValues, setTradeInCalculationValues] = useState();
    const monthlyDiscount = parseFloat(total - tradeInCalculationValues?.reducedMonthlyPrice)?.toFixed(2);
    const totalMonthlySubscriptionDiscount = parseFloat(monthlyDiscount * (durationTime - 1));

    const toggle = () => setModal(!modal);

    const getTradeInValue = async () => {
        const payload = {
            subtotal: subtotal,
            total: total,
            trade_in_quote: tradeInDevice?.estimates.total,
            rental_period_duration: durationTime
        };

        if (Object.values(payload).some((value) => isNaN(Number(value)))) return;

        const data = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            data.append(key, Number(value));
        });

        await calculateTradeIn(data)
            .then((res) => setTradeInCalculationValues(res))
            .catch((err) => {
                console.error('Error calculating trade-in:', err);
                notify('Error! Something went wrong, please try again!', notifType.DANGER);
            });
    };

    useEffect(() => {
        if (subtotal, total && tradeInDevice && durationTime) getTradeInValue();
    }, [subtotal, total, tradeInDevice, durationTime]);

    if (!tradeInCalculationValues || !tradeInDevice) return null;

    return (
        <>
            <Button color='link-dark' size='sm' onClick={toggle}
                className={styles.seeTheMath}>See the math</Button>
            <Modal isOpen={modal} toggle={toggle} >
                <ModalBody className={styles.modalBody}>
                    <Button close={true} onClick={toggle} className={styles.closeButton} />
                    <div className={styles.modalHeader}>
                        <img src={ArithmeticIcon} alt='Trade in' className={styles.tradeInIcon} />
                        <h4>Trade-in Calculator</h4>
                    </div>

                    <strong>{tradeInDevice.device.name}</strong>
                    <div>
                        <span>Estimated Trade-in Value*:</span>
                        <span className={styles.accent}>SGD {tradeInDevice.estimates.total.toFixed(2)}</span>
                    </div>
                    <hr />

                    <div>
                        <span>Monthly Subscription Discount:</span>
                        <span>SGD {monthlyDiscount}</span>
                    </div>
                    <small className='text-muted'>(Monthly Rate** - New Monthly Rate)</small>

                    <div>
                        <span>Total Subscription Discount:</span>
                        <span>SGD {totalMonthlySubscriptionDiscount.toFixed(2)}</span>
                    </div>
                    <small className='text-muted'>(Monthly Subscription Discount × {durationTime - 1} months)</small>

                    {tradeInCalculationValues?.cinchCashAmount > 0 && (
                        <div>
                            <span>Cinch Credit:</span>
                            <span>SGD {(tradeInCalculationValues.cinchCashAmount).toFixed(2)}</span>
                        </div>
                    )}
                    <hr />

                    <div>
                        <span>Total Discount + Credit:</span>
                        <span className={styles.accent}>SGD {(totalMonthlySubscriptionDiscount + tradeInCalculationValues.cinchCashAmount).toFixed(2)}</span>
                    </div>

                    <div className={styles.fineprint}>
                        <small className='text-muted'>*The estimated trade-in value is based upon the device information provided. The final value is subject to change based upon a device inspection.</small>
                        <small className='text-muted'>**The Monthly Rate is calculated after any applicable promotions are applied.</small>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='dark' onClick={toggle} block={true}
                        className={styles.buttonDismissButton}>
                        Okay
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

SeeTheMathModal.propTypes = {
    tradeInDevice: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired,
    durationTime: PropTypes.number.isRequired
};

export default SeeTheMathModal;
