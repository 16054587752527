import React from 'react';
import PropTypes from 'prop-types';

import FormInput from '../FormInput';
import FormFooter from '../FormFooter';
import FormSelcetion from '../FormSelection';
import { gender, martialStatus } from 'screens/SingpassManualForm/options';

import style from './Form.module.scss';

Second.propTypes = {
    errors: PropTypes.object.isRequired,
    setErrors: PropTypes.func.isRequired,
    secondForm: PropTypes.object.isRequired,
    setSecondForm: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    handlePrevStep: PropTypes.func.isRequired,
    errorMsgs: PropTypes.object
};

function Second({
    errors,
    setErrors,
    secondForm,
    setSecondForm,
    handleNextStep,
    handlePrevStep,
    errorMsgs
}) {
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setSecondForm(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: false }));
    };

    return (
        <div className={style.body}>
            <div>
                <div className={style.col}>
                    <FormSelcetion
                        name='sex'
                        value={secondForm.sex ? ({ label: secondForm.sex, value: secondForm.sex }) : null}
                        error={errors.sex}
                        errorMsg={errorMsgs.sex}
                        onChange={handleChange}
                        options={gender}
                        placeholder='Select an option'
                        label='Gender:'/>
                </div>
                <div className={style.col}>
                    <FormSelcetion
                        name='martial_status'
                        value={secondForm.martial_status ? ({ label: secondForm.martial_status, value: secondForm.martial_status }) : null}
                        errorMsg={errorMsgs.martial_status}
                        error={errors.martial_status}
                        onChange={handleChange}
                        options={martialStatus}
                        placeholder='Select an option'
                        label='Marital status:'/>
                </div>
                <div className={style.col}>
                    <FormInput
                        type='text'
                        name={'maried_name'}
                        errorMsg={errorMsgs.maried_name}
                        value={secondForm.maried_name}
                        error={errors.maried_name}
                        onChange={handleChange}
                        label='What is your marital name?' />
                </div>
                <div className={style.col}>
                    <FormInput
                        type='phone'
                        name={'mobile'}
                        errorMsg={errorMsgs.mobile}
                        error={errors.mobile}
                        value={secondForm.mobile}
                        onChange={handleChange}
                        label='Mobile number:' />
                </div>
                <div className={style.colEmail}>
                    <FormInput
                        type='email'
                        name={'email_address'}
                        errorMsg={errorMsgs.email_address}
                        error={errors.email_address}
                        value={secondForm.email_address}
                        onChange={handleChange}
                        placeHolder='Enter your email address'
                        label='Email address:' />
                </div>

                <div className={style.col}>
                    <FormFooter
                        prevLabel='Previous'
                        nextLabel='Next'
                        prevClick={handlePrevStep}
                        nextClick={handleNextStep}/>
                </div>
            </div>
        </div>
    );
}

export default Second;
