import { createAxiosInstance, getAuthToken } from 'utils/helpers';
import { trackKlaviyoEvent } from 'api/tracking';

const initialState = {
    address: {},
    otpInput: {},
    authData: {},
    isLoading: false,
    documents: null,
    supportingDocs: {
        studentIDCard: null,
        studentVisa: null,
        studentIDExpiryDate: null,
        studentVisaExpiryDate: null
    },
    guarantor: {
        guarantorName: '',
        guarantorRelation: '',
        guarantorEmail: '',
        guarantorMobile: ''
    },
    supportingDocsUploaded: false
};

const FETCH_ADDRESS_START = 'FETCH_ADDRESS_START';
const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS';
const FETCH_ADDRESS_FAILURE = 'FETCH_ADDRESS_FAILURE';

const CREATE_ADDRESS_START = 'CREATE_ADDRESS_START';
const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
const CREATE_ADDRESS_FAILURE = 'CREATE_ADDRESS_FAILURE';

const UPDATE_ADDRESS_START = 'UPDATE_ADDRESS_START';
const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

const UPLOAD_SUPPORT_DOCS_START = 'UPDATE_ADDRESS_START';
const UPLOAD_SUPPORT_DOCS_SUCCESS = 'UPLOAD_SUPPORT_DOCS_SUCCESS';
const UPLOAD_SUPPORT_DOCS_FAILURE = 'UPLOAD_SUPPORT_DOCS_FAILURE';

const UPDATE_GUARANTOR_INFORMATION = 'UPDATE_GUARANTOR_INFORMATION';
const UPDATE_GUARANTOR_INFORMATION_SUCCESS = 'UPDATE_GUARANTOR_INFORMATION_SUCCESS';
const UPDATE_GUARANTOR_INFORMATION_FAILURE = 'UPDATE_GUARANTOR_INFORMATION_FAILURE';

const UPDATE_USER_LOGIN_STATE_DATA = 'UPDATE_USER_LOGIN_STATE_DATA';

const UPDATE_USER_SUPPORTING_DOCUMENTS_DATA = 'UPDATE_USER_SUPPORTING_DOCUMENTS_DATA';

const UPDATE_GUARANTOR_INFORMATION_ON_CHECKOUT = 'UPDATE_GUARANTOR_INFORMATION_ON_CHECKOUT';

const UPDATE_OTP_INPUT_DATA = 'UPDATE_OTP_INPUT_DATA';

const UPDATE_USER_GURANTOR_INFORMATION_DATA = 'UPDATE_USER_GURANTOR_INFORMATION_DATA';

const UPDATED_ON_SUPPORT_UPLOADED_EVENT = 'UPDATED_ON_SUPPORT_UPLOADED_EVENT';

const fetchAddressStart = () => ({
    type: FETCH_ADDRESS_START
});

const fetchAddressSuccess = (data) => ({
    type: FETCH_ADDRESS_SUCCESS,
    data
});

const fetchAddressFailure = () => ({
    type: FETCH_ADDRESS_FAILURE
});

const createAddressStart = () => ({
    type: CREATE_ADDRESS_START
});

const createAddressSuccess = () => ({
    type: CREATE_ADDRESS_SUCCESS
});

const createAddressFailure = () => ({
    type: CREATE_ADDRESS_FAILURE
});

const updateAddressStart = () => ({
    type: UPDATE_ADDRESS_START
});

const updateAddressSuccess = (data) => ({
    type: UPDATE_ADDRESS_SUCCESS,
    data
});

const updateAddressFailure = () => ({
    type: UPDATE_ADDRESS_FAILURE
});

const supportDocsStartUpload = () => ({
    type: UPLOAD_SUPPORT_DOCS_START
});

const supportDocsStartUploadSuccess = (data) => ({
    type: UPLOAD_SUPPORT_DOCS_SUCCESS,
    data
});

const supportDocsStartUploadFailure = () => ({
    type: UPLOAD_SUPPORT_DOCS_FAILURE
});

const guarantorInformationUpdate = () => ({
    type: UPDATE_GUARANTOR_INFORMATION
});

const guarantorInformationUpdateSuccess = (data) => ({
    type: UPDATE_GUARANTOR_INFORMATION_SUCCESS,
    data
});

const guarantorInformationUpdateFailure = () => ({
    type: UPDATE_GUARANTOR_INFORMATION_FAILURE
});

export const updateOTPDataFromStateToStore = (data) => ({
    type: UPDATE_OTP_INPUT_DATA,
    data
});

export const updateAuthenticationDataFromStateToStore = (data) => ({
    type: UPDATE_USER_LOGIN_STATE_DATA,
    data
});

export const updateSupportingDocumentsDatFromStateToStore = (key, value) => ({
    type: UPDATE_USER_SUPPORTING_DOCUMENTS_DATA,
    data: {
        key,
        value
    }
});

export const guarantorInformationFromStateToStore = (keyG, valueG) => ({
    type: UPDATE_USER_GURANTOR_INFORMATION_DATA,
    data: {
        keyG,
        valueG
    }
});

export const updateGurantorInformationFromStateToStore = (data) => ({
    type: UPDATE_GUARANTOR_INFORMATION_ON_CHECKOUT,
    data
});

export const updatedOnSupportUploadedEvent = (data) => ({
    type: UPDATED_ON_SUPPORT_UPLOADED_EVENT,
    data
});

export const handleFetchAddress = () => {
    return async (dispatch) => {
        dispatch(fetchAddressStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/user/address?channel=samsung');

            dispatch(fetchAddressSuccess(data[0]));
        } catch (e) {
            dispatch(fetchAddressFailure());
        }
    };
};

export const handleCreateAddress = (form, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(createAddressStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        if (form?.name !== 'billing') {
            trackKlaviyoEvent('shipping-added', {
                zip_code: form.postal_code
            });
        }

        try {
            const addressRes = await instance.post('/user/address?channel=samsung', form);

            if (addressRes?.data?.id || addressRes?.status === 201 || addressRes?.status === 204 || addressRes?.status === 200) {
                dispatch(handleFetchAddress());
                dispatch(createAddressSuccess());

                onSuccess(addressRes.data);
            } else {
                onFailure();
            }
        } catch (e) {
            dispatch(createAddressFailure());

            onFailure();
        }
    };
};

export const handleUpdateAddress = (id, form, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(updateAddressStart());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const addressRes = await instance.put(`user/address/${id}`, form);

            if (addressRes?.data?.status === true || addressRes?.status === 204) {
                dispatch(updateAddressSuccess(form));
                onSuccess();
            } else {
                onFailure();
            }
        } catch (e) {
            dispatch(updateAddressFailure());

            onFailure();
        }
    };
};

export const handleSupportDocsUpload = (formData, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(supportDocsStartUpload());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const returnVal = await instance.post('user/insert-user-kyc-info', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (returnVal?.data?.status === true || returnVal?.data?.status === 200) {
                dispatch(supportDocsStartUploadSuccess(formData));
                onSuccess();
            } else {
                dispatch(supportDocsStartUploadFailure());

                onFailure();

                return false;
            }
        } catch (e) {
            dispatch(supportDocsStartUploadFailure());

            onFailure();
        }
    };
};

export const handleGuarantorInformationUpdate = (form, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch) => {
        dispatch(guarantorInformationUpdate());

        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const returnVal = await instance.post('user/insert-user-guarantor-info', form, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (returnVal?.data?.status === true || returnVal?.data?.status === 200) {
                dispatch(guarantorInformationUpdateSuccess(form));

                onSuccess();
            } else {
                dispatch(guarantorInformationUpdateFailure());

                onFailure();
            }
        } catch (e) {
            dispatch(guarantorInformationUpdateFailure());

            onFailure();
        }
    };
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case FETCH_ADDRESS_START:
            return {
                ...state,
                isLoading: true
            };

        case FETCH_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.data,
                isLoading: false
            };

        case FETCH_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case UPDATE_ADDRESS_START:
            return {
                ...state,
                isLoading: true
            };

        case UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                address: { ...state.address, ...action.data },
                isLoading: false
            };

        case UPDATE_ADDRESS_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case UPDATE_GUARANTOR_INFORMATION:
            return {
                ...state,
                isLoading: true
            };

        case UPDATE_GUARANTOR_INFORMATION_SUCCESS:
            return {
                ...state,
                guarantor: action.data?.userInfo,
                isLoading: false
            };

        case UPDATE_GUARANTOR_INFORMATION_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case UPLOAD_SUPPORT_DOCS_START:
            return {
                ...state,
                isLoading: true
            };

        case UPLOAD_SUPPORT_DOCS_SUCCESS:
            return {
                ...state,
                documents: action.data?.userInfo,
                isLoading: false
            };

        case UPLOAD_SUPPORT_DOCS_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case UPDATE_USER_LOGIN_STATE_DATA:
            return {
                ...state,
                authData: action.data
            };

        case UPDATE_USER_SUPPORTING_DOCUMENTS_DATA:
            // eslint-disable-next-line no-case-declarations
            const { key, value } = action.data;

            return {
                ...state,
                supportingDocs: {
                    ...state.supportingDocs,
                    [key]: value
                }
            };

        case UPDATE_USER_GURANTOR_INFORMATION_DATA:
            // eslint-disable-next-line no-case-declarations
            const { keyG, valueG } = action.data;

            return {
                ...state,
                guarantor: {
                    ...state.guarantor,
                    [keyG]: valueG
                }
            };

        case UPDATE_GUARANTOR_INFORMATION_ON_CHECKOUT:
            return {
                ...state,
                gurantorInformation: action.data
            };

        case UPDATE_OTP_INPUT_DATA:
            return {
                ...state,
                otpInput: action.data
            };

        case UPDATED_ON_SUPPORT_UPLOADED_EVENT:
            return {
                ...state,
                supportingDocsUploaded: action.data
            };

        default:
            return state;
    }
}
