import { AUTH_API_URL } from 'config/constants';
import axios from 'axios';

import { notifType, notify } from 'utils/notifSender';
import { getAuthToken } from 'utils/helpers';

/**
 * Get logged in user's profile data
 * @param {Object} data
 * @returns {Promise}
 */
export async function uploadFile(data) {
    const options = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + getAuthToken(),
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json'
        },
        url: AUTH_API_URL + '/upload/file',
        data
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('Sorry, can&apos;t upload this file.Please try again.', notifType.DANGER);

        throw new Error(err);
    }
}
