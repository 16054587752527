import axios from 'axios';

import { AUTH_API_URL } from 'config/constants';

import { notifType, notify } from 'utils/notifSender';
import { getAuthToken } from 'utils/helpers';

/**
 * add items to cart
 * @param {object} products
 * @returns {Promise}
 */

export async function addItemsToCart(products, coupon) {
    const data = { products };

    if (coupon) {
        data.coupon = coupon;
    }

    const options = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + getAuthToken(),
            'content-type': 'application/json'
        },
        url: AUTH_API_URL + '/user/cart?channel=samsung',
        data
    };

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);
        throw new Error(err);
    }
}

/**
 * check items in the cart
 * @returns {Promise}
 */

export async function checkoutRules() {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken(),
            'content-type': 'application/json'
        },
        url: AUTH_API_URL + '/checkoutrules/validate?channel=samsung'
    };

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        const response = err.response;

        return response;
    }
}

/**
 * check Coupon in the cart
 * @returns {Promise}
 */

export async function validateCoupon(coupon, products) {
    const options = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + getAuthToken(),
            'content-type': 'application/json'
        },
        url: AUTH_API_URL + '/coupon/validate?channel=samsung',
        data: { coupon, products }
    };

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        const response = err.response;

        throw response;
    }
}

/**
 * get user cart
 * @returns {Promise}
 */

export async function getUserCart() {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken(),
            'content-type': 'application/json'
        },
        url: AUTH_API_URL + '/user/cart?channel=samsung'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        const response = err.response;

        throw response;
    }
}

/**
 * update user cart
 * @returns {Promise}
 */

export async function updateUserCart(id, data) {
    const options = {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer ' + getAuthToken(),
            'content-type': 'application/json'
        },
        url: AUTH_API_URL + '/user/cart/' + id,
        data
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        const response = err.response;

        throw response;
    }
}
