import React from 'react';
// import PropTypes from 'prop-types';
import style from './title.module.scss';

// Title.propTypes = {
//     token: PropTypes.string.isRequired,
//     isAuthed: PropTypes.bool
// };

function Title() {
    return (
        <div className={style.title}>
            My Information
        </div>
    );
}

export default Title;
