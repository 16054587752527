import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { isEmpty } from 'underscore';

import { ROUTES } from 'config/constants';

import { handleFetchAddress } from 'redux/reducers/user';

import CheckoutStart from './CheckoutStart';

import { getAuthToken } from 'utils/helpers';

import './styles.scss';

const CheckoutContainer = ({ isAuthed, profile }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const previousUrl = document.referrer;
    const currentlySelectedCartItem = JSON.parse(localStorage.getItem('currentlySelectedCartItem'));

    useEffect(() => {
        window.document.title = 'Checkout';
    }, []);

    useEffect(() => {
        if (getAuthToken() && !isEmpty(currentlySelectedCartItem)) {
            if (location.pathname === ROUTES.ORDER_SUMMARY) dispatch(handleFetchAddress());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (
        (location.pathname === ROUTES.SINGPASS) ||
        (location.pathname === ROUTES.SINGPASS_RESULT) ||
        (location.pathname === ROUTES.DOCUMENTSPROOF) ||
        (location.pathname === ROUTES.SINGPASS_MANUALL_FORM)
    ) {
        return (
            <div className='signUp'>
                <CheckoutStart profile={profile} />
            </div>
        );
    } else if (location.pathname === ROUTES.SIGN_UP) {
        if (isAuthed) {
            if (profile?.singpass_status === 'verified') {
                previousUrl === ROUTES.CART ? history.push(ROUTES.ORDER_SUMMARY) : history.push(ROUTES.CART);
            } else {
                history.push(ROUTES.SINGPASS);
            }
        } else {
            return <CheckoutStart profile={profile} />;
        }
    }

    return null;
};

CheckoutContainer.propTypes = {
    isAuthed: PropTypes.bool,
    profile: PropTypes.object
};

const mapStateToProps = ({ auth }) => {
    const { isAuthed } = auth;

    return { isAuthed };
};

export default connect(mapStateToProps)(CheckoutContainer);
