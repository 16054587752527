export const countryList = [
    { label: 'ARUBA', value: 'ARUBA' },
    { label: 'ANDORRA', value: 'ANDORRA' },
    { label: 'UNITED ARAB EMIRATES', value: 'UNITED ARAB EMIRATES' },
    { label: 'AFGHANISTAN', value: 'AFGHANISTAN' },
    { label: 'ANTIGUA', value: 'ANTIGUA' },
    { label: 'ANGUILLA', value: 'ANGUILLA' },
    { label: 'ALBANIA', value: 'ALBANIA' },
    { label: 'ARMENIA', value: 'ARMENIA' },
    { label: 'NETHERLANDS ANTILILLES', value: 'NETHERLANDS ANTILILLES' },
    { label: 'ANGOLA', value: 'ANGOLA' },
    { label: 'ARGENTINA', value: 'ARGENTINA' },
    { label: 'AMERICAN SAMOA', value: 'AMERICAN SAMOA' },
    { label: 'AUSTRIA', value: 'AUSTRIA' },
    { label: 'AUSTRALIA', value: 'AUSTRALIA' },
    { label: 'AZERBAIJAN', value: 'AZERBAIJAN' },
    { label: 'BOSNIA-HERZEGOVINA', value: 'BOSNIA-HERZEGOVINA' },
    { label: 'BARBADOS', value: 'BARBADOS' },
    { label: 'BANGLADESH', value: 'BANGLADESH' },
    { label: 'BELGIUM', value: 'BELGIUM' },
    { label: 'BURKINA FASO', value: 'BURKINA FASO' },
    { label: 'BULGARIA', value: 'BULGARIA' },
    { label: 'BAHRAIN', value: 'BAHRAIN' },
    { label: 'BURUNDI', value: 'BURUNDI' },
    { label: 'BENIN', value: 'BENIN' },
    { label: 'BELARUS', value: 'BELARUS' },
    { label: 'BERMUDA', value: 'BERMUDA' },
    { label: 'BRUNEI', value: 'BRUNEI' },
    { label: 'BOLIVIA', value: 'BOLIVIA' },
    { label: 'BRITISH ANTARCTIC TERRITORY', value: 'BRITISH ANTARCTIC TERRITORY' },
    { label: 'BRAZIL', value: 'BRAZIL' },
    { label: 'BAHAMAS', value: 'BAHAMAS' },
    { label: 'BHUTAN', value: 'BHUTAN' },
    { label: 'MYANMAR', value: 'MYANMAR' },
    { label: 'BOTSWANA', value: 'BOTSWANA' },
    { label: 'BELIZE', value: 'BELIZE' },
    { label: 'CANADA', value: 'CANADA' },
    { label: 'CROATIA', value: 'CROATIA' },
    { label: 'COCOS KEELING ISLAND', value: 'COCOS KEELING ISLAND' },
    { label: 'CHANNEL ISLANDS', value: 'CHANNEL ISLANDS' },
    { label: 'CENTRAL AFRICAN REPUBLIC', value: 'CENTRAL AFRICAN REPUBLIC' },
    { label: 'CONGO', value: 'CONGO' },
    { label: 'SWITZERLAND', value: 'SWITZERLAND' },
    { label: 'IVORY COAST', value: 'IVORY COAST' },
    { label: 'COOK ISLANDS', value: 'COOK ISLANDS' },
    { label: 'CHILE', value: 'CHILE' },
    { label: 'CAMEROON', value: 'CAMEROON' },
    { label: 'CHINA', value: 'CHINA' },
    { label: 'COLOMBIA', value: 'COLOMBIA' },
    { label: 'COSTA RICA', value: 'COSTA RICA' },
    { label: 'CANTON & ENDERBURY ISLANDS', value: 'CANTON & ENDERBURY ISLANDS' },
    { label: 'CUBA', value: 'CUBA' },
    { label: 'CAPE VERDE', value: 'CAPE VERDE' },
    { label: 'CHRISTMAS ISLAND', value: 'CHRISTMAS ISLAND' },
    { label: 'CYPRUS', value: 'CYPRUS' },
    { label: 'CZECH REPUBLIC', value: 'CZECH REPUBLIC' },
    { label: 'GERMANY', value: 'GERMANY' },
    { label: 'DJIBOUTI', value: 'DJIBOUTI' },
    { label: 'DENMARK', value: 'DENMARK' },
    { label: 'DOMINICA', value: 'DOMINICA' },
    { label: 'DOMINICAN REPUBLIC', value: 'DOMINICAN REPUBLIC' },
    { label: 'ALGERIA', value: 'ALGERIA' },
    { label: 'ECUADOR', value: 'ECUADOR' },
    { label: 'EGYPT', value: 'EGYPT' },
    { label: 'WESTERN SAHARA', value: 'WESTERN SAHARA' },
    { label: 'ESTONIA', value: 'ESTONIA' },
    { label: 'ERITREA', value: 'ERITREA' },
    { label: 'SPAIN', value: 'SPAIN' },
    { label: 'ETHIOPIA', value: 'ETHIOPIA' },
    { label: 'ESWATINI', value: 'ESWATINI' },
    { label: 'FINLAND', value: 'FINLAND' },
    { label: 'FIJI', value: 'FIJI' },
    { label: 'FALKLAND ISLANDS', value: 'FALKLAND ISLANDS' },
    { label: 'FAEROE ISLANDS', value: 'FAEROE ISLANDS' },
    { label: 'FRENCH SOUTHERN & ANTARCTIC TERRITORIES', value: 'FRENCH SOUTHERN & ANTARCTIC TERRITORIES' },
    { label: 'FRANCE', value: 'FRANCE' },
    { label: 'GABON', value: 'GABON' },
    { label: 'UNITED KINGDOM', value: 'UNITED KINGDOM' },
    { label: 'GRENADA', value: 'GRENADA' },
    { label: 'FRENCH GUIANA', value: 'FRENCH GUIANA' },
    { label: 'GHANA', value: 'GHANA' },
    { label: 'GIBRALTAR', value: 'GIBRALTAR' },
    { label: 'GUERNSEY', value: 'GUERNSEY' },
    { label: 'GREENLAND', value: 'GREENLAND' },
    { label: 'GAMBIA', value: 'GAMBIA' },
    { label: 'GUINEA', value: 'GUINEA' },
    { label: 'GEORGIA', value: 'GEORGIA' },
    { label: 'GUADELOUPE', value: 'GUADELOUPE' },
    { label: 'EQUATORIAL GUINEA', value: 'EQUATORIAL GUINEA' },
    { label: 'GREECE', value: 'GREECE' },
    { label: 'GUATEMALA', value: 'GUATEMALA' },
    { label: 'GUAM', value: 'GUAM' },
    { label: 'GUINEA-BISSAU', value: 'GUINEA-BISSAU' },
    { label: 'GUYANA', value: 'GUYANA' },
    { label: 'GAZA', value: 'GAZA' },
    { label: 'HONG KONG', value: 'HONG KONG' },
    { label: 'HEARD ISLAND & MCDONALD ISLANDS', value: 'HEARD ISLAND & MCDONALD ISLANDS' },
    { label: 'HONDURAS', value: 'HONDURAS' },
    { label: 'HONG KONG SAR', value: 'HONG KONG SAR' },
    { label: 'HAITI', value: 'HAITI' },
    { label: 'HUNGARY', value: 'HUNGARY' },
    { label: 'UPPER VOLTA', value: 'UPPER VOLTA' },
    { label: 'INDONESIA', value: 'INDONESIA' },
    { label: 'IRELAND', value: 'IRELAND' },
    { label: 'ISRAEL', value: 'ISRAEL' },
    { label: 'INDIA', value: 'INDIA' },
    { label: 'BRITISH INDIAN OCEAN TERRITORY', value: 'BRITISH INDIAN OCEAN TERRITORY' },
    { label: 'IRAQ', value: 'IRAQ' },
    { label: 'IRAN', value: 'IRAN' },
    { label: 'ICELAND', value: 'ICELAND' },
    { label: 'ITALY', value: 'ITALY' },
    { label: 'JAMAICA', value: 'JAMAICA' },
    { label: 'JORDAN', value: 'JORDAN' },
    { label: 'JAPAN', value: 'JAPAN' },
    { label: 'JOHNSTON ISLAND', value: 'JOHNSTON ISLAND' },
    { label: 'CAMBODIA', value: 'CAMBODIA' },
    { label: 'KENYA', value: 'KENYA' },
    { label: 'KIRGHIZIA', value: 'KIRGHIZIA' },
    { label: 'KIRIBATI', value: 'KIRIBATI' },
    { label: 'COMOROS', value: 'COMOROS' },
    { label: 'ST. KITTS-NEVIS', value: 'ST. KITTS-NEVIS' },
    { label: 'D.P.R. KOREA', value: 'D.P.R. KOREA' },
    { label: 'KOREA, SOUTH', value: 'KOREA, SOUTH' },
    { label: 'KYRGYZSTAN', value: 'KYRGYZSTAN' },
    { label: 'KOSOVO', value: 'KOSOVO' },
    { label: 'KUWAIT', value: 'KUWAIT' },
    { label: 'CAYMAN ISLANDS', value: 'CAYMAN ISLANDS' },
    { label: 'KAZAKHSTAN', value: 'KAZAKHSTAN' },
    { label: 'LAOS', value: 'LAOS' },
    { label: 'LEBANON', value: 'LEBANON' },
    { label: 'ST. LUCIA', value: 'ST. LUCIA' },
    { label: 'LITHUANIA', value: 'LITHUANIA' },
    { label: 'LIECHTENSTEIN', value: 'LIECHTENSTEIN' },
    { label: 'SRI LANKA', value: 'SRI LANKA' },
    { label: 'LIBERIA', value: 'LIBERIA' },
    { label: 'LESOTHO', value: 'LESOTHO' },
    { label: 'LUXEMBOURG', value: 'LUXEMBOURG' },
    { label: 'LATVIA', value: 'LATVIA' },
    { label: 'LIBYA', value: 'LIBYA' },
    { label: 'MOROCCO', value: 'MOROCCO' },
    { label: 'MACEDONIA', value: 'MACEDONIA' },
    { label: 'MONACO', value: 'MONACO' },
    { label: 'MOLDOVA', value: 'MOLDOVA' },
    { label: 'MAYOTTE', value: 'MAYOTTE' },
    { label: 'MACAO SAR', value: 'MACAO SAR' },
    { label: 'MADAGASCAR', value: 'MADAGASCAR' },
    { label: 'MARSHALL ISLANDS', value: 'MARSHALL ISLANDS' },
    { label: 'MIDWAY ISLANDS', value: 'MIDWAY ISLANDS' },
    { label: 'MONTENEGRO', value: 'MONTENEGRO' },
    { label: 'MALI', value: 'MALI' },
    { label: 'ISLE OF MAN', value: 'ISLE OF MAN' },
    { label: 'MONGOLIA', value: 'MONGOLIA' },
    { label: 'MACAO', value: 'MACAO' },
    { label: 'MARTINIQUE', value: 'MARTINIQUE' },
    { label: 'MAURITANIA', value: 'MAURITANIA' },
    { label: 'MONTSERRAT', value: 'MONTSERRAT' },
    { label: 'MALTA', value: 'MALTA' },
    { label: 'MAURITIUS', value: 'MAURITIUS' },
    { label: 'MALDIVES', value: 'MALDIVES' },
    { label: 'MALAWI', value: 'MALAWI' },
    { label: 'MEXICO', value: 'MEXICO' },
    { label: 'MALAYSIA', value: 'MALAYSIA' },
    { label: 'MOZAMBIQUE', value: 'MOZAMBIQUE' },
    { label: 'NAMIBIA', value: 'NAMIBIA' },
    { label: 'NEW CALEDONIA', value: 'NEW CALEDONIA' },
    { label: 'NIGER', value: 'NIGER' },
    { label: 'NORFOLK ISLAND', value: 'NORFOLK ISLAND' },
    { label: 'NIGERIA', value: 'NIGERIA' },
    { label: 'NICARAGUA', value: 'NICARAGUA' },
    { label: 'NETHERLANDS', value: 'NETHERLANDS' },
    { label: 'NORTH MACEDONIA', value: 'NORTH MACEDONIA' },
    { label: 'NORWAY', value: 'NORWAY' },
    { label: 'NEPAL', value: 'NEPAL' },
    { label: 'NAURU', value: 'NAURU' },
    { label: 'NIUE ISLAND', value: 'NIUE ISLAND' },
    { label: 'NEW ZEALAND', value: 'NEW ZEALAND' },
    { label: 'OMAN', value: 'OMAN' },
    { label: 'PANAMA', value: 'PANAMA' },
    { label: 'PALESTINE', value: 'PALESTINE' },
    { label: 'PACIFIC ISLAND TRUST TERRITORY', value: 'PACIFIC ISLAND TRUST TERRITORY' },
    { label: 'PERU', value: 'PERU' },
    { label: 'FRENCH POLYNESIA', value: 'FRENCH POLYNESIA' },
    { label: 'PAPUA NEW GUINEA', value: 'PAPUA NEW GUINEA' },
    { label: 'PHILIPPINES', value: 'PHILIPPINES' },
    { label: 'PAKISTAN', value: 'PAKISTAN' },
    { label: 'POLAND', value: 'POLAND' },
    { label: 'ST. PIERRE & MIQUELON', value: 'ST. PIERRE & MIQUELON' },
    { label: 'PITCAIRN ISLAND', value: 'PITCAIRN ISLAND' },
    { label: 'PUERTO RICO', value: 'PUERTO RICO' },
    { label: 'PORTUGAL', value: 'PORTUGAL' },
    { label: 'PALAU', value: 'PALAU' },
    { label: 'PARAGUAY', value: 'PARAGUAY' },
    { label: 'PANAMA CANAL ZONE', value: 'PANAMA CANAL ZONE' },
    { label: 'QATAR', value: 'QATAR' },
    { label: 'REUNION', value: 'REUNION' },
    { label: 'RUSSIA', value: 'RUSSIA' },
    { label: 'ROMANIA', value: 'ROMANIA' },
    { label: 'SERBIA', value: 'SERBIA' },
    { label: 'RWANDA', value: 'RWANDA' },
    { label: 'SAUDI ARABIA', value: 'SAUDI ARABIA' },
    { label: 'SOLOMON ISLANDS', value: 'SOLOMON ISLANDS' },
    { label: 'SEYCHELLES', value: 'SEYCHELLES' },
    { label: 'SUDAN', value: 'SUDAN' },
    { label: 'SWEDEN', value: 'SWEDEN' },
    { label: 'SERBIA/MONTENEGRO', value: 'SERBIA/MONTENEGRO' },
    { label: 'SINGAPORE', value: 'SINGAPORE' },
    { label: 'ST. HELENA', value: 'ST. HELENA' },
    { label: 'SLOVENIA', value: 'SLOVENIA' },
    { label: 'SLOVAK REPUBLIC', value: 'SLOVAK REPUBLIC' },
    { label: 'SIERRA LEONE', value: 'SIERRA LEONE' },
    { label: 'SAN MARINO', value: 'SAN MARINO' },
    { label: 'SENEGAL', value: 'SENEGAL' },
    { label: 'SOMALIA', value: 'SOMALIA' },
    { label: 'SURINAME', value: 'SURINAME' },
    { label: 'SAO TOME & PRINCIPE', value: 'SAO TOME & PRINCIPE' },
    { label: 'EL SALVADOR', value: 'EL SALVADOR' },
    { label: 'SOUTH SUDAN', value: 'SOUTH SUDAN' },
    { label: 'SYRIA', value: 'SYRIA' },
    { label: 'SWAZILAND', value: 'SWAZILAND' },
    { label: 'TURKS & CAICOS ISLANDS', value: 'TURKS & CAICOS ISLANDS' },
    { label: 'CHAD', value: 'CHAD' },
    { label: 'TIMOR', value: 'TIMOR' },
    { label: 'TOGO', value: 'TOGO' },
    { label: 'THAILAND', value: 'THAILAND' },
    { label: 'TAJIKISTAN', value: 'TAJIKISTAN' },
    { label: 'TOKELAU ISLANDS', value: 'TOKELAU ISLANDS' },
    { label: 'TURKMENISTAN', value: 'TURKMENISTAN' },
    { label: 'TUNISIA', value: 'TUNISIA' },
    { label: 'TONGA', value: 'TONGA' },
    { label: 'EAST TIMOR', value: 'EAST TIMOR' },
    { label: 'TURKEY', value: 'TURKEY' },
    { label: 'TRINIDAD & TOBAGO', value: 'TRINIDAD & TOBAGO' },
    { label: 'TUVALU', value: 'TUVALU' },
    { label: 'TAIWAN', value: 'TAIWAN' },
    { label: 'TANZANIA', value: 'TANZANIA' },
    { label: 'UGANDA', value: 'UGANDA' },
    { label: 'UNKNOWN', value: 'UNKNOWN' },
    { label: 'UKRAINE', value: 'UKRAINE' },
    { label: 'UNITED STATES', value: 'UNITED STATES' },
    { label: 'URUGUAY', value: 'URUGUAY' },
    { label: 'UZBEKISTAN', value: 'UZBEKISTAN' },
    { label: 'VATICAN CITY STATE', value: 'VATICAN CITY STATE' },
    { label: 'ST. VINCENT', value: 'ST. VINCENT' },
    { label: 'VENEZUELA', value: 'VENEZUELA' },
    { label: 'BRITISH VIRGIN ISLANDS', value: 'BRITISH VIRGIN ISLANDS' },
    { label: 'US VIRGIN ISLANDS', value: 'US VIRGIN ISLANDS' },
    { label: 'VIETNAM', value: 'VIETNAM' },
    { label: 'VANUATU', value: 'VANUATU' },
    { label: 'WALLIS AND FUTUNA', value: 'WALLIS AND FUTUNA' },
    { label: 'WAKE ISLAND', value: 'WAKE ISLAND' },
    { label: 'SAMOA', value: 'SAMOA' },
    { label: 'OTHERS', value: 'OTHERS' },
    { label: 'YEMEN', value: 'YEMEN' },
    { label: 'YUGOSLAVIA', value: 'YUGOSLAVIA' },
    { label: 'SOUTH AFRICA', value: 'SOUTH AFRICA' },
    { label: 'ZAMBIA', value: 'ZAMBIA' },
    { label: 'ZAIRE', value: 'ZAIRE' },
    { label: 'ZIMBABWE', value: 'ZIMBABWE' }
];
