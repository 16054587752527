import React from 'react';
import PropTypes from 'prop-types';

import FormHeader from './elements/FormHeader/FormHeader';
import First from './elements/forms/first';
import Second from './elements/forms/second';
import Third from './elements/forms/third';
import Fourth from './elements/forms/fourth';
import Fifth from './elements/forms/fifth';

SingpassManualFormScreen.propTypes = {
    errors: PropTypes.object.isRequired,
    setErrors: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    firstForm: PropTypes.object.isRequired,
    setFirstForm: PropTypes.func.isRequired,
    secondForm: PropTypes.object.isRequired,
    setSecondForm: PropTypes.func.isRequired,
    thirdForm: PropTypes.object.isRequired,
    setThirdForm: PropTypes.func.isRequired,
    fourthForm: PropTypes.object.isRequired,
    setFourthForm: PropTypes.func.isRequired,
    fifthForm: PropTypes.object.isRequired,
    setFifthForm: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    handlePrevStep: PropTypes.func.isRequired,
    myRef: PropTypes.object.isRequired,
    errorMsgs: PropTypes.object,
    loading: PropTypes.bool,
    openModal: PropTypes.bool,
    handleUploadFile: PropTypes.func,
    uploadLoading: PropTypes.bool,
    handleUploadedFile: PropTypes.func,
    handleErrorMsg: PropTypes.func
};

function SingpassManualFormScreen({
    errors,
    setErrors,
    step,
    firstForm,
    setFirstForm,
    secondForm,
    setSecondForm,
    thirdForm,
    setThirdForm,
    fourthForm,
    setFourthForm,
    fifthForm,
    setFifthForm,
    handleNextStep,
    handlePrevStep,
    myRef,
    errorMsgs,
    loading,
    openModal,
    handleUploadedFile,
    handleErrorMsg
}) {
    return (
        <div className='container' ref={myRef}>
            <div><FormHeader title={step !== 1}/></div>
            <div>
                {step === 1 &&
                <First
                    handleErrorMsg={handleErrorMsg}
                    handleUploadedFile={handleUploadedFile}
                    errorMsgs={errorMsgs}
                    myRef={myRef}
                    errors={errors}
                    setErrors={setErrors}
                    firstForm={firstForm}
                    setFirstForm={setFirstForm}
                    handleNextStep={handleNextStep}/>
                }
                {step === 2 &&
                <Second
                    errorMsgs={errorMsgs}
                    errors={errors}
                    setErrors={setErrors}
                    secondForm={secondForm}
                    setSecondForm={setSecondForm}
                    handleNextStep={handleNextStep}
                    handlePrevStep={handlePrevStep}/>
                }
                {step === 3 &&
                <Third
                    errorMsgs={errorMsgs}
                    errors={errors}
                    setErrors={setErrors}
                    thirdForm={thirdForm}
                    setThirdForm={setThirdForm}
                    handleNextStep={handleNextStep}
                    handlePrevStep={handlePrevStep}/>
                }
                {step === 4 &&
                <Fourth
                    errorMsgs={errorMsgs}
                    errors={errors}
                    setErrors={setErrors}
                    fourthForm={fourthForm}
                    setFourthForm={setFourthForm}
                    handleNextStep={handleNextStep}
                    handlePrevStep={handlePrevStep}/>
                }
                {step === 5 &&
                <Fifth
                    handleErrorMsg={handleErrorMsg}
                    handleUploadedFile={handleUploadedFile}
                    openModal={openModal}
                    loading={loading}
                    errorMsgs={errorMsgs}
                    errors={errors}
                    setErrors={setErrors}
                    fifthForm={fifthForm}
                    setFifthForm={setFifthForm}
                    handleNextStep={handleNextStep}
                    handlePrevStep={handlePrevStep}/>
                }
            </div>
        </div>
    );
}

export default SingpassManualFormScreen;
