import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormFeedback, Input, Label } from 'reactstrap';

import InputPhone from 'containers/App/components/InputPhone';

import './Form.scss';

const MyInformationForm = ({
    formData,
    onFormDataItemChange,
    errors,
    isFormDisabled = false
}) => {
    const {
        name,
        aliasName,
        sex,
        maritalStatus,
        marriedName,
        dateOfBirth,
        countryOfBirth,
        race,
        secondaryRace,
        residencyStatus,
        residentialAddress,
        nric,
        workPassExpiryDate,
        email,
        mobile,
        occupation,
        typeOfHousing,
        nameOfEmployer,
        employmentSector,
        income,
        assessedYearOfIncome,
        passType,
        passStatus
    } = formData;

    return (
        <form className='MyInformationForm'>
            <div className='d-flex flex-col w-100'>
                <Label for='myInfoName'>Full legal name</Label>
                <FormGroup className={`MyInformationForm__form-group ${errors?.name?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                    <Input
                        id='myInfoName'
                        name='myInfoName'
                        placeholder='Full legal name'
                        type='text'
                        value={name}
                        onChange={(e) => onFormDataItemChange('name', e)}
                        disabled={true} />
                </FormGroup>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='aliasName'>Alias name</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.aliasName?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='aliasName'
                            name='aliasName'
                            placeholder='Alias Name'
                            type='text'
                            value={aliasName}
                            onChange={(e) => onFormDataItemChange('aliasName', e)}
                            disabled={true} />
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='sex'>Gender</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.sex?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='sex'
                            name='sex'
                            placeholder='Sex'
                            type='text'
                            value={sex}
                            onChange={(e) => onFormDataItemChange('sex', e)}
                            disabled={true} />
                    </FormGroup>
                </div>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='landmark'>Marital status</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.maritalStatus?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='maritalStatus'
                            name='maritalStatus'
                            placeholder='Marital Status'
                            type='text'
                            value={maritalStatus}
                            onChange={(e) => onFormDataItemChange('maritalStatus', e)}
                            invalid={!!errors?.maritalStatus?.message}
                            disabled={isFormDisabled} />
                        <FormFeedback valid={false}>{errors?.maritalStatus?.message}</FormFeedback>
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='marriedName'>Married name</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.marriedName?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='marriedName'
                            name='marriedName'
                            placeholder='Married Name'
                            type='text'
                            value={marriedName}
                            onChange={(e) => onFormDataItemChange('marriedName', e)}
                            invalid={!!errors?.marriedName?.message}
                            disabled={isFormDisabled} />
                        <FormFeedback valid={false}>{errors?.marriedName?.message}</FormFeedback>
                    </FormGroup>
                </div>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='dateOfBirth'>Date of birth</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.dateOfBirth?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='dateOfBirth'
                            name='dateOfBirth'
                            placeholder='Date Of Birth'
                            type='text'
                            value={dateOfBirth}
                            onChange={(e) => onFormDataItemChange('dateOfBirth', e)}
                            disabled={true} />
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='countryOfBirth'>Country Of birth</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.countryOfBirth?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='countryOfBirth'
                            name='countryOfBirth'
                            placeholder='Country Of Birth'
                            type='text'
                            value={countryOfBirth}
                            onChange={(e) => onFormDataItemChange('countryOfBirth', e)}
                            disabled={true} />
                    </FormGroup>
                </div>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='race'>Race / Ethnicity</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.race?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='race'
                            name='race'
                            placeholder='Race'
                            type='text'
                            value={race}
                            onChange={(e) => onFormDataItemChange('race', e)}
                            disabled={true} />
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='secondaryRace'>Secondary race</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.secondaryRace?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='secondaryRace'
                            name='secondaryRace'
                            placeholder='Secondary Race'
                            type='text'
                            value={secondaryRace}
                            onChange={(e) => onFormDataItemChange('secondaryRace', e)}
                            disabled={true} />
                    </FormGroup>
                </div>
            </div>

            <div className='d-flex flex-col w-100'>
                <Label for='residencyStatus'>Residency status</Label>
                <FormGroup className={`MyInformationForm__form-group ${errors?.residencyStatus?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                    <Input
                        id='residencyStatus'
                        name='residencyStatus'
                        placeholder='Residency Status'
                        type='text'
                        value={residencyStatus}
                        onChange={(e) => onFormDataItemChange('residencyStatus', e)}
                        disabled={true} />
                </FormGroup>
            </div>

            <div className='d-flex flex-col w-100'>
                <Label for='residentialAddress'>Residential address</Label>
                <FormGroup className={`MyInformationForm__form-group ${errors?.residentialAddress?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                    <Input
                        id='residentialAddress'
                        name='residentialAddress'
                        placeholder='Residential address'
                        type='text'
                        value={residentialAddress}
                        onChange={(e) => onFormDataItemChange('residentialAddress', e)}
                        invalid={!!errors?.residentialAddress?.message}
                        disabled={isFormDisabled}/>
                    <FormFeedback valid={false}>{errors?.residentialAddress?.message}</FormFeedback>
                </FormGroup>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='nric'>NRIC / FIN</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.nric?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='nric'
                            name='nric'
                            placeholder='NRIC / FIN'
                            type='text'
                            value={nric}
                            onChange={(e) => onFormDataItemChange('nric', e)}
                            disabled={true} />
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='workPassExpiryDate'>Work pass expiry date</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.pinCode?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='workPassExpiryDate'
                            name='workPassExpiryDate'
                            placeholder='Work pass expiry date'
                            type='text'
                            value={workPassExpiryDate}
                            onChange={(e) => onFormDataItemChange('workPassExpiryDate', e)}
                            disabled={true} />
                    </FormGroup>
                </div>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='myInfoEmail'>Email address</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.email?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='myInfoEmail'
                            name='email'
                            placeholder='Email address'
                            type='text'
                            value={email}
                            onChange={(e) => onFormDataItemChange('email', e)}
                            invalid={!!errors?.email?.message}
                            disabled={isFormDisabled} />
                        <FormFeedback valid={false}>{errors?.email?.message}</FormFeedback>
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='myInfoMobile'>Mobile Number</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.mobile?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <InputPhone
                            id='myInfoMobile'
                            phone={mobile || ''}
                            setPhone={(value) => onFormDataItemChange('mobile', { target: { value } })}
                            countryCodeEditable={false}
                            disableDropdown={true}
                            className='MyInformationForm__form-group__phone-input'
                            country={'sg'}
                            onlyCountries={['sg']}
                            disabled={isFormDisabled} />
                        <FormFeedback valid={false}>{errors?.mobile?.message}</FormFeedback>
                    </FormGroup>
                </div>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='occupation'>Occupation</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.occupation?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='occupation'
                            name='occupation'
                            placeholder='Occupation'
                            type='text'
                            value={occupation}
                            onChange={(e) => onFormDataItemChange('occupation', e)}
                            disabled={true} />
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='typeOfHousing'>Type of housing</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.typeOfHousing?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='typeOfHousing'
                            name='typeOfHousing'
                            placeholder='Type of housing'
                            type='text'
                            value={typeOfHousing}
                            onChange={(e) => onFormDataItemChange('typeOfHousing', e)}
                            disabled={true} />
                    </FormGroup>
                </div>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='nameOfEmployer'>Name of employer</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.nameOfEmployer?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='nameOfEmployer'
                            name='nameOfEmployer'
                            placeholder='Name of employer'
                            type='text'
                            value={nameOfEmployer}
                            onChange={(e) => onFormDataItemChange('nameOfEmployer', e)}
                            disabled={true} />
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='employmentSector'>Employment sector</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.employmentSector?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='employmentSector'
                            name='employmentSector'
                            placeholder='Employment Sector'
                            type='text'
                            value={employmentSector}
                            onChange={(e) => onFormDataItemChange('employmentSector', e)}
                            disabled={true} />
                    </FormGroup>
                </div>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='income'>Income / NOA</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.income?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='income'
                            name='income'
                            placeholder='Income'
                            type='text'
                            value={income}
                            onChange={(e) => onFormDataItemChange('income', e)}
                            disabled={true} />
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='assessedYearOfIncome'>Assessed year of assessable income</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.assessedYearOfIncome?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='assessedYearOfIncome'
                            name='assessedYearOfIncome'
                            placeholder='Assessed Year of Assessable Income'
                            type='text'
                            value={assessedYearOfIncome}
                            onChange={(e) => onFormDataItemChange('assessedYearOfIncome', e)}
                            disabled={true} />
                    </FormGroup>
                </div>
            </div>

            <div className='d-flex w-100'>
                <div className='d-flex flex-col w-50'>
                    <Label for='landmark'>Pass type</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.passType?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='passType'
                            name='passType'
                            placeholder='Pass Type'
                            type='text'
                            value={passType}
                            onChange={(e) => onFormDataItemChange('passType', e)}
                            disabled={true} />
                    </FormGroup>
                </div>

                <div className='d-flex flex-col w-50'>
                    <Label for='passStatus'>Pass status</Label>
                    <FormGroup className={`MyInformationForm__form-group ${errors?.passStatus?.message ? 'MyInformationForm__form-group--error' : ''}`} floating={true}>
                        <Input
                            id='passStatus'
                            name='passStatus'
                            placeholder='Pass Status'
                            type='text'
                            value={passStatus}
                            onChange={(e) => onFormDataItemChange('passStatus', e)}
                            disabled={true} />
                    </FormGroup>
                </div>
            </div>
        </form>
    );
};

MyInformationForm.propTypes = {
    formData: PropTypes.object.isRequired,
    onFormDataItemChange: PropTypes.func.isRequired,
    isFormDisabled: PropTypes.bool,
    includeNoa: PropTypes.bool,
    errors: PropTypes.object
};

export default MyInformationForm;
