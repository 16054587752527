import React from 'react';
import PropTypes from 'prop-types';

import Button from 'containers/App/components/Button/Button';

import style from './FormFooter.module.scss';

FormFooter.propTypes = {
    prevLabel: PropTypes.string,
    prevClick: PropTypes.func,
    nextLabel: PropTypes.string,
    nextClick: PropTypes.func,
    loading: PropTypes.bool
};

function FormFooter({
    prevLabel,
    prevClick,
    nextLabel,
    nextClick,
    loading
}) {
    return (
        <div className={style.FormFooter} >
            <div className={style.FormFooter__left}>
                {prevLabel &&
                <Button
                    className='submit footerBtn'
                    disabled={loading}
                    title={prevLabel}
                    onClick={prevClick}/>
                }
            </div>
            <div className={style.FormFooter__right}>
                <Button
                    className='submit footerBtn'
                    loading={loading}
                    title={nextLabel}
                    onClick={nextClick}/>
            </div>
        </div>
    );
}

export default FormFooter;
