import axios from 'axios';

import { AUTH_API_URL, INACTIVE_STATUS } from 'config/constants';

import { notifType, notify } from 'utils/notifSender';
import { getAuthToken } from 'utils/helpers';

export async function getOrders(page = 1, limit = 10, status) {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + `/order?paginate=true&channel=samsung&page=${page}&limit=${limit}${status ? '&status=' + status : ''}`
    };

    try {
        const { data: { data, meta } } = await axios(options);

        return status !== 'subscribed' ? { data: data.filter(d => d?.status !== 'subscribed'), meta } : { data, meta };
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

export async function getInactiveOrders() {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/order?limit=999&channel=samsung'
    };

    try {
        const { data: { data, meta } } = await axios(options);

        return { data: data.filter(d => INACTIVE_STATUS.includes(d.status)), meta };
    } catch (err) {
        notify('Something went wrong. Please try again.', notifType.DANGER);

        return false;
    }
}

/**
 * update order
 * @param {String} id
 * @param {Object} param
 */

export async function updateOrder(id, param) {
    const options = {
        method: 'PATCH',
        url: AUTH_API_URL + '/order/' + id + '?channel=samsung',
        data: param,
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        }
    };

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        if (err.response && err.response.status === 500) {
            notify('Something went wrong. Please try again.', notifType.DANGER);
        }

        throw err;
    }
}

/**
 * get billing
 */

export async function billingOrder(id, newrentalPeriodId = null, coupon = null) {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + '/order/' + id + '?channel=samsung&newRentalPeriodId=' + newrentalPeriodId + '&coupon=' + coupon,
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        }
    };

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        if (err.response && err.response.status === 500) {
            notify('Something went wrong. Please try again.', notifType.DANGER);
        }

        throw err;
    }
}

/**
 * get Price
 */

export async function getPurchasePrice(id) {
    const options = {
        method: 'POST',
        url: AUTH_API_URL + '/order/purchase?channel=samsung&get_price',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        data: { order_id: id }
    };

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        throw err.response;
    }
}

/**
 * get billing
 */

export async function purchaseProduct(id) {
    const options = {
        method: 'POST',
        url: AUTH_API_URL + '/order/purchase?channel=samsung',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        data: { order_id: id }
    };

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        throw err.response;
    }
}
