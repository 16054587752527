import React from 'react';
import PropTypes from 'prop-types';

import FormFooter from '../FormFooter';
import FormSelcetion from '../FormSelection';
import { countryList } from 'utils/countries';
import { ethnicity } from 'screens/SingpassManualForm/options';
import DatePicker from '../DatePicker';

import style from './Form.module.scss';

Third.propTypes = {
    errors: PropTypes.object.isRequired,
    setErrors: PropTypes.func.isRequired,
    thirdForm: PropTypes.object.isRequired,
    setThirdForm: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    handlePrevStep: PropTypes.func.isRequired,
    errorMsgs: PropTypes.object
};

function Third({
    errors,
    setErrors,
    thirdForm,
    setThirdForm,
    handleNextStep,
    handlePrevStep,
    errorMsgs
}) {
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setThirdForm(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: false }));
    };

    return (
        <div className={style.body}>
            <div>
                <div className={style.col}>
                    <DatePicker
                        date={thirdForm.dob ? new Date(thirdForm.dob) : ''}
                        error={errors.dob}
                        errorMsg={errorMsgs.dob}
                        onChange={e => handleChange({ target: { name: 'dob', value: e } })}
                        otherProps={{
                            maxDate: new Date(),
                            placeholderText: 'MM/DD/YYYY',
                            showMonthDropdown: true,
                            showYearDropdown: true,
                            scrollableYearDropdown: true,
                            yearDropdownItemNumber: 150
                        }}
                        label='Date of birth:'
                        name={'dob'}/>

                    {/* <FormInput
                        type='date'
                        name={'dob'}
                        value={thirdForm.dob}
                        error={errors.dob}
                        errorMsg={errorMsgs.dob}
                        onChange={handleChange}
                        label='Date of birth:' /> */}
                </div>
                <div className={style.col}>
                    <FormSelcetion
                        isSearchable={true}
                        name='birth_country'
                        error={errors.birth_country}
                        errorMsg={errorMsgs.birth_country}
                        value={thirdForm.birth_country ? ({ label: thirdForm.birth_country, value: thirdForm.birth_country }) : null}
                        onChange={handleChange}
                        options={countryList}
                        placeholder='Select an option'
                        label='Country of birth:'/>
                </div>
                <div className={style.col}>
                    <FormSelcetion
                        isSearchable={true}
                        name='ethnicity'
                        error={errors.ethnicity}
                        errorMsg={errorMsgs.ethnicity}
                        value={thirdForm.ethnicity ? ({ label: thirdForm.ethnicity, value: thirdForm.ethnicity }) : null}
                        onChange={handleChange}
                        options={ethnicity}
                        placeholder='Select an option'
                        label='Race/Ethnicity:'/>
                </div>
                <div className={style.col}>
                    <FormSelcetion
                        isSearchable={true}
                        name='secondary_race'
                        error={errors.secondary_race}
                        errorMsg={errorMsgs.secondary_race}
                        value={thirdForm.secondary_race ? ({ label: thirdForm.secondary_race, value: thirdForm.secondary_race }) : null}
                        onChange={handleChange}
                        options={[
                            { label: 'None', value: 'None' },
                            ...ethnicity]}
                        placeholder='Select an option'
                        label='Secondary race:'/>
                </div>
                <div className={style.col}>
                    <FormFooter
                        prevLabel='Previous'
                        nextLabel='Next'
                        prevClick={handlePrevStep}
                        nextClick={handleNextStep}/>
                </div>
            </div>
        </div>
    );
}

export default Third;
