import * as Sentry from '@sentry/react';
import { isProduction } from 'utils/helpers';

// TODO: rename to more generic tracking function
export async function trackKlaviyoEvent(event, params = {}) {
    var klaviyo = window.klaviyo = window.klaviyo || [];
    var dataLayer = window.dataLayer = window.dataLayer || [];

    if (!isProduction()) {
        return;
    }

    try {
        switch (event) {
            case 'identify':
                klaviyo.identify({
                    $email: params.email,
                    $first_name: params.name,
                    $last_name: params.surname,
                    channel: 'samsung'
                }, () => { });
                break;
            case 'product-view':
                var item = {
                    ProductName: params.title,
                    ProductID: params.product.id,
                    Categories: [params.product.category.name],
                    ImageURL: params.images[0].url,
                    URL: window.location.href,
                    Brand: params.product.brand.name,
                    Price: params.price,
                    CompareAtPrice: '',
                    channel: 'samsung'
                };

                klaviyo.push(['track', 'Viewed Product', item]);
                break;
            case 'add-cart':
                if (params.cartItems.length === 0) { return; }

                dataLayer.push({
                    event: 'add-to-cart',
                    AddedItemProductName: params.title,
                    AddedItemProductID: params.product.id,
                    AddedItemImageURL: params.images[0].url,
                    AddedItemURL: window.location.href,
                    AddedItemPrice: params.price,
                    AddedItemQuantity: 1,
                    channel: 'samsung'
                });

                klaviyo.push(['track', 'Added to Cart', {
                    $value: params.cartMeta.total,
                    AddedItemProductName: params.title,
                    AddedItemProductID: params.product.id,
                    AddedItemImageURL: params.images[0].url,
                    AddedItemURL: window.location.href,
                    AddedItemPrice: params.price,
                    AddedItemQuantity: 1,
                    ItemNames: params.cartItems.map((item) => (item.base_product.name)),
                    CheckoutURL: 'https://www.samsung.cinch.sg/checkout/order-summary',
                    channel: 'samsung',
                    Items: params.cartItems.map((item) => ({
                        ProductID: item.base_product.id,
                        SKU: '',
                        ProductName: item.base_product.name,
                        Quantity: item.quantity,
                        ItemPrice: item.total,
                        RowTotal: item.total,
                        ProductURL: window.location.href,
                        ImageURL: item.base_product.medias[0].url
                    }))
                }]);
                break;
            case 'start-checkout':

                if (params.cartItems.length === 0) { return; }

                klaviyo.push(['track', 'Started Checkout', {
                    $event_id: `${params.cartMeta.id}_${Math.round(Date.now() / 1000)}`,
                    $value: params.cartMeta.total,
                    ItemNames: params.cartItems.map((item) => (item.base_product.name)),
                    CheckoutURL: 'https://www.samsung.cinch.sg/checkout/order-summary',
                    Categories: params.cartItems.map((item) => (item.base_product.category.name)),
                    channel: 'samsung',
                    Items: params.cartItems.map((item) => ({
                        ProductID: item.base_product.id,
                        SKU: '',
                        ProductName: item.base_product.name,
                        Quantity: item.quantity,
                        ItemPrice: item.total,
                        RowTotal: item.total,
                        ProductURL: 'https://www.samsung.cinch.sg/product/' + item.base_product.slug,
                        ImageURL: item.base_product.medias[0].url,
                        ProductCategories: [item.base_product.category.name]
                    }))
                }]);
                break;
            case 'payment-added':
                dataLayer.push({
                    event: 'add_payment_info',
                    channel: 'samsung'
                });

                klaviyo.push(['track', 'Payment Added', {
                    channel: 'samsung'
                }]);
                break;
            case 'shipping-added':
                dataLayer.push({
                    event: 'add_shipping_info',
                    channel: 'samsung'
                });

                klaviyo.push(['track', 'Shipping Added', {
                    ZipCode: params.zip_code,
                    channel: 'samsung'
                }]);
                break;
            case 'user-registered':
                klaviyo.push(['track', 'User Registered', {
                    user_id: params.id,
                    email: params.email,
                    channel: 'samsung',
                    referral_code: params.referral_code
                }]);
                break;
            case 'singpass-attempt':
                dataLayer.push({
                    event: 'singpass_submit_cta_click',
                    channel: 'samsung'
                });
                break;
            case 'purchase':
                dataLayer.push({
                    event: 'purchase',
                    channel: 'samsung',
                    ecommerce: {
                        transaction_id: params.transaction_id,
                        value: params.value
                    }
                });
                break;
            default:
                break;
        }
    } catch (err) {
        Sentry.captureException(err);
    }
}

export async function trackFreshChatUser(event, params = {}) {
    var fcWidget = window.fcWidget || [];

    if (!isProduction() || !fcWidget || !fcWidget.user) {
        return;
    }

    try {
        switch (event) {
            case 'identify':
                fcWidget.user.setProperties({
                    externalId: params.email,
                    firstName: params.name,
                    lastName: params.surname,
                    email: params.email
                });
                break;
            default:
                break;
        }
    } catch (err) {
        Sentry.captureException(err);
    }
}
