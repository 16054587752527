import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, UncontrolledAccordion, AccordionBody, AccordionItem, Button } from 'reactstrap';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink as RRNavLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Collapse, Badge } from 'antd';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { ROUTES } from 'config/constants';

import {
    handleLogout,
    loginModal,
    handleOpenSignUp
} from 'redux/reducers/auth';
import { handleFetchingCart } from 'redux/reducers/cart';

import { useQuery, getAuthToken, setReferralParam } from 'utils/helpers';
// import PromoBanner from './PromoBanner/PromoBanner';

import SamsungLogoDesktop from 'assets/images/Samung-Cinc-Logo-Black.png';
import Cart from 'assets/images/shopping-cart.png';
import mobileMenu from 'assets/images/menu.png';

import './Header.scss';

const Header = ({
    loginModal,
    token,
    handleLogout,
    profile
}) => {
    const query = useQuery();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    const { segment } = useSelector(state => ({ segment: state.common.segment }));
    const segmentQuery = segment ? `?segment=${segment}` : '';
    const { products, isAuthed } = useSelector(state => ({
        products: state.cart.fetchedProducts,
        isAuthed: state.auth.isAuthed
    }));
    const cartLocalStorageJSON = localStorage.getItem('products');
    const cartLocalStorage = cartLocalStorageJSON ? JSON.parse(cartLocalStorageJSON) : [];
    const userCart = isAuthed ? products : cartLocalStorage;
    const [cartCount, setCartCount] = useState(userCart?.length ?? 0);
    const { orderPlaced } = useSelector(state => ({ orderPlaced: state.order.orderPlaced }));
    const isCheckoutPages = pathname.includes('checkout');

    const showSignInSignUpPaths = [
        ROUTES.HOME,
        ROUTES.FAQ,
        ROUTES.PRIVACY_POLICY,
        ROUTES.TERMS_AND_CONDITIONS,
        ROUTES.ABOUT_US,
        ROUTES.ORDERS,
        ROUTES.SUBSCRIPTIONS,
        ROUTES.INACTIVE_SUBSCRIPTIONS,
        ROUTES.KNOX_DISCLAIMER,
        ROUTES.Q6B6,
        ROUTES.Q6B6_flip6,
        ROUTES.Q6B6_fold6,
        ROUTES.GALAXY_Z_SEM,
        ROUTES.GALAXY_Z_AFF,
        ROUTES.GALAXY_Z_EDM
    ];
    const showSignInSignUpCta = (showSignInSignUpPaths.includes(pathname) || pathname.indexOf('product') > -1) && token === '';

    const showCartIconPaths = [
        ROUTES.ORDERS,
        ROUTES.MYINFORMATION,
        ROUTES.SUBSCRIPTIONS,
        ROUTES.INACTIVE_SUBSCRIPTIONS,
        ROUTES.HOME,
        ROUTES.FAQ,
        ROUTES.PRIVACY_POLICY,
        ROUTES.TERMS_AND_CONDITIONS,
        ROUTES.ABOUT_US,
        ROUTES.KNOX_DISCLAIMER,
        ROUTES.DOCUMENTSPROOF
    ];

    const showCartIcon = () => {
        if (token === '') return false;

        const q6b6Pages = [
            ROUTES.Q6B6,
            ROUTES.Q6B6_flip6,
            ROUTES.Q6B6_fold6,,
            ROUTES.GALAXY_Z_SEM,
            ROUTES.GALAXY_Z_AFF,
            ROUTES.GALAXY_Z_EDM
        ];

        if (q6b6Pages.includes(pathname) && cartCount === 0) return false;

        if (!(showCartIconPaths.includes(pathname) || pathname.indexOf('product') > -1)) return false;

        return true;
    };

    useEffect(() => {
        if (!orderPlaced) setCartCount(products?.length || cartLocalStorage?.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, cartLocalStorage]);

    useEffect(() => {
        if (!orderPlaced) setCartCount(userCart?.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCart]);

    useEffect(() => {
        if (getAuthToken() && isAuthed && (location.pathname === ROUTES.CART || location.pathname === ROUTES.HOME || location.pathname === ROUTES.ORDER_SUMMARY)) {
            dispatch(handleFetchingCart());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    useEffect(() => {
        if (orderPlaced === true) {
            setCartCount(0);
        }
    }, [orderPlaced]);

    const handleUrlChange = () => setOpen(false);

    useEffect(() => {
        handleUrlChange();
        const unlisten = history.listen(handleUrlChange);

        return () => unlisten();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        const forgot = query.get('forgot');

        if (forgot && forgot === 'true') {
            goToLogin();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginModal, query]);

    const onClose = () => setOpen(false);

    const goToLogin = () => history.push(ROUTES.SIGN_IN);

    const handleLogoutMobile = () => {
        setOpen(false);
        handleLogout();
    };

    const goToCart = () => history.push(ROUTES.CART);

    const pointoutTheSection = (hash) => {
        setOpen(false);

        const hashSection = document.getElementById(hash);

        if (location.pathname === ROUTES.HOME && hashSection) {
            const offset = 50;
            const topPos = hashSection.getBoundingClientRect().top + window.scrollY - offset;

            window.scroll({
                top: topPos,
                behavior: 'smooth'
            });
        } else {
            history.push(`${ROUTES.HOME}${segmentQuery}#${hash}`);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const referralCode = queryParams.get('referral_code');

        if (referralCode !== null) {
            setReferralParam(referralCode);
        }
    }, []);

    return (
        <>
            <nav className='headerNavbar' style={{ position: isCheckoutPages ? 'relative' : 'sticky' }}>
                <NavLink href={`${ROUTES.HOME}${segmentQuery}`}><img className='nav__logo samsung-logo' src={SamsungLogoDesktop} alt='Smasung Logo' /></NavLink>
                <div>
                    {showSignInSignUpCta &&
                        <Button
                            className='auth-btn register__link text-center'
                            onClick={() => {
                                handleOpenSignUp();
                                loginModal(true);
                            }}>
                            {'Sign In/Up'}
                        </Button>
                    }
                    {showCartIcon() && (
                        <NavLink href='#'>
                            <Badge count={cartCount} onClick={goToCart} size='small'>
                                <img src={Cart} className='cartIcon' alt='Icon the Cart' />
                            </Badge>
                        </NavLink>
                    )}
                    {(!isMobile && token !== '') &&
                        <NavLink className='nav__start' onClick={setOpen}>
                            <img className='nav__logo drawer' src={mobileMenu} alt='Mobile menu' />
                        </NavLink>
                    }
                    {(isMobile && token !== '') && <NavLink className='nav__start' onClick={setOpen}><img className='nav__logo drawer' src={mobileMenu} alt='Mobile menu' /></NavLink>}
                </div>
            </nav>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={onClose}
                swipeAreaWidth={250}
                PaperProps={{
                    sx: { width: 250 }
                }}>
                <Box role='presentation' className='mobile__drawer'>
                    <div className='modal__header'>
                        <NavLink href={`${ROUTES.HOME}${segmentQuery}`}><img className='nav__logo samsung-logo' src={SamsungLogoDesktop} alt='Cinch Logo - Link to homepage' /></NavLink>
                    </div>
                    {(token !== '' && profile?.singpass_status && profile?.singpass_status !== 'rejected') && <Collapse accordion={true} ghost={true}
                        expandIconPosition='end'
                        className='drawer__menu'>
                        <UncontrolledAccordion defaultOpen='1'>
                            <AccordionItem>
                                <AccordionBody accordionId='1'>
                                    <NavLink to={ROUTES.PRODUCTS} tag={RRNavLink} className={`${pathname === ROUTES.PRODUCTS ? 'selected__link' : ''}`}>{'Products'}</NavLink>
                                    <NavLink to={ROUTES.MYINFORMATION} tag={RRNavLink} className={`${pathname === ROUTES.MYINFORMATION ? 'selected__link' : ''}`}>{'My Profile'}</NavLink>
                                    <NavLink to={ROUTES.SUBSCRIPTIONS} tag={RRNavLink} className={`${pathname === ROUTES.SUBSCRIPTIONS ? 'selected__link' : ''}`}>{'Subscriptions'}</NavLink>
                                    <NavLink to={ROUTES.PAYMENTINFO} tag={RRNavLink} className={`${pathname === ROUTES.PAYMENTINFO ? 'selected__link' : ''}`}>{'Payment Info'}</NavLink>
                                    <NavLink to={ROUTES.SHIPPINGINFO} tag={RRNavLink} className={`${pathname === ROUTES.SHIPPINGINFO ? 'selected__link' : ''}`}>{'Shipping Details'}</NavLink>
                                    <NavLink to={ROUTES.REFER_AND_EARN} tag={RRNavLink} className={`${pathname === ROUTES.REFER_AND_EARN ? 'selected__link' : ''}`}>{'Refer and Earn'}</NavLink>
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>
                    </Collapse>}
                    {token !== '' && (!profile?.singpass_status || profile?.singpass_status === 'rejected') &&
                        <div>
                            <div className='link__separator' />
                            <NavLink to={ROUTES.PRODUCTS} tag={RRNavLink} className={`${pathname === ROUTES.PRODUCTS ? 'selected__link' : ''}`}>{'Products'}</NavLink>
                            <NavLink to={ROUTES.MYINFORMATION} tag={RRNavLink} className={`${pathname === ROUTES.MYINFORMATION ? 'selected__link' : ''}`}>{'My Profile'}</NavLink>
                            <NavLink to={ROUTES.REFER_AND_EARN} tag={RRNavLink} className={`${pathname === ROUTES.REFER_AND_EARN ? 'selected__link' : ''}`}>{'Refer and Earn'}</NavLink>
                        </div>}
                    <div>
                        <div className='link__separator' />
                        <NavLink onClick={() => pointoutTheSection('how_it_works_section')}>{'How it works'}</NavLink>
                        <NavLink onClick={() => pointoutTheSection('faq')}>{'FAQ'}</NavLink>
                        <NavLink to={ROUTES.PRIVACY_POLICY} tag={RRNavLink} className={`${pathname === ROUTES.PRIVACY_POLICY ? 'selected__link' : ''}`}>{'Privacy Policy'}</NavLink>
                        <NavLink to={ROUTES.TERMS_AND_CONDITIONS} tag={RRNavLink} className={`${pathname === ROUTES.TERMS_AND_CONDITIONS ? 'selected__link' : ''}`}>{'Terms and Conditions'}</NavLink>
                    </div>
                    {(token !== '' && profile?.singpass_status && profile?.singpass_status !== 'rejected') && <button className='mobile__logout_link' onClick={() => handleLogoutMobile()}>{'Logout'}</button>}
                    {(token !== '' && (!profile?.singpass_status || profile?.singpass_status === 'rejected')) &&
                        <>
                            <NavLink onClick={() => handleLogoutMobile()}
                                className='nav__btn login__modal_btn_get_started my__account-btn-sinpass-not-verified cursor-pointer'>{'Logout'}</NavLink>
                        </>}
                </Box>
            </SwipeableDrawer>
            <div className={`elfsight-app-c420cecf-1dd6-4e42-84af-8cf6d8427fbd ${(
                pathname !== ROUTES.CHECKOUT &&
                pathname !== ROUTES.CART &&
                pathname !== ROUTES.SINGPASS &&
                pathname !== ROUTES.SINGPASS_MANUALL_FORM &&
                pathname !== ROUTES.SINGPASS_RESULT &&
                pathname !== ROUTES.SHIPPING &&
                pathname !== ROUTES.PAYMENT &&
                pathname !== ROUTES.ORDER_SUMMARY &&
                pathname !== ROUTES.ORDER_SUMMARY_CONFIRMATION &&
                pathname !== ROUTES.SIGN_IN &&
                pathname !== ROUTES.FORGOT_PASSWORD &&
                pathname !== ROUTES.SIGN_UP
            ) ? 'show' : 'hide'}`} data-elfsight-app-lazy={true} />
            {/* <PromoBanner /> */}
        </>
    );
};

Header.propTypes = {
    loginModal: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
    token: PropTypes.string,
    handleOpenSignUp: PropTypes.func,
    singpassStatus: PropTypes.any,
    profile: PropTypes.object
};

const mapStateToProps = ({ auth }) => {
    const { token, profile } = auth;

    return {
        token,
        singpassStatus: profile.singpass_status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginModal: openLogin => dispatch(loginModal(openLogin)),
        handleLogout: () => dispatch(handleLogout()),
        handleOpenSignUp: () => dispatch(handleOpenSignUp())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
