import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';
import { storeUserEvent } from 'api/user';
import { updateAuthenticationDataFromStateToStore } from 'redux/reducers/user';

import eye from 'assets/images/general/eye.svg';
import eyeOff from 'assets/images/general/eye-off.svg';
import backIcon from 'assets/images/general/back-icon.png';

import style from './ResetPassword.module.scss';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#AAA'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#AAA'
        },
        '&:hover fieldset': {
            borderColor: '#AAA'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#AAA'
        }
    }
});

const ResetPassword = ({ submit, changeForm, onFormDataItemChange, fromPopup, loading }) => {
    const dispatch = useDispatch();
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    const [type, setType] = useState(true);
    const [typeConfirm, setTypeConfirm] = useState(true);
    const [formData, setFormData] = useState({ email: '', password: '' });
    const { password, confirmPassword } = formData;
    const isInValidData = password === '' || password !== confirmPassword;

    const handleChange = (e) => {
        onFormDataItemChange(e.target.name, e.target.value);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = e => {
        e.preventDefault();
        const pass = e.target.password.value;
        const passConf = e.target.confirmPassword.value;

        if (pass === '' || passConf === '') {
            notify('Fields should not be empty.', notifType.DANGER);

            return true;
        }

        if (pass !== passConf) {
            notify('Passwords do not match.', notifType.DANGER);

            return true;
        }

        submit(pass);
    };

    useEffect(() => {
        const triggerUserEvent = async () => {
            const payload = {
                event: 'reset_password'
            };

            await storeUserEvent(payload);
        };

        triggerUserEvent();
    }, []);

    useEffect(() => {
        dispatch(updateAuthenticationDataFromStateToStore(formData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    return (
        <div className={`${style.section} reset-form-section`}>
            <div className='auth-form-header'>
                <img
                    src={backIcon}
                    alt='Back'
                    className='auth-form-header__back'
                    onClick={changeForm} />
                <div className='auth-form-header__title'>Create new password</div>
            </div>
            <div className={style.description}>We&rsquo;ll ask for this password whenever you Sign-In.</div>
            <form onSubmit={handleSubmit} >
                <div className={style.row}>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <CssTextField name='password' type={type ? 'password' : 'text'}
                                className={style.value__input + ' ' + style.withIcon__input}
                                label='New Password*' variant='standard' onChange={handleChange} />
                            <img
                                src={type ? eye : eyeOff}
                                alt='Toggle password visibility button icon'
                                onClick={() => setType(!type)}
                                className={type ? style.withIcon__img : style.withIcon__img + ' ' + style.withIcon__imgActive} />
                        </div>
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <CssTextField name='confirmPassword' type={typeConfirm ? 'password' : 'text'}
                                className={style.value__input + ' ' + style.withIcon__input}
                                label='Re-enter New Password*' variant='standard' onChange={handleChange} />
                            <img
                                src={typeConfirm ? eye : eyeOff}
                                alt='Toggle password visibility button icon'
                                onClick={() => setTypeConfirm(!typeConfirm)}
                                className={typeConfirm ? style.withIcon__img : style.withIcon__img + ' ' + style.withIcon__imgActive} />
                        </div>
                    </div>
                </div>
                {fromPopup && (
                    <div className={style.buttons}>
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Save & Sign in'}
                            className={style.buttons__submit}
                            disabled={isInValidData} />
                    </div>
                )}
                {(!fromPopup && !isMobile) && (
                    <div className={style.buttons}>
                        <Button
                            type='button'
                            title={'Back'}
                            className={`${style.buttons__back} ${style.w_160}`}
                            onClick={changeForm} />
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Save & Sign in'}
                            className={`${style.buttons__submit} ${style.w_160}`}
                            disabled={isInValidData} />
                    </div>
                )}
            </form>
        </div>
    );
};

ResetPassword.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    onFormDataItemChange: PropTypes.func,
    fromPopup: PropTypes.bool,
    loading: PropTypes.bool
};

export default ResetPassword;
