import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { FormGroup, Label, Button as ReactStrapButton } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';
import { validateEmail } from 'utils/helpers';

import { ROUTES } from 'config/constants';

import { updateAuthenticationDataFromStateToStore } from 'redux/reducers/user';
import { storeUserEvent } from 'api/user';

import eye from 'assets/images/general/eye.svg';
import eyeOff from 'assets/images/general/eye-off.svg';
import backIcon from 'assets/images/general/back-icon.png';

import style from './SignUpForm.module.scss';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#AAA'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#AAA'
        },
        '&:hover fieldset': {
            borderColor: '#AAA'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#AAA'
        }
    }
});

const SignUpForm = ({
    submit,
    loading,
    isStudent,
    email: initialEmail,
    backToMainLogin,
    fromPopup,
    onFormDataItemChange
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [type, setType] = useState(true);
    const [typeConfirm, setTypeConfirm] = useState(true);
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    const [formData, setFormData] = useState({ email: initialEmail || '', password: '', confirmPassword: '', subscriptionAcknowledge: true });
    const { password, confirmPassword } = formData;
    const isInValidData = formData.email === '' || formData.password === '' || formData.password !== formData.confirmPassword;
    const [subscriptionAcknowledge, setSubscriptionAcknowledge] = useState(true);

    const handleChange = (e) => {
        onFormDataItemChange(e.target.name, e.target.value);
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name == 'subscriptionAcknowledge') {
            setSubscriptionAcknowledge(!subscriptionAcknowledge);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        const email = (e.target.email.value).replace(/ /g, '');
        const password = e.target.password.value;
        const confirmPassword = e.target.confirmPassword.value;
        const subscriptionAcknowledge = e.target.subscriptionAcknowledge.checked;

        if (!validateEmail(email)) {
            notify('Please enter a valid email address. ', notifType.DANGER);

            return true;
        }

        if (password === '' || confirmPassword === '') {
            notify('Please fill in all required fields.', notifType.DANGER);

            return true;
        }

        if (password !== confirmPassword) {
            notify('Password and Confirm Password do not match.', notifType.DANGER);

            return true;
        }

        if (password.length < 5) {
            notify('Password is too short. Please choose another one.', notifType.DANGER);

            return true;
        }

        submit({
            email,
            password,
            confirmPassword,
            subscriptionAcknowledge
        });
    };

    const backToTheCart = () => history.push(ROUTES.CART);

    useEffect(() => {
        const triggerUserEvent = async () => {
            const payload = {
                event: 'sign_up'
            };

            await storeUserEvent(payload);
        };

        triggerUserEvent();
    }, []);

    useEffect(() => {
        if (initialEmail) {
            setFormData((prevState) => ({ ...prevState, email: initialEmail }));
        }
    }, [initialEmail]);

    useEffect(() => {
        dispatch(updateAuthenticationDataFromStateToStore(formData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    return (
        <div className={style.section}>
            <div className='auth-form-header'>
                <img
                    src={backIcon}
                    alt='Back'
                    className='auth-form-header__back'
                    onClick={backToMainLogin} />
                <div className='auth-form-header__title'>Welcome to Cinch!</div>
            </div>
            <div className={style.description}>Please create an account to continue.</div>
            <form onSubmit={handleSubmit}>
                <div className={style.row}>
                    <div className={style.value}>
                        <CssTextField name='email' type='email' className={style.value__input}
                            label={`${isStudent === 'b2s' ? 'Student ' : ''}Email`} variant='standard'
                            onChange={handleChange} value={formData.email} required='true' />
                    </div>
                </div>
                <div className={`${!fromPopup ? style.page__row : ''}`}>
                    <div className={`${style.row} w-100`}>
                        <div className={style.value}>
                            <div className={style.withIcon}>
                                <CssTextField name='password' type={type ? 'password' : 'text'}
                                    className={style.value__input + ' ' + style.withIcon__input}
                                    label='Create Password' variant='standard' onChange={handleChange}
                                    required='true' />
                                <img
                                    src={type ? eye : eyeOff}
                                    alt='Toggle password visibility'
                                    onClick={() => setType(!type)}
                                    className={type ? style.withIcon__img : style.withIcon__img + ' ' + style.withIcon__imgActive} />
                            </div>
                        </div>
                    </div>
                    <div className={`${style.row} w-100`}>
                        <div className={style.value}>
                            <div className={style.withIcon}>
                                <CssTextField name='confirmPassword' type={typeConfirm ? 'password' : 'text'}
                                    className={style.value__input + ' ' + style.withIcon__input}
                                    label='Re-enter New Password' variant='standard' onChange={handleChange}
                                    required='true' />
                                <img
                                    src={typeConfirm ? eye : eyeOff}
                                    alt='Toggle password visibility'
                                    onClick={() => setTypeConfirm(!typeConfirm)}
                                    className={type ? style.withIcon__img : style.withIcon__img + ' ' + style.withIcon__imgActive} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.row + ' ' + style.marketing_block_container}>
                    <FormGroup className={style.row__fullCol + ' ' + style.marketing_block} check={true}
                        inline={true}>
                        <Radio
                            name='subscriptionAcknowledge'
                            id='subscriptionAcknowledge'
                            checked={subscriptionAcknowledge}
                            onClick={handleChange} />
                        <Label check={true} className='text-justify' htmlFor='subscriptionAcknowledge'>
                            I would like to receive notification, promotional and marketing materials about products and/or services from Cinch Tech Holdings Pte. Ltd. and their respective partners through my contact details provided here in.
                        </Label>
                    </FormGroup>
                </div>
                {fromPopup && (
                    <div className={style.buttons}>
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Sign up'}
                            className={style.buttons__submit}
                            disabled={isInValidData} />
                    </div>
                )}
                {(!fromPopup && !isMobile) && (
                    <div className={style.buttons}>
                        <Button
                            type='button'
                            title={'Back'}
                            className={`${style.buttons__back} ${style.w_160}`}
                            onClick={backToMainLogin} />
                        <Button
                            loading={loading}
                            type='submit'
                            title={'Sign up'}
                            className={`${style.buttons__submit} ${style.w_160}`}
                            disabled={isInValidData} />
                    </div>
                )}
            </form>
        </div>
    );
};

SignUpForm.propTypes = {
    submit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isStudent: PropTypes.bool,
    email: PropTypes.string,
    backToMainLogin: PropTypes.func,
    fromPopup: PropTypes.bool,
    onFormDataItemChange: PropTypes.func
};

export default SignUpForm;
