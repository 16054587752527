import React from 'react';
import PropTypes from 'prop-types';

import FormInput from '../FormInput';
import FormFooter from '../FormFooter';
import FormSelcetion from '../FormSelection';
import { housing, passType, residency } from 'screens/SingpassManualForm/options';

import style from './Form.module.scss';

Fourth.propTypes = {
    errors: PropTypes.object.isRequired,
    setErrors: PropTypes.func.isRequired,
    fourthForm: PropTypes.object.isRequired,
    setFourthForm: PropTypes.func.isRequired,
    handleNextStep: PropTypes.func.isRequired,
    handlePrevStep: PropTypes.func.isRequired,
    errorMsgs: PropTypes.object
};

function Fourth({
    errors,
    setErrors,
    fourthForm,
    setFourthForm,
    handleNextStep,
    handlePrevStep,
    errorMsgs
}) {
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setFourthForm(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: false }));
    };

    return (
        <div className={style.body}>
            <div>
                <div className={style.col}>
                    <FormSelcetion
                        name='residency_status'
                        error={errors.residency_status}
                        errorMsg={errorMsgs.residency_status}
                        value={fourthForm.residency_status ? ({ label: fourthForm.residency_status, value: fourthForm.residency_status }) : null}
                        onChange={handleChange}
                        options={residency}
                        placeholder='Select an option'
                        label='Singapore residency status:'/>
                </div>
                <div className={style.col}>
                    {/* <FormSelcetion
                        name='postal_code'
                        error={errors.postal_code}
                        errorMsg={errorMsgs.postal_code}
                        value={fourthForm.postal_code ? ({ label: fourthForm.postal_code, value: fourthForm.postal_code }) : null}
                        onChange={handleChange}
                        options={samplePostCode}
                        label='What is your postal code?'/> */}
                    <FormInput
                        maxLength={6}
                        type='number'
                        name={'postal_code'}
                        error={errors.postal_code}
                        errorMsg={errorMsgs.postal_code}
                        value={fourthForm.postal_code}
                        onChange={handleChange}
                        placeHolder='Enter your postal code'
                        label='Postal code:' />
                </div>
                <div className={style.col}>
                    <FormInput
                        type='text'
                        name={'residential_address'}
                        error={errors.residential_address}
                        errorMsg={errorMsgs.residential_address}
                        value={fourthForm.residential_address}
                        onChange={handleChange}
                        placeHolder='Enter your registered address'
                        label='Registered full address:' />
                </div>
                <div className={style.col}>
                    <FormSelcetion
                        name='housing_type'
                        error={errors.housing_type}
                        errorMsg={errorMsgs.housing_type}
                        value={fourthForm.housing_type ? ({ label: fourthForm.housing_type, value: fourthForm.housing_type }) : null}
                        onChange={handleChange}
                        options={housing}
                        placeholder='Select an option'
                        label='Housing type:'/>
                </div>
                <div className={style.col}>
                    <FormSelcetion
                        name='pass_type'
                        error={errors.pass_type}
                        errorMsg={errorMsgs.pass_type}
                        value={fourthForm.pass_type ? ({ label: fourthForm.pass_type, value: fourthForm.pass_type }) : null}
                        onChange={handleChange}
                        options={passType}
                        placeholder='Select an option'
                        label='Pass type:'/>
                </div>
                {/* <div className={style.col}>
                    <FormInput
                        type='text'
                        name='pass_status'
                        error={errors.pass_status}
                        errorMsg={errorMsgs.pass_status}
                        value={fourthForm.pass_status}
                        onChange={handleChange}
                        label='What is your pass status?' />
                </div> */}

                <div className={style.col}>
                    <FormInput
                        type='date'
                        name={'workpass_expiry_date'}
                        errorMsg={errorMsgs.workpass_expiry_date}
                        error={errors.workpass_expiry_date}
                        value={fourthForm.workpass_expiry_date}
                        onChange={handleChange}
                        label='When is your pass expiring?' />
                </div>
                <div className={style.col}>
                    <FormFooter
                        prevLabel='Previous'
                        nextLabel='Next'
                        prevClick={handlePrevStep}
                        nextClick={handleNextStep}/>
                </div>
            </div>
        </div>
    );
}

export default Fourth;
