import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Row, Col, Input, FormFeedback, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { DatePicker } from 'antd';
import { useDropzone } from 'react-dropzone';
import { DeleteTwoTone } from '@ant-design/icons';

import { formatDatePickerDate } from 'utils/helpers';
import { notifType, notify } from 'utils/notifSender';

import { storeUserEvent } from 'api/user';
import {
    guarantorInformationFromStateToStore,
    updateSupportingDocumentsDatFromStateToStore
} from 'redux/reducers/user';
import { updateSelectedCartItem } from 'redux/reducers/cart';

import UploadFile from 'assets/images/upload-icon.png';
import { ReactComponent as CrossIcon } from 'assets/images/general/close.svg';

import 'screens/SupportingDocuments/SupportingDocuments.scss';

const SupportingDocuments = ({ profile }) => {
    const dispatch = useDispatch();
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [studentIDCard, setStudentIDCard] = useState(null);
    const [studentVisa, setStudentVisa] = useState(null);
    const [studentIDExpiryDate, setStudentIDExpiryDate] = useState(null);
    const [studentVisaExpiryDate, setStudentVisaExpiryDate] = useState(null);
    const [guarantorName, setGuarantorName] = useState('');
    const [guarantorRelation, setGuarantorRelation] = useState('');
    const [guarantorEmail, setGuarantorEmail] = useState('');
    const [guarantorMobile, setGuarantorMobile] = useState('');
    const { supportingDocsUploaded } = useSelector(state => ({ supportingDocsUploaded: state.user.supportingDocsUploaded }));
    const { cart } = useSelector((state) => ({ cart: state.cart.fetchedProducts }));
    const [isOpenOnImagePreview, setIsOpenOnImagePreview] = useState(false);
    const [currentPreviewImageModal, setCurrentPreviewImageModal] = useState(null);
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    const { getRootProps: getStudentIDCardProps, getInputProps: getInputfileStudentIDCardProps } = useDropzone({
        accept: {
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': []
        },
        maxSize: 4 * 1024 * 1024,
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length > 0) {
                notify('File size exceeds the limit.', notifType.DANGER, 5000);
            } else {
                setStudentIDCard(acceptedFiles[0]);
            }
        }
    });
    const { getRootProps: getStudentVisaProps, getInputProps: getInputfileStudentVisaProps } = useDropzone({
        accept: {
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': []
        },
        maxSize: 4 * 1024 * 1024,
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length > 0) {
                notify('File size exceeds the limit.', notifType.DANGER, 5000);
            } else {
                setStudentVisa(acceptedFiles[0]);
            }
        }
    });

    const disabledDate = (current) => current && current < moment().endOf('day');

    const triggerUserEvent = async () => {
        const payload = {
            event: 'documents_upload'
        };

        await storeUserEvent(payload);
    };

    useEffect(() => {
        triggerUserEvent();
    }, []);

    useEffect(() => {
        if (cart.length === 1) {
            dispatch(updateSelectedCartItem(cart[0]));
        }
    }, [dispatch, cart]);

    useEffect(() => {
        dispatch(updateSupportingDocumentsDatFromStateToStore('studentIDCard', studentIDCard));
        dispatch(updateSupportingDocumentsDatFromStateToStore('studentVisa', studentVisa));

        if (studentIDExpiryDate !== null) {
            dispatch(updateSupportingDocumentsDatFromStateToStore('studentIDExpiryDate', formatDatePickerDate(studentIDExpiryDate)));
        }

        if (studentVisaExpiryDate !== null) {
            dispatch(updateSupportingDocumentsDatFromStateToStore('studentVisaExpiryDate', formatDatePickerDate(studentVisaExpiryDate)));
        }
    }, [dispatch, studentIDCard, studentVisa, studentIDExpiryDate, studentVisaExpiryDate, supportingDocsUploaded]);

    useEffect(() => {
        dispatch(guarantorInformationFromStateToStore('guarantorName', guarantorName));
        dispatch(guarantorInformationFromStateToStore('guarantorRelation', guarantorRelation));

        if (isEmailValid) {
            dispatch(guarantorInformationFromStateToStore('guarantorEmail', guarantorEmail));
        } else {
            dispatch(guarantorInformationFromStateToStore('guarantorEmail', ''));
        }

        dispatch(guarantorInformationFromStateToStore('guarantorMobile', guarantorMobile));
    }, [dispatch, guarantorName, guarantorRelation, guarantorEmail, guarantorMobile, isEmailValid]);

    const onStudentIdCardExpiryDate = date => setStudentIDExpiryDate(date);

    const onStudentVisaExpiryDate = date => setStudentVisaExpiryDate(date);

    const removeTheCurrentStoreObjectOnSupportDocument = (file, fileType) => {
        // eslint-disable-next-line default-case
        switch (fileType) {
            case 'studentIDCard':
                setStudentIDCard(null);
                dispatch(updateSupportingDocumentsDatFromStateToStore('studentIDCard', null));
                break;
            case 'studentVisa':
                setStudentVisa(null);
                dispatch(updateSupportingDocumentsDatFromStateToStore('studentVisa', null));
                break;
        }
    };

    const renderStudentIDCardORVisaPreview = (file, fileType) => {
        if (file) {
            const isImage = file.type.startsWith('image/');
            const preview = isImage ? (
                <div className='SupportingDocuments__account-content__preview__prev__wrapper'>
                    <img src={URL.createObjectURL(file)} alt={file.name} height={100}
                        onClick={() => openImagePreviewModalBox(URL.createObjectURL(file))} />
                    <DeleteTwoTone size={'large'} onClick={() => removeTheCurrentStoreObjectOnSupportDocument(file, fileType)} />
                </div>
            ) : (
                <div className='SupportingDocuments__account-content__preview__img__wrapper'>{file.name}</div>
            );

            return preview;
        }

        return null;
    };

    const closePreviewImage = () => {
        setIsOpenOnImagePreview(false);
        setCurrentPreviewImageModal(null);
    };

    const openImagePreviewModalBox = (image) => {
        setIsOpenOnImagePreview(true);
        setCurrentPreviewImageModal(image);
    };

    return (
        <>
            <div className='SupportingDocuments'>
                <div className='SupportingDocuments__account-content'>
                    {!supportingDocsUploaded &&
                        <Row className='commonPaddingSupportDocs'>
                            {(profile?.is_student && !profile?.pass_proof_of_expiry_required) &&
                                !(isMobile)
                                ? <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                    <div className='d-flex simple-grid__gap'>
                                        <div className='SupportingDocuments__account-content__dropzone'>
                                            <h5 className='SupportingDocuments__account-content__dropzone__label'>
                                                Upload a photo of your Student ID card
                                            </h5>
                                            <div className='SupportingDocuments__account-content__dropzone__internal'>
                                                <section className='w-100 text-center'>
                                                    <div {...getStudentIDCardProps({ className: 'dropzone d-flex flex-column justify-content-center align-items-center w-100' })}>
                                                        <input {...getInputfileStudentIDCardProps()} />
                                                        <img src={UploadFile} alt='' height={64} />
                                                        <p className='SupportingDocuments__account-content__dropzone__internal__itemname'>{studentIDCard ? studentIDCard.name : <><span>Click to upload</span> <span>or</span> <span>Choose file or drag here</span></>}</p>
                                                    </div>
                                                    <p className='SupportingDocuments__account-content__dropzone__label'>
                                                        <span>PDF, PNG OR JPG (max. 800x400px - max: 4mb)</span>
                                                    </p>
                                                </section>
                                            </div>
                                        </div>
                                        <div className='SupportingDocuments__account-content__preview'>
                                            <p className='SupportingDocuments__account-content__dropzone__label'>
                                                Preview of your student ID card
                                            </p>
                                            {renderStudentIDCardORVisaPreview(studentIDCard, 'studentIDCard')}
                                        </div>
                                    </div>
                                </Col>
                                : <>
                                    {(profile?.is_student && !profile?.pass_proof_of_expiry_required) &&
                                        <>
                                            <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                                <div className='d-flex simple-grid__gap flex-col'>
                                                    <div className='SupportingDocuments__account-content__dropzone'>
                                                        <h5 className='SupportingDocuments__account-content__dropzone__label'>
                                                            Upload a photo of your Student ID card
                                                        </h5>
                                                        <div className='SupportingDocuments__account-content__dropzone__internal'>
                                                            <section className='w-100 text-center'>
                                                                <div {...getStudentIDCardProps({ className: 'dropzone d-flex flex-column justify-content-center align-items-center w-100' })}>
                                                                    <input {...getInputfileStudentIDCardProps()} />
                                                                    <img src={UploadFile} alt='' height={64} />
                                                                    <p className='SupportingDocuments__account-content__dropzone__internal__itemname'>{studentIDCard ? studentIDCard.name : <><span>Click to upload</span> <span>or</span> <span>Choose file or drag here</span></>}</p>
                                                                </div>
                                                                <p className='SupportingDocuments__account-content__dropzone__label'>
                                                                    <span>PDF, PNG OR JPG (max. 800x400px - max: 4mb)</span>
                                                                </p>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </>}
                                    {studentIDCard &&
                                        <Col sm='12' className='mobile-margin__gap__bottom'>
                                            <p className='SupportingDocuments__account-content__dropzone__label'>
                                                Preview of your student ID Card
                                            </p>
                                            <div className='SupportingDocuments__account-content__preview'>
                                                {renderStudentIDCardORVisaPreview(studentIDCard, 'studentIDCard')}
                                            </div>
                                        </Col>}
                                </>}

                            {(profile?.is_student && !profile?.pass_proof_of_expiry_required) &&
                                <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                    <section>
                                        <DatePicker onChange={onStudentIdCardExpiryDate} placeholder='Add Expiry Date* (DD/MM/YYYY' value={studentIDExpiryDate}
                                            disabledDate={disabledDate} />
                                    </section>
                                </Col>}

                            {profile?.pass_proof_of_expiry_required &&
                                (!isMobile)
                                ? <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                    <div className='d-flex simple-grid__gap'>
                                        <div className='SupportingDocuments__account-content__dropzone'>
                                            <h5 className='SupportingDocuments__account-content__dropzone__label'>
                                                Upload a photo of your visa
                                            </h5>
                                            <div className='SupportingDocuments__account-content__dropzone__internal'>
                                                <section className='w-100 text-center'>
                                                    <div {...getStudentVisaProps({ className: 'dropzone d-flex flex-column justify-content-center align-items-center w-100' })}>
                                                        <input {...getInputfileStudentVisaProps()} />
                                                        <img src={UploadFile} alt='' height={64} />
                                                        <p className='SupportingDocuments__account-content__dropzone__internal__itemname'>{studentVisa ? studentVisa.name : <><span>Click to upload</span> <span>or</span> <span>Choose file or drag here</span></>}</p>
                                                    </div>
                                                    <p className='SupportingDocuments__account-content__dropzone__label'>
                                                        <span>PDF, PNG OR JPG (max. 800x400px - max: 4mb)</span>
                                                    </p>
                                                </section>
                                            </div>
                                        </div>
                                        <div className='SupportingDocuments__account-content__preview'>
                                            <p className='SupportingDocuments__account-content__dropzone__label'>
                                                Preview of your visa
                                            </p>
                                            {renderStudentIDCardORVisaPreview(studentVisa, 'studentVisa')}
                                        </div>
                                    </div>
                                </Col>
                                : <>
                                    {profile?.pass_proof_of_expiry_required &&
                                        <>
                                            <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                                <div className='d-flex simple-grid__gap flex-col'>
                                                    <div className='SupportingDocuments__account-content__dropzone'>
                                                        <h5 className='SupportingDocuments__account-content__dropzone__label'>
                                                            Upload a photo of your visa
                                                        </h5>
                                                        <div className='SupportingDocuments__account-content__dropzone__internal'>
                                                            <section className='w-100 text-center'>
                                                                <div {...getStudentVisaProps({ className: 'dropzone d-flex flex-column justify-content-center align-items-center w-100' })}>
                                                                    <input {...getInputfileStudentVisaProps()} />
                                                                    <img src={UploadFile} alt='' height={64} />
                                                                    <p className='SupportingDocuments__account-content__dropzone__internal__itemname'>{studentVisa ? studentVisa.name : <><span>Click to upload</span> <span>or</span> <span>Choose file or drag here</span></>}</p>
                                                                </div>
                                                                <p className='SupportingDocuments__account-content__dropzone__label'>
                                                                    <span>PDF, PNG OR JPG (max. 800x400px - max: 4mb)</span>
                                                                </p>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </>}
                                    {studentVisa &&
                                        <Col sm='12' className='mobile-margin__gap__bottom'>
                                            <p className='SupportingDocuments__account-content__dropzone__label'>
                                                Preview of your visa
                                            </p>
                                            <div className='SupportingDocuments__account-content__preview'>
                                                {renderStudentIDCardORVisaPreview(studentVisa, 'studentVisa')}
                                            </div>
                                        </Col>}
                                </>}

                            {profile?.pass_proof_of_expiry_required &&
                                <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                    <section>
                                        <DatePicker onChange={onStudentVisaExpiryDate} placeholder='Add Expiry Date* (DD/MM/YYYY' value={studentVisaExpiryDate}
                                            disabledDate={disabledDate} />
                                    </section>
                                </Col>}
                        </Row>}

                    {(supportingDocsUploaded) &&
                        <Row className='commonPaddingSupportDocs'>
                            <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                <p className='SupportingDocuments__account-content__dropzone__header__label'>
                                    Guarantor Information
                                </p>
                            </Col>
                            <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                <p className='SupportingDocuments__account-content__dropzone__label'>
                                    Guarantor Name
                                </p>
                                <section>
                                    <Input value={guarantorName} name='guarantorName' onChange={e => setGuarantorName(e.target.value)} />
                                </section>
                            </Col>
                            <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                <p className='SupportingDocuments__account-content__dropzone__label'>
                                    Guarantor Relationship
                                </p>
                                <section>
                                    <Input value={guarantorRelation} name='guarantorRelation' onChange={e => setGuarantorRelation(e.target.value)} />
                                </section>
                            </Col>
                            <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                <p className='SupportingDocuments__account-content__dropzone__label'>
                                    Guarantor Email
                                </p>
                                <section>
                                    <Input
                                        type='email'
                                        value={guarantorEmail}
                                        name='guarantorEmail'
                                        onChange={(e) => {
                                            setGuarantorEmail(e.target.value);
                                            const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);

                                            setIsEmailValid(isValid);
                                        }}
                                        invalid={!isEmailValid}
                                        noValidate={true} />
                                    <FormFeedback>Invalid email format</FormFeedback>
                                </section>
                            </Col>
                            <Col sm='12' className='d-flex flex-column SupportingDocuments__account-content__col'>
                                <p className='SupportingDocuments__account-content__dropzone__label'>
                                    Guarantor Mobile
                                </p>
                                <section>
                                    <Input type='text' value={guarantorMobile} name='guarantorMobile'
                                        onChange={e => setGuarantorMobile(e.target.value)} />
                                </section>
                            </Col>
                        </Row>}
                </div>
            </div>
            <Modal
                size='lg'
                isOpen={isOpenOnImagePreview}
                centered={true}>
                <ModalHeader className='Preview__modal__header__heading'>
                    <span>Preview</span>

                    <button onClick={closePreviewImage} className='Preview__modal__header__heading__button'>
                        <CrossIcon />
                    </button>
                </ModalHeader>

                <ModalBody>
                    <div className='Preview__modal__body'>
                        <img className='Preview__modal__body__img' src={currentPreviewImageModal} alt='' />
                        <div className='d-flex justify-content-end align-items-center'>
                            <button onClick={closePreviewImage}>
                                OK
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

SupportingDocuments.propTypes = {
    profile: PropTypes.object
};

export default SupportingDocuments;
