import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { storeUserEvent } from 'api/user';

import {
    handleFetchSingpassData,
    handleSingpassSubmitData
} from 'redux/reducers/auth';
import MyInformationForm from '../../screens/Profile/components/MyInformation/Form/Form';
import SingPassVerification from '../../screens/Profile/components/SingPassVerification/SingPassVerification';
import { notifType, notify } from 'utils/notifSender';

import { ROUTES } from 'config/constants';

import SingPassLogo from 'assets/images/general/singpass-logo.png';

import '../../screens/Profile/components/MyInformation/MyInformation.scss';
import './SingPassScreen.scss';

const formConfig = {
    defaultValues: {
        name: '',
        aliasName: '',
        sex: '',
        maritalStatus: '',
        marriedName: '',
        dateOfBirth: '',
        countryOfBirth: '',
        race: '',
        secondaryRace: '',
        residencyStatus: '',
        residentialAddress: '',
        nric: '',
        workPassExpiryDate: '',
        email: '',
        mobile: '',
        occupation: '',
        typeOfHousing: '',
        nameOfEmployer: '',
        employmentSector: '',
        income: '',
        assessedYearOfIncome: '',
        passType: '',
        passStatus: ''
    }
};

const SingPassScreen = () => {
    const {
        profile,
        singPassVerified,
        includeNoa
    } = useSelector((state) => ({
        profile: state.auth.profile,
        singPassVerified: state.auth?.profile?.singpass_status,
        includeNoa: state.auth.includeNoa
    }));
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        setValue,
        reset,
        formState: { errors },
        watch
    } = useForm(formConfig);
    const formData = watch();
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    const [, setRemainingVerifications] = useState([]);
    const [, setMessageModalOpen] = useState(false);
    const [, setMessageModalMessage] = useState({ type: '', message: '' });
    const [isLoading, setIsLoading] = useState(false);
    const { maritalStatus, marriedName, residentialAddress, email, mobile } = formData;
    const isSubmitBtnDisabled = !residentialAddress || !email || !mobile;

    useEffect(() => {
        const userData = profile?.singpass;

        reset({
            name: userData?.legal_name,
            aliasName: userData?.alias_name,
            sex: userData?.sex,
            maritalStatus: userData?.martial_status,
            marriedName: userData?.maried_name,
            dateOfBirth: userData?.dob,
            countryOfBirth: userData?.birth_country,
            race: userData?.ethnicity,
            secondaryRace: userData?.secondary_race,
            residencyStatus: userData?.residency_status,
            residentialAddress: userData?.residential_address,
            nric: userData?.nric_fin,
            workPassExpiryDate: userData?.workpass_expiry_date,
            email: userData?.email_address,
            mobile: userData?.mobile,
            occupation: userData?.occupation,
            typeOfHousing: userData?.housing_type,
            nameOfEmployer: userData?.employment_sector,
            employmentSector: userData?.employer_name,
            income: userData?.income_noa,
            assessedYearOfIncome: userData?.assessed_year_income,
            passType: userData?.pass_type,
            passStatus: userData?.pass_status
        });
    }, [profile?.singpass, reset]);

    useEffect(() => {
        if (singPassVerified !== 'rejected' && !profile.singpass) {
            setIsLoading(true);

            dispatch(handleFetchSingpassData(() => setIsLoading(false), () => setIsLoading(false)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const triggerUserEvent = async () => {
            const payload = {
                event: 'singpass'
            };

            await storeUserEvent(payload);
        };

        triggerUserEvent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onToggleLoading = () => setIsLoading(!isLoading);

    const onFormDataItemChange = (key, e) => setValue(key, e.target.value);

    const onAuthorizedSingpass = redirectUrl => {
        window.location.href = redirectUrl;
    };

    const onRejectSingpassAuthorization = includeNoa => {
        setIsLoading(false);

        if (!includeNoa) {
            setMessageModalMessage({ type: 'failed', message: 'Unable to retrieve your information from Singpass.' });
        } else {
            setMessageModalMessage({
                type: 'failed',
                message: `
                We are sorry, you are not eligible for our services at the moment.
                If you feel that this is a mistake or to better understand our policies
                please feel free to contact us at support@cinch.sg or +65 8168-8604.
`
            });
        }

        setMessageModalOpen(true);
    };

    const onFormSubmitSuccess = data => {
        const userData = profile?.singpass;

        setIsLoading(false);

        if (data && data.action === 'otp_required') {
            if (userData?.email_address !== email || userData?.mobile !== mobile) {
                const newVerifications = [];

                if (userData?.email_address !== email) newVerifications.push('email');
                if (userData?.mobile !== mobile) newVerifications.push('mobile');

                setRemainingVerifications(newVerifications);
            }
        } else {
            notify('Thank you! We have received your information from Singpass.', notifType.SUCCESS);

            // if (profile.pass_proof_of_expiry_required && profile.is_student) {
            //     setTimeout(() => {
            //         history.push(ROUTES.DOCUMENTSPROOF);
            //     }, 1000);
            // } else {
            //     setTimeout(() => {
            //         history.push(ROUTES.ORDER_SUMMARY);
            //     }, 1000);
            // }
            setTimeout(() => {
                history.push(ROUTES.ORDER_SUMMARY);
            }, 1000);
        }
    };

    const onFormSubmitFailure = () => {
        setIsLoading(false);

        notify('Unable to retrieve your information. Please check all fields.', notifType.DANGER);
    };

    const onSubmit = () => {
        if (mobile.length < 10) {
            notify('Please enter the correct phone number.', notifType.DANGER);

            return;
        }

        if (!email || email === '') {
            notify('Please enter your email.', notifType.DANGER);

            return;
        }

        setIsLoading(true);

        const form = {
            residential_address: residentialAddress,
            email_address: email,
            mobile: mobile.includes('+') ? mobile : '+' + mobile
        };

        if (maritalStatus !== '') {
            form.martial_status = maritalStatus;
        }

        if (marriedName !== '') {
            form.maried_name = marriedName;
        }

        dispatch(handleSingpassSubmitData(form, onFormSubmitSuccess, onFormSubmitFailure));
    };

    return (
        <div className='container MyInformation SingPassContainer'>
            {(singPassVerified === 'verified') && (
                <>
                    <div className='MyInformation__singpass-logo'>
                        <img src={SingPassLogo} alt='SingPass Logo' />
                    </div>

                    <div className='MyInformation__header'>
                        <span className='MyInformation__header__heading'>
                            My Information
                        </span>
                    </div>

                    <MyInformationForm
                        includeNoa={includeNoa}
                        formData={formData}
                        onFormDataItemChange={onFormDataItemChange}
                        errors={errors}
                        isFormDisabled={false} />

                    <div className='MyInformation__singpass-button-container'>
                        <Button className='MyInformation__singpass-button'
                            disabled={isSubmitBtnDisabled}
                            onClick={() => onSubmit()}>
                            Continue
                        </Button>
                    </div>

                </>
            )}

            {(!singPassVerified || (singPassVerified === 'rejected')) &&
                <div>
                    <SingPassVerification
                        isMobile={isMobile}
                        onToggleLoading={onToggleLoading}
                        onAuthorizedSingpass={onAuthorizedSingpass}
                        onRejectSingpassAuthorization={onRejectSingpassAuthorization} />
                </div>
            }
        </div>
    );
};

export default SingPassScreen;
