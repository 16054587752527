import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
import * as FS from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { Sha1 } from '@aws-crypto/sha1-browser';
import { v4 as uuidv4 } from 'uuid';

import { createWebHistoryStack, clearWebHistoryStack } from 'utils/helpers';

import AppRouter from './components/Router/AppRouter';
import CustomLinearProgress from 'containers/App/components/Loading/CustomLinearProgress';
import { handleAutoLogin, loginModal } from 'redux/reducers/auth';
import LoginModal from './components/LoginModal/LoginModal';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import UtmParamsChecker from 'components/UtmParamsHandler';
import ErrorBoundary from './DefaultErrorBoundary';

import { ROUTES, CHATBOT_ROUTES } from 'config/constants';

import { trackFreshChatUser, trackKlaviyoEvent } from 'api/tracking';
import { getAuthedProfile } from 'api/user';
import { setSegment } from 'redux/reducers/common';

import 'react-notifications-component/dist/theme.css';
import './AppContainer.scss';

const AppContainer = ({
    location,
    autoLogin,
    isAppReady,
    isAuthed,
    language,
    openLogin,
    profile,
    loginModal
}) => {
    const hash = new Sha1();
    const history = useHistory();
    const { pathname } = location;
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);
    const [isIdle, setIsIdle] = useState(false);
    var idleTimer;

    ReactGA.initialize('G-L69Z2CN0LK');
    ReactGA.gtag('config', 'GTM-NRZ5357');
    TiktokPixel.init('CDTDC8RC77U48G2OG19G');
    TiktokPixel.pageView();

    useEffect(() => autoLogin(), [autoLogin]);

    useEffect(() => {
        const data = { hitType: 'pageview', page: pathname };

        if (profile.id) {
            data.userID = profile.id;
            data.userEmail = profile.email;

            if (FS) {
                FS.identify(data.userID.toString(), {
                    displayName: data.userID.toString()
                });
            }

            trackKlaviyoEvent('identify', data);

            trackFreshChatUser('identify', profile);

            const _hsq = window._hsq = window._hsq || [];

            _hsq && _hsq.push(['setPath', pathname]);
        }

        ReactGA.send(data);

        const _hsq = window._hsq = window._hsq || [];

        _hsq.push(['setPath', pathname]);

        if (location.pathname === ROUTES.HOME) {
            const segment = new URLSearchParams(location.search).get('segment');

            dispatch(setSegment(segment));
        }

        createWebHistoryStack(location);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, isAppReady, isAuthed, language, openLogin, profile]);

    const handleActivity = () => {
        clearTimeout(idleTimer);

        // Display message when user becomes idle
        if (isIdle) {
            setIsIdle(true);
        }

        setIsIdle(false);

        idleTimer = setTimeout(() => {
            setIsIdle(true);
        }, 15000);
    };

    const onError = (error, info) => {
        Sentry.withScope((scope) => {
            scope.setExtras('Info', info);
            Sentry.captureException(error);
        });

        setIsError(true);
    };

    const closeLoginModal = () => {
        loginModal(false);
    };

    useEffect(() => clearWebHistoryStack(), []);

    useEffect(() => {
        let uuid = null;

        if (!localStorage.getItem('uuid')) {
            uuid = uuidv4();
            localStorage.setItem('uuid', uuid);
        } else {
            uuid = localStorage.getItem('uuid');
        }

        if (isAuthed) {
            getAuthedProfile().then(async (res) => {
                hash.update(res?.data?.email);
                const result = await hash.digest();

                window.ire('identify', {
                    customerId: res?.data?.id,
                    customerEmail: result,
                    customProfileId: uuid
                });
            });
        } else {
            window.ire('identify', {
                customerId: '',
                customerEmail: '',
                customProfileId: uuid
            });
        }

        overrideChatWidgetStyle(location?.pathname);
    }, [isAuthed, location]);

    useEffect(() => {
        document.addEventListener('click', handleActivity);
        document.addEventListener('keydown', handleActivity);
        document.addEventListener('keyup', handleActivity);
        document.addEventListener('touchstart', handleActivity);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        idleTimer = setTimeout(() => {
            setIsIdle(true);
        }, 15000);

        return () => {
            document.removeEventListener('click', handleActivity);
            document.removeEventListener('keydown', handleActivity);
            document.removeEventListener('keyup', handleActivity);
            document.removeEventListener('touchstart', handleActivity);
            // clearTimeout(idleTimer);
        };
    }, []);

    // Let's keep this code for the future reference in any case the requirments gets changed ...
    const overrideChatWidgetStyle = (path) => {
        var style = document.createElement('style');

        style.type = 'text/css';
        var cssRule = '#fc_widget { display: block !important; }';

        if (CHATBOT_ROUTES.includes(path)) {
            cssRule = '#fc_widget { display: none !important; }';
            setTimeout(() => {
                style.innerHTML = '#fc_widget { display: block !important; }';
            }, 10000);
        }

        if (style.styleSheet) {
            style.styleSheet.cssText = cssRule;
        } else {
            style.appendChild(document.createTextNode(cssRule));
        }

        const head = document.getElementsByTagName('head')[0];
        const existingStyle = document.getElementById('chatWidgetStyle');

        if (existingStyle) {
            head.removeChild(existingStyle);
        }

        style.id = 'chatWidgetStyle';
        head.appendChild(style);
    };

    if (isAppReady) {
        return (
            <>
                <main className='AppContainer' id='mainAppContainer'>
                    <LoginModal isOpen={openLogin} onToggle={closeLoginModal} />
                    <ReactNotifications className='pt-notifs' />
                    <ErrorBoundary pathname={pathname} onError={onError}>
                        <>
                            <ScrollToTop />
                            <AppRouter isAuthed={isAuthed} profile={profile} />
                            <UtmParamsChecker isAuthed={isAuthed} />
                        </>
                    </ErrorBoundary>
                </main>
            </>
        );
    }

    return (
        <main className='AppContainer'>
            {isError ? (
                <div>Error occurred!</div>
            ) : (
                <CustomLinearProgress loading={true} />
            )}
        </main>
    );
};

AppContainer.propTypes = {
    location: PropTypes.object.isRequired,
    autoLogin: PropTypes.func.isRequired,
    isAppReady: PropTypes.bool.isRequired,
    isAuthed: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    openLogin: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    loginModal: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth, i18n }) => {
    const { language } = i18n;
    const { isAppReady, isAuthed, openLogin, profile } = auth;

    return {
        isAppReady,
        isAuthed,
        language,
        openLogin,
        profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        autoLogin: (location) => dispatch(handleAutoLogin(location)),
        loginModal: openLogin => dispatch(loginModal(openLogin))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
