import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import { ROUTES } from 'config/constants';

import SamsungCinchLogo from 'assets/images/SamsungCinchLogo.png';
import FacebookIcon from 'assets/images/footer_icon-facebook.png';
import InstagramIcon from 'assets/images/footer_icon-instagram.png';
import LinkedInIcon from 'assets/images/footer_icon-linkedin.png';

import './Footer.scss';

export default function Footer() {
    const currentRoute = window.location.pathname.split('/')[1];
    const noShowPages = [
        'sign-up',
        'sign-in',
        'checkout',
        'forgot-password'
    ];

    const footerClass = () => {
        let className = 'footer';

        switch (currentRoute) {
            case 'product':
                className += ' marginAlways';
                break;
            case 'sign-in':
            case 'sign-up':
                className += ' marginOnMobile';
                break;
            case 'checkout':
                className += ' marginCheckout';
            default:
                break;
        }

        return className;
    };

    if (noShowPages.includes(currentRoute)) return null;

    return (
        <footer className={footerClass()}>
            <img src={SamsungCinchLogo} alt='Samsung | Cinch' className='logo' />
            <div className='footerTop'>
                <ul className='information'>
                    <li>Official Samsung Partner</li>
                    <li>160 Robinson Road, 14-04, 068593,<br />Singapore</li>
                    <li>UEN: 202221862D</li>
                </ul>
                <nav className='externalLinks'>
                    <ul>
                        <li><a href='https://www.cinch.sg/' target='_'>Home</a></li>
                        <li><a href='https://www.cinch.sg/products/' target='_'>View All Products</a></li>
                        <li><a href='https://cinch.sg/business/' target='_'>For Businesses</a></li>
                    </ul>
                </nav>
                <nav className='internalLinks'>
                    <ul>
                        <li><ScrollToID id='faq' name='FAQ' /></li>
                        <li><ScrollToID id='how_it_works_section' name='How It Works' /></li>
                        <li><Link to={ROUTES.TERMS_AND_CONDITIONS}>Terms & Conditions</Link></li>
                        <li><Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link></li>
                    </ul>
                </nav>
                <div className='contactAndReviews'>
                    <ul>
                        <li>
                            <a href={ROUTES.CONTACT_US}>
                                <Button className='contactAndReviewsBtn'>Contact Us</Button>
                            </a>
                        </li>
                        <li>
                            <a href='https://g.page/r/Cbole4zs90GBEAI/review' target='_'>
                                <Button className='reviewBtnStyle'>Leave a Review</Button>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr />
            <div className='footerBottom'>
                <p>© 2023 Cinch Tech Holding Pte. Ltd. All rights reserved.</p>
                <div>
                    <a href='https://www.facebook.com/cinchtechsg' target='_'>
                        <img src={FacebookIcon} alt='Facebook' />
                    </a>
                    <a href='https://www.instagram.com/cinchtechsg/' target='_'>
                        <img src={InstagramIcon} alt='Instagram' />
                    </a>
                    <a href='https://www.linkedin.com/company/cinch-asia' target='_'>
                        <img src={LinkedInIcon} alt='LinkedIn' />
                    </a>
                </div>
            </div>
        </footer >
    );
}

const ScrollToID = ({ id, name }) => {
    const scrollToElement = () => {
        const element = document.querySelector(`#${id}`);

        if (element) {
            const offsetVal = id === 'faq' ? 3.5 : 5.5;
            const offset = offsetVal * parseFloat(getComputedStyle(document.documentElement).fontSize);
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;

            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth'
            });
        } else {
            setTimeout(scrollToElement, 1000);
        }
    };

    return (
        <Link to={ROUTES.HOME} onClick={scrollToElement}>
            {name}
        </Link>
    );
};

ScrollToID.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};
