import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import * as FS from '@fullstory/browser';
import { trackFreshChatUser, trackKlaviyoEvent } from 'api/tracking';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'underscore';

import { handleLogin, toggleInvoiceModal, handleRegistration } from 'redux/reducers/auth';
import { addItemsToCart, getUserCart } from 'api/cart';
import { handleFetchingCart } from 'redux/reducers/cart';
import { getUserIP, getUserEmailAvailability, getOTPForForgotPassword, checkOTPCode, passwordReset } from 'api/user';
import { notifType, notify } from 'utils/notifSender';
import { ROUTES, AUTH_API_URL } from 'config/constants';
import { getLastPageUrlWithoutAuth, getReferralParam } from 'utils/helpers';

import LoginForm from 'containers/App/components/LoginModal/elements/LoginForm/LoginForm';
import SignUpForm from 'containers/App/components/LoginModal/elements/SignUpForm/SignUpForm';
import ForgotForm from 'containers/App/components/LoginModal/elements/ForgotForm/ForgotForm';
import AuthOTPForm from 'containers/App/components/LoginModal/elements/OTPForm/AuthOTPForm';
import ResetPassword from 'containers/App/components/LoginModal/elements/ResetPassword/ResetPassword';
import InitialLoginForm from 'containers/App/components/InitialLoginForm/InitialLoginForm';
import StickyBottom from 'components/StickyBottom/StickyBottom';
import InvoiceModal from 'containers/App/components/InvoiceModal/InvoiceModal';

import './AuthForm.scss';

const AuthForm = ({ handleLogin, handleRegistration, fromPopup = false, initialForm = '', initialLoginFormContainerClass = '' }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const otpTimerDuration = 90;
    const { authData } = useSelector(state => ({ authData: state.user.authData }));
    const products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];
    const { profile } = useSelector(state => ({ profile: state.auth.profile }));
    const [ip, setIP] = useState(null);
    const [doLogin, setDoLogin] = useState(false);
    const [doSignup, setDoSignup] = useState(false);
    const loginFormConfig = {
        defaultValues: {
            email: '',
            password: ''
        }
    };
    const {
        setValue,
        watch
    } = useForm(loginFormConfig);
    const formData = watch();
    const signupFormConfig = {
        defaultValues: {
            email: '',
            password: '',
            confirmPassword: '',
            subscriptionAcknowledge: false
        }
    };
    const {
        setValue: setValueForSignUpForm,
        watch: watchForSignUpForm
    } = useForm(signupFormConfig);
    const signupFormData = watchForSignUpForm();
    const resetPasswordFormConfig = {
        defaultValues: {
            newPassword: '',
            confirmPassword: ''
        }
    };
    const { watch: watchForResetPasswordForm, setValue: setValueForResetPasswordForm } = useForm(resetPasswordFormConfig);
    const resetPasswordFormData = watchForResetPasswordForm();
    const [loginResponse, setLoginResponse] = useState(null);
    const [emailId, setEmailId] = useState('');
    const [emailForAutoFill, setEmailForAutoFill] = useState('');
    const [form, setForm] = useState(initialForm || '');
    const [loading, setLoading] = useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const [timer, setTimer] = useState(otpTimerDuration);
    const [forgotOtp, setForgotOtp] = useState(false);
    const [otp, setOtp] = useState('');
    const {
        newPassword,
        confirmPassword
    } = resetPasswordFormData;

    const onFormDataItemChange = (key, e) => setValue(key, e.target.value);

    const isValidSignupData = () => {
        return (
            !isEmpty(signupFormData.email) &&
            !isEmpty(signupFormData.password) &&
            !isEmpty(signupFormData.confirmPassword) &&
            signupFormData.password === signupFormData.confirmPassword
        );
    };

    const submitLogin = async () => {
        login(formData);
    };

    const login = (payload) => {
        setLoading(true);
        handleLogin({
            email: payload.email,
            password: payload.password,
            gaSessionId: Cookies.get('_ga') || 'no-session',
            trueIp: ip || 'no-ip',
            onSuccess: (resp) => {
                setLoginResponse(resp);
                setDoLogin(true);
                dispatch(handleFetchingCart());
            },
            onFailure: () => {
                setLoading(false);
            }
        });
    };

    const redirect = () => {
        const redirectUrl = getRedirectUrl();

        if (redirectUrl !== null) {
            history.push(redirectUrl);
        } else {
            history.push(getLastPageUrlWithoutAuth());
        }
    };

    const sendProductsToBE = async () => {
        const makeProducts = [];
        const profileTypeAPI = 'b2c';
        let selectedRentalPeriodIdOnEmptyCases = null;
        const userType = 'b2c';
        const selectedRentalPeriod = JSON.parse(localStorage.getItem('selectedRentalPeriod'));
        const selectedColorOption = JSON.parse(localStorage.getItem('selectedColorOption'));

        for (const product of products) {
            const selectedRentalPeriodId = getRentalPeriodId(product);

            if (!isStudentPriceAvailable(product)) {
                if (selectedRentalPeriodId === '') {
                    const selectedRentalPeriodTemp = product?.rental_period.filter(r => r?.duration_time === selectedRentalPeriod?.duration_time || selectedRentalPeriod[0]?.duration_time);

                    selectedRentalPeriodIdOnEmptyCases = selectedRentalPeriodTemp[0]?.id;
                } else {
                    selectedRentalPeriodIdOnEmptyCases = selectedRentalPeriodId;
                }
            } else if (userType === 'b2c') {
                if (userType === profileTypeAPI) {
                    if (selectedRentalPeriodId === '') {
                        const selectedRentalPeriodForB2cTemp = product?.rental_period.filter(r => r?.duration_time === selectedRentalPeriod?.duration_time || selectedRentalPeriod[0]?.duration_time);

                        selectedRentalPeriodIdOnEmptyCases = selectedRentalPeriodForB2cTemp.filter(r => r?.user_type === 'b2c')[0]?.id;
                    } else {
                        selectedRentalPeriodIdOnEmptyCases = selectedRentalPeriodId;
                    }
                } else {
                    const durationTime = product?.rental_period.filter(r => r?.duration_time === selectedRentalPeriod[0]?.duration_time);

                    selectedRentalPeriodIdOnEmptyCases = durationTime.filter(d => d?.user_type === userType)[0]?.id;
                }
            } else {
                if (userType === profileTypeAPI) {
                    if (selectedRentalPeriodId === '') {
                        const selectedRentalPeriodForB2sTemp = product?.rental_period.filter(r => r?.duration_time === selectedRentalPeriod?.duration_time || selectedRentalPeriod[0]?.duration_time);

                        selectedRentalPeriodIdOnEmptyCases = selectedRentalPeriodForB2sTemp.filter(r => r?.user_type === 'b2s')[0]?.id;
                    } else {
                        selectedRentalPeriodIdOnEmptyCases = selectedRentalPeriodId;
                    }
                } else {
                    const durationTime = product?.rental_period.filter(r => r?.duration_time === selectedRentalPeriod[0]?.duration_time);

                    selectedRentalPeriodIdOnEmptyCases = durationTime.filter(d => d?.user_type === userType)[0]?.id;
                }
            }

            makeProducts.push(
                {
                    is_purchase: false,
                    product_attribute_value_id: product.selectedStorage ? product.selectedStorage.value
                        : (
                            product.attributes.specifications.values[0].id ||
                            product.attributes.inside_box.values[0].id
                        ),
                    quantity: 1,
                    rental_period_id: (selectedRentalPeriodId === '' || isEmpty(selectedRentalPeriodId)) ? selectedRentalPeriodIdOnEmptyCases : selectedRentalPeriodId,
                    rental_period_duration: product?.rental_period?.find(rp => rp.id === selectedRentalPeriodId)?.duration_time,
                    product_id: product.id,
                    color_id: product?.selectedColor?.value || selectedColorOption?.value,
                    deal_id: null
                }
            );
        }

        try {
            await addItemsToCart(makeProducts);

            const resp = await getUserCart();

            if (resp?.data?.products?.length > 0) {
                notify(`Product${makeProducts.length > 1 ? 's' : ''} added to cart successfuly`, notifType.SUCCESS);

                if (getLastPageUrlWithoutAuth().includes('product/')) {
                    window.location.href = ROUTES.CART;
                }
            }

            if (loginResponse && (loginResponse.orderNumber || loginResponse.invoiceUrl)) {
                //
            } else {
                redirect();
            }
        } catch (err) {
            redirect();
        }
    };

    const getRentalPeriodId = (product) => {
        const userType = 'b2c';
        const durationTime = product?.rental_period?.find(rp => rp.id === product?.selectedRentalPeriod?.id)?.duration_time;
        let rentalPeriod = product?.rental_period?.find(rp => rp.duration_time === durationTime);

        if (isStudentPriceAvailable(product)) {
            rentalPeriod = product?.rental_period?.find(rp => rp.duration_time === durationTime && rp.user_type === userType);
        }

        return rentalPeriod?.id ?? '';
    };

    const isStudentPriceAvailable = (product) => {
        const studentObj = product?.rental_period?.find(rp => rp?.user_type === 'b2s');

        return studentObj !== undefined && studentObj !== null;
    };

    const getData = async () => {
        try {
            const res = await getUserIP();

            setIP(res);
        } catch (error) {
            //
        }
    };

    const onForgotPassword = (form, email = null) => {
        setForgotPasswordEmail(email);
        setForm('forgot-password');
    };

    const getRedirectUrl = () => {
        const queryParams = new URLSearchParams(location.search);

        return queryParams.get('redirect_url');
    };

    const backToMainLogin = () => setForm('');

    const checkEmailAvailability = async () => {
        setLoading(true);
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const email = emailId;

        if (email === null || email.trim() === '') {
            notify('Please enter email', notifType.DANGER);
            setLoading(false);

            return false;
        }

        if (!emailPattern.test(email)) {
            notify('Please enter email', notifType.DANGER);
            setLoading(false);

            return false;
        }

        try {
            const data = await getUserEmailAvailability(email);

            if (data?.exists) {
                setEmailForAutoFill(email);
                setValue('email', email);
                setForm('signin');
            } else {
                onSignUpFormDataItemChange('email', email);
                setEmailForAutoFill(email);
                setForm('signup');
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSocialLogin = (provider) => {
        const storageReferral = getReferralParam();

        window.location.href = `${AUTH_API_URL}/auth/oauth/redirect/${provider}?channel=cinch&referral_code=${storageReferral}`;
    };

    const requestOtp = () => {
        setOtp('');
        getOTPForForgotPassword(forgotPasswordEmail,
            () => {
                setForm('forgot-password-otp');
                setForgotOtp(true);
                setTimer(otpTimerDuration);
                notify('OTP code sent to your email.', notifType.SUCCESS);
                setLoading(false);
            },
            () => {
                notify('Invalid email used.', notifType.DANGER);
                setLoading(false);
            });
    };

    const submitSignUp = () => {
        setLoading(true);

        handleRegistration({
            ...signupFormData,
            gaSessionId: Cookies.get('_ga') || 'no-session',
            trueIp: ip || 'no-ip',
            onSuccess: () => setDoSignup(true),
            onFailure: (err) => {
                setLoading(false);

                if (err.response.status === 422) {
                    setForm('signin');
                }
            }
        });
    };

    const submitForgotPassword = async (email) => {
        setLoading(true);
        requestOtp();
    };

    const handleResendCode = () => {
        setLoading(true);
        requestOtp();
    };

    const handleSubmitOtp = async (type) => {
        setLoading(true);

        try {
            await checkOTPCode(otp,
                () => {
                    setForgotOtp(false);
                    setForm('reset-password');
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                    notify('The OTP code is incorrect.', notifType.DANGER);
                    setOtp('');
                });
        } catch (error) {
            if (error.response.status) {
                notify('The OTP code is incorrect.', notifType.DANGER);
                setOtp('');
            }

            setLoading(false);
        }
    };

    const onResetPasswordFormDataItemChange = (key, value) => {
        switch (key) {
            case 'password':
                setValueForResetPasswordForm('newPassword', value);
                break;
            case 'confirmPassword':
                setValueForResetPasswordForm('confirmPassword', value);
                break;
            default:
                break;
        }
    };

    const onSignUpFormDataItemChange = (key, value) => {
        switch (key) {
            case 'email':
                setValueForSignUpForm('email', value);
                break;
            case 'password':
                setValueForSignUpForm('password', value);
                break;
            case 'confirmPassword':
                setValueForSignUpForm('confirmPassword', value);
                break;
            case 'subscriptionAcknowledge':
                setValueForSignUpForm('subscriptionAcknowledge', value);
                break;
            default:
                break;
        }
    };

    const submitResetPassword = async () => {
        setLoading(true);

        try {
            await passwordReset(
                {
                    otp_code: otp,
                    password: newPassword,
                    password_confirmation: confirmPassword
                },
                () => {
                    notify('Password changed successfully.', notifType.SUCCESS);
                    login({ email: forgotPasswordEmail, password: newPassword });
                    setForgotOtp(false);
                    setOtp('');
                },
                () => {
                    setLoading(false);
                    notify('Please try again.', notifType.DANGER);
                }
            );
        } catch (error) {
            setLoading(false);

            if (error.response.status) {
                notify('Please try again.', notifType.DANGER);
            }
        }
    };

    useEffect(() => {
        if (forgotOtp) {
            const time = setTimeout(() => {
                if (timer > 0) {
                    setTimer(timer - 1);
                }
            }, 1000);

            return () => clearTimeout(time);
        } else {
            setTimer(otpTimerDuration);
        }
    }, [forgotOtp, timer]);

    useEffect(() => getData(), []);

    useEffect(() => {
        if (doLogin) {
            ReactGA.event('user_login', {
                category: 'user_auth',
                label: 'user_login',
                value: profile.id,
                user_ID: profile.id,
                fp: Cookies.get('_fp'),
                timestamp: new Date()
            });

            if (loginResponse && (loginResponse.orderNumber || loginResponse.invoiceUrl)) {
                setLoading(false);
                dispatch(toggleInvoiceModal(true, loginResponse.productId, loginResponse.orderNumber, loginResponse.invoiceUrl));
            }

            if (products.length !== 0) {
                sendProductsToBE();
            } else {
                if (loginResponse && (loginResponse.orderNumber || loginResponse.invoiceUrl)) {
                    //
                } else {
                    redirect();
                }
            }

            FS.event('User login', {
                user_id: profile.id.toString()
            });

            trackKlaviyoEvent('identify', profile);

            trackFreshChatUser('identify', profile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doLogin, loginResponse, doSignup]);

    useEffect(() => {
        if (doSignup) {
            ReactGA.event('user_register', {
                category: 'user_auth',
                label: 'user_register',
                value: profile.id,
                user_ID: profile.id,
                fp: Cookies.get('_fp'),
                timestamp: new Date()
            });

            if (products.length > 0) {
                sendProductsToBE();
            } else {
                redirect();
            }

            FS.event('User registered', {
                user_id: profile.id.toString()
            });

            trackKlaviyoEvent('identify', profile);

            trackFreshChatUser('identify', profile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doSignup]);

    useEffect(() => {
        if (form === 'forgot-password-otp' && otp && otp.length === 6) {
            handleSubmitOtp();
        }
    }, [otp]);

    return (
        <>
            <div className='w-100'>
                {form === '' &&
                    <InitialLoginForm
                        containerClass={initialLoginFormContainerClass}
                        handleSocialLogin={handleSocialLogin}
                        setEmailId={setEmailId}
                        checkEmailAvailability={checkEmailAvailability}
                        onBack={() => { history.push(getLastPageUrlWithoutAuth()); }}
                        loading={loading}
                        fromPopup={fromPopup} />
                }
                {form === 'signin' &&
                    <>
                        <LoginForm
                            showLoginWithOTP={false}
                            submit={submitLogin}
                            changeForm={onForgotPassword}
                            backToMainLogin={backToMainLogin}
                            email={emailForAutoFill}
                            onFormDataItemChange={onFormDataItemChange}
                            isStudent={localStorage.getItem('is_student')}
                            fromPopup={fromPopup}
                            showBackButton={true}
                            loading={loading} />
                        {!fromPopup &&
                            <StickyBottom
                                actionBtnLabel='Sign In'
                                actionBtnDisabled={authData.email === '' || authData.password === ''}
                                onSubmit={submitLogin}
                                onBack={backToMainLogin}
                                isLoading={loading} />}
                    </>
                }
                {form === 'signup' &&
                    <>
                        <SignUpForm
                            onFormDataItemChange={onSignUpFormDataItemChange}
                            submit={submitSignUp}
                            backToMainLogin={backToMainLogin}
                            email={emailForAutoFill}
                            isStudent={localStorage.getItem('is_student')}
                            fromPopup={fromPopup}
                            loading={loading}
                            changeForm={() => { setForm('signin'); }} />
                        {!fromPopup &&
                            <StickyBottom
                                actionBtnLabel='Sign up'
                                actionBtnDisabled={!isValidSignupData()}
                                onSubmit={submitSignUp}
                                onBack={backToMainLogin}
                                isLoading={loading} />}
                    </>
                }
                {form === 'forgot-password' &&
                    <>
                        <ForgotForm
                            submit={submitForgotPassword}
                            emailAddress={emailForAutoFill}
                            fromPopup={fromPopup}
                            onFormDataItemChange={(key, val) => { setForgotPasswordEmail(val); }}
                            changeForm={() => { setForm('signin'); }}
                            loading={loading} />
                        {!fromPopup &&
                            <StickyBottom
                                actionBtnLabel='Send OTP'
                                actionBtnDisabled={forgotPasswordEmail === ''}
                                onSubmit={submitForgotPassword}
                                onBack={() => { setForm('signin'); }}
                                isLoading={loading} />}
                    </>
                }
                {form === 'forgot-password-otp' &&
                    <>
                        <AuthOTPForm
                            otp={otp}
                            setOtp={setOtp}
                            email={forgotPasswordEmail}
                            type={'forgot'}
                            resendCode={handleResendCode}
                            timer={timer}
                            submit={handleResendCode}
                            fromPopup={fromPopup}
                            changeForm={() => { setForm('forgot-password'); }}
                            loading={loading} />
                        {!fromPopup &&
                            <StickyBottom
                                actionBtnLabel='Resend OTP'
                                actionBtnDisabled={timer !== 0}
                                onSubmit={handleResendCode}
                                onBack={() => { setForm('signin'); }}
                                isLoading={loading} />}
                    </>
                }
                {form === 'reset-password' &&
                    <>
                        <ResetPassword
                            submit={submitResetPassword}
                            changeForm={() => { setForm('forgot-password'); }}
                            onFormDataItemChange={onResetPasswordFormDataItemChange}
                            isReset={true}
                            loading={loading}
                            fromPopup={fromPopup} />
                        {!fromPopup &&
                            <StickyBottom
                                actionBtnLabel='Save & Sign in'
                                actionBtnDisabled={newPassword === '' || confirmPassword !== newPassword}
                                onSubmit={submitResetPassword}
                                onBack={() => { setForm('forgot-password'); }}
                                isLoading={loading} />}
                    </>
                }
            </div>
            <InvoiceModal />
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleLogin: ({
            email,
            password,
            isRemember,
            gaSessionId,
            trueIp,
            onSuccess,
            onFailure
        }) => {
            dispatch(handleLogin({
                email,
                password,
                isRemember,
                gaSessionId,
                trueIp,
                onSuccess,
                onFailure
            }));
        },
        handleRegistration: ({
            email,
            password,
            confirmPassword,
            subscriptionAcknowledge,
            gaSessionId,
            trueIp,
            onSuccess,
            onFailure
        }) => {
            dispatch(handleRegistration({
                email,
                password,
                confirmPassword,
                subscriptionAcknowledge,
                gaSessionId,
                trueIp,
                onSuccess,
                onFailure
            }));
        }
    };
};

AuthForm.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    handleRegistration: PropTypes.func.isRequired,
    fromPopup: PropTypes.bool,
    initialForm: PropTypes.string,
    initialLoginFormContainerClass: PropTypes.string
};

export default connect(null, mapDispatchToProps)(AuthForm);
